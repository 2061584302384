import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from "react-router-dom";
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import HeaderFeaturedNav from './HeaderFeaturedNav'
import LoginRegistrationNav from './LoginRegistrationNav'
import HeaderNotificationNav from './HeaderNotificationNav'
import HeaderProfileNav from './HeaderProfileNav'
import HeaderMembership from './HeaderMembership'
import { Button, Container, Badge, Form } from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../../../services/AuthService.js";
import progressDots from './/./../../../assets/img/progress_dots_cropped.gif'
import newTOTLogo from './/./../../../assets/brand/UpdatedLogo.png'

//import { ReactComponent as Logo } from './/./../../../assets/brand/coreui.svg';

export default function Header(props) {
    //properties
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const { setAccountProfile, toggleSidebar, toggleSidebarMd, setIsShowSidebarMd, accountProfile, baseUrl, logoutRedirectUrl } = props
    const { isAuthenticated } = useAuth0();

    const [primaryCompanies, setPrimaryCompanies] = useState([]);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [dataStatus, setDataStatus] = useState('ready');

    const imgStyle = {
        position: 'relative',
        height: '30px',
        width: '100px',
        left: '10px',
        top: '0',
        right: '0',
        bottom: '0',
        color: 'transparent',
        border: 'none',
        display: dataStatus != 'ready' ? 'block' : 'none'
    };

    const imgStyleLogo = {
        position: 'relative',
        height: '30px',
        width: '30px',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0',
        marginRight: '10px',
        color: 'transparent',
        border: '1px solid black',
        borderRadius: '15%'
    };

    //populate primary companies
    const populatePrimaryCompanies = async () => {
        //console.log('Populating Primary Companies...');

        setDataStatus('loading');

        //get primary companies for dropdown
        await getPrimaryCompanies()
            .then(response => {
                if (response.success) {
                    setPrimaryCompanies(response.data);
                    setSelectedAccountId(accountProfile.accountId);
                }
        })
        .catch(ex => {
            const blah = 1;
            //setPageStatus('error');
            //setPageErrors(ex.message);
        })
        .finally(response => {
            setDataStatus('ready');
        })
    };

    //get primary companies
    const getPrimaryCompanies = async () => {
        //console.log('Getting Primary Companies...');

        const apiUrl = 'accounts/primarycompanies';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = response.ok ? await response.json() : [];
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle company change
    const handleCompanyChange = async (event) => {
        //console.log('Getting Primary Companies...');

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setSelectedAccountId(value);
        setDataStatus('loading');

        //get primary companies for dropdown
        await getAccountSwitch(value)
            .then(response => {
                if (response.success) {
                    localStorage.removeItem('dataFileState');

                    //refresh site at home page with new token
                    let alink = document.createElement('a');
                    alink.href = window.location.origin;
                    alink.click();
                } else {
                    setPrimaryCompanies([]);
                }
            })
            .catch(ex => {
                //setPageStatus('error');
                //setPageErrors(ex.message);
            })
            .finally(response => {
                setDataStatus('ready');
            })
    };

    //get account switch
    const getAccountSwitch = async (accountId) => {
        //console.log('Switching Account...');

        const apiUrl = 'accounts/accountswitch/' + accountId;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = response.ok ? await response.json() : [];
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    return (
      <header className={`header sticky-top mb-4 p-2 ${baseUrl.includes("totdata-api.azurewebsites") || baseUrl.includes("localhostblah") ? 'border-bottom' : ''}`} style={{ borderBottom: (baseUrl.includes("local") ? 'solid 2px lightblue' : baseUrl.includes("dev") ? 'solid 2px green' : baseUrl.includes("qa") ? 'solid 2px orange' : baseUrl.includes("demo") ? 'solid 2px blue' : baseUrl.includes("stage") ? 'solid 2px red' : 'none') }}>
          <Container fluid className="header-navbar d-flex align-items-center" >
        <Button
          variant="link"
          className="header-toggler d-md-none px-md-0 me-md-3 rounded-0 shadow-none"
          type="button"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>       

        {(() => {
            if (!baseUrl.includes("totdata-api.azurewebsites")) {
                return <div style={{ marginTop: '10px', marginRight: '15px' }} >
                    <h5>
                        <Badge bg={baseUrl.includes("local") ? 'info' : baseUrl.includes("dev") ? 'success' : baseUrl.includes("qa") ? 'warning' : baseUrl.includes("demo") ? 'primary' : baseUrl.includes("stage") ? 'danger' : ''}>
                            {baseUrl.includes("local") ? 'Local Dev' : baseUrl.includes("dev") ? 'Development' : baseUrl.includes("qa") ? 'QA' : baseUrl.includes("demo") ? 'Demo' : baseUrl.includes("stage") ? 'Stage' : ''}
                        </Badge>
                    </h5>
                </div>
            }                    
        })()}     

        {(() => {
            if (baseUrl.includes("totdata-api.")) {
                return <img
                    object-fit="fill"
                    src={newTOTLogo}
                    alt="totdata.com"
                    style={imgStyleLogo}
                />
            }
        })()}    

                <div className="header-nav d-none d-md-flex">                    
                  <Breadcrumb />
                </div>     

                {/*<div className="header-nav ms-2" style={{marginTop: '-20px', margin: 'auto', width: '50%'}} >*/}
                {/*    {(() => {*/}
                {/*        if (isAuthenticated) {*/}
                {/*            return <HeaderMembership accountProfile={accountProfile} />*/}
                {/*        }*/}
                {/*        else {*/}
                {/*            return ''*/}
                {/*        }*/}
                {/*    })()}*/}
                {/*</div>*/}

        <div className="header-nav ms-auto">
            {(() => {
                      if (!isAuthenticated) {
                          //return <LoginRegistrationNav />
                      }
                      else {
                          if (accountProfile.hasImpersonate) {
                              return <>
                                  <div style={{ marginLeft: (primaryCompanies.length > 0 ? '0px' : '12px') }} >
                                      {((accountProfile.firstName ?? '') == '' || (accountProfile.lastName ?? '') == '') ? accountProfile.email : accountProfile.firstName + ' ' + accountProfile.lastName}
                                  </div>
                                  <Button id="primaryAccounts" variant="link"
                                      size='md'
                                      style={{ display: (primaryCompanies.length > 0 || dataStatus != 'ready' ? 'none' : 'block'), marginTop: '-6px', marginLeft: '0px'}}
                                      onClick={() => populatePrimaryCompanies()}
                                  >
                                      {accountProfile.accountName.toUpperCase()}
                                  </Button>
                                  <Form.Select name="accountId"
                                      size="sm"
                                      style={{ display: (primaryCompanies.length > 0 && dataStatus == 'ready' ? 'block' : 'none'), marginTop: '5px' }}
                                      aria-label="PrimaryCompany"
                                      value={selectedAccountId}
                                      onChange={handleCompanyChange}
                                  >
                                        {primaryCompanies.map((company, i) => (
                                            <option key={i} value={company.accountId}>{company.companyName}</option>
                                        ))}
                                  </Form.Select>
                                  <img
                                      object-fit="fill"
                                      src={progressDots}
                                      alt="user@email.com"
                                      style={imgStyle}
                                  />
                              </>
                          } else {
                              return <>
                                  <div>{((accountProfile.firstName ?? '') == '' || (accountProfile.lastName ?? '') == '') ? accountProfile.email : accountProfile.firstName + ' ' + accountProfile.lastName}</div>
                                  <div>{accountProfile.accountName.toUpperCase()}</div>
                              </>
                          }
                      }
            })()}
        </div>

        <div className="header-nav ms-2">                  
            {(() => {
                if (isAuthenticated) {
                    return <HeaderProfileNav setAccountProfile={setAccountProfile} toggleSidebarMd={toggleSidebarMd} setIsShowSidebarMd={setIsShowSidebarMd} accountProfile={accountProfile} logoutRedirectUrl={logoutRedirectUrl} />
                }
                else {
                    return ''
                }
            })()}
        </div>
      </Container>
    </header>
  )
}
