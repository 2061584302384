import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb as BSBreadcrumb } from 'react-bootstrap'

export default function Breadcrumb() {
    const location = useLocation();
    const navigate = useNavigate();
    const [segments, setSegments] = useState([]);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log(location.pathname);
            const urlSegments = location.pathname.split("/");

            //let i = 0;
            //for (i in urlSegments) {
            //    console.log('Segment ' + i + ': ' + urlSegments[i]);
            //}

            const currentPage = urlSegments[1];
            let segmentList = [];
            switch (currentPage) {
                case 'account':
                    segmentList = [{ name: 'Account', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    //let i = 0;
                    //for (i in segmentList) {
                    //    console.log('Segment ' + i + ' - Name: ' + segmentList[i].name + ', Link: ' + segmentList[i].link ?? '');
                    //}

                    break;
                case 'profile':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                        { name: 'My Profile', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'companies':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                    { name: 'Companies', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'membership':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                    { name: 'Membership', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'payment':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                    { name: 'Membership', isLink: true, link: '/membership', style: { cursor: 'pointer' }, isLastSegment: false },
                    { name: 'Payment', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'uploademails':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                        { name: 'Load Emails', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'settings':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                        { name: 'Settings', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'contact':
                    segmentList = [{ name: 'Account', isLink: true, link: '/account', style: { cursor: 'pointer' }, isLastSegment: false },
                        { name: 'Contact', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;

                case 'import':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: { }, isLastSegment: false },
                    { name: 'Import', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'datafiles':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                        { name: 'Data Files', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'statements':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                        { name: 'Check Statements', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'mappings':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'Owner Mappings', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'mappedowners':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                        { name: 'Owner Mappings', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;

                case 'unmappedcdex':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'Cdex Mappings', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'cdexcodes':
                    segmentList = [{ name: 'Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'Cdex Mappings', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'checkfiles':
                    segmentList = [{ name: 'Non-Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                        { name: 'Check Statements', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'sendingoperators':
                    segmentList = [{ name: 'Non-Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'My Operators', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'documentconversion':
                    segmentList = [{ name: 'Non-Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'Document Conversion', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'docconversion':
                    segmentList = [{ name: 'Non-Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'Document Conversion', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'cdexudfs':
                    segmentList = [{ name: 'Non-Operated', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'User-Defined Fields', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;

                case 'jib':
                    segmentList = [{ name: 'Other', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'JIB', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case '1099':
                    segmentList = [{ name: 'Other', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: '1099', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'ach':
                    segmentList = [{ name: 'Other', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'ACH', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
                case 'coa':
                    segmentList = [{ name: 'Other', isLink: false, link: '/', style: {}, isLastSegment: false },
                    { name: 'COA', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;

                default:
                    segmentList = [{ name: 'Home', isLink: false, link: null, style: {}, isLastSegment: true }];
                    setSegments(segmentList);

                    break;
            }            
        })();
    }, [location]);

    return (
      <div className="header-nav d-none d-md-flex">
            {segments.map((segment, i) => (
                <div key={i} style={{ paddingTop: '4px' }}>                    
                    <span style={segments[i].style} onClick={() => (segments[i].isLink ? navigate(segments[i].link) : null)}>{segments[i].name}</span>
                    <span style={{ paddingLeft: '8px', paddingRight: '8px' }} >{segments[i].isLastSegment ? '' : "/"}</span>
                </div>
            ))}
      </div>
    )
}
