import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card, ToggleButton, ToggleButtonGroup, Modal } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import { apiGet, apiPut } from "../services/AuthService.js";
import { useAuth0 } from "@auth0/auth0-react";
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripePublishableKey);

const Payment = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');

    const [clientSecret, setClientSecret] = useState('');
    const [options, setOptions] = useState();

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            const billingPeriod = location.state.billingPeriod;
            //console.log('Billing Period: ' + billingPeriod);

            await getCheckoutSession(billingPeriod)
                .then(response => {
                    if (response.data.length === 0) {
                        console.log(response.data);
                        setDataStatus('nodata')
                    }
                    else {
                        const clientSecret = response.data.clientSecret;
                        const options = {clientSecret};
                        setOptions(options);
                        setClientSecret(response.data.clientSecret);
                        setDataStatus('ready')
                    }

                    setPageStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    setDataStatus('error');
                    setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //get client secret
    const getCheckoutSession = async (billingPeriod) => {
        //console.log('Getting Client Secret...');

        let apiUrl = 'a_payments/create-checkout-session?accountId=' + currentAccount.id + '&billingPeriod=' + billingPeriod;
        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render payment
    const showPayment = () => {
        return <div id="checkout">
                {clientSecret && (
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={options}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                )}
            </div>
    }

    return (
        <div>
            <h1>Payment</h1>
            <div><br /></div>

            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showPayment()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
            <br />
      </div>
    );
}

export default Payment;
