import React, { useEffect, useState } from 'react';

const WeatherForecast = props => {
    //const displayName = WeatherForecast.name;
    const [forecasts, setForecasts] = useState([]);
    const [loading, setLoading] = useState(true);

    const populateWeatherData = async () => {
        try {
            //const response = await fetch("weatherforecast");
            let apiUrl = process.env.REACT_APP_API_BASEURL + 'weatherforecast';
            const response = await fetch(apiUrl);
            const json = await response.json();
            return { success: true, data: json };
        } catch (error) {
            console.log(error);
            return { success: false };
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            let res = await populateWeatherData();
            if (res.success) {
                setForecasts(res.data);
                setLoading(false);
            }
        })();
    }, []);

    const renderForecastsTable = () => {
        return (
            <table className="table table-striped" aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Temp. (C)</th>
                        <th>Temp. (F)</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map((forecast) => (
                        <tr key={forecast.date}>
                            <td>{forecast.date}</td>
                            <td>{forecast.temperatureC}</td>
                            <td>{forecast.temperatureF}</td>
                            <td>{forecast.summary}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    const noData = () => {
        return (
            <p>
                <em>
                    Loading... Please refresh once the ASP.NET backend has started. See{" "}
                    <a href="https://aka.ms/jspsintegrationreact">
                        https://aka.ms/jspsintegrationreact
                    </a>{" "}
                    for more details.
                </em>
            </p>
        );
    }

    return (
      <div>
        <h1 id="tabelLabel">Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
            {loading ? (
                noData()
            ): (
                renderForecastsTable()
            )}
      </div>
    );
}

export default WeatherForecast;
