import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Modal } from 'react-bootstrap';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
//import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import MatchPaginationComponent from '../components/pagination/MatchPaginationComponent.js'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

function SureModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: 'lightgray' }} >
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>Are you sure?</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ marginTop: '0px', paddingTop: '0px' }} ><i><b>This option will mark all Imported Owners as Verified Receivers.</b></i></p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }} >
                        <Col style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button
                                onClick={props.onHide}
                                style={{ width: '100px', backgroundColor: 'red' }}
                            >
                                No
                            </Button>
                            <Button
                                type='button'
                                size='sm'
                                style={{ marginLeft: '20px', width: '100px', backgroundColor: 'green' }}
                                onClick={() => props.handleMapAllButton()}
                            >
                                Yes
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const OwnerMappings = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const navigate = useNavigate();
    const params = useParams();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [sureModalShow, setSureModalShow] = useState(false);
    const [owners, setOwners] = useState([]);
    const [dataCriteria, setDataCriteria] = useState({
        dataFileId: params.id,
        skipCount: 0,
        takeCount: 50,
        matchTakeCount: 2,
        ownerName: ""
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [matchCriteria, setMatchCriteria] = useState([{
        ownerId: 0,
        skipCount: 0,
        takeCount: 2,
        currentPage: 1,
        rowCount: 0,        
        ownerName: "",
        address: "",
        city: "",
        state: "",
        zip: ""
    }]);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await populateOwners(1)
                .then(response => {
                    setPageStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    setDataStatus('error');
                    setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //populate owners
    const populateOwners = async (currentPage, reset) => {
        //console.log('Current Page: ' + currentPage);

        //note: have to recalcaulate skipCount here since state updates aren't active until re-render
        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        //console.log('Skip Count: ' + skipCount);

        //setDataStatus('loading');

        const currentCriteria = {
            dataFileId: dataCriteria.dataFileId,
            ownerName: (reset ? '' : dataCriteria.ownerName),
            skipCount: skipCount,
            takeCount: dataCriteria.takeCount,
            matchTakeCount: dataCriteria.matchTakeCount
        }

        await getOwners(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    //console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Owner Count: ' + response.data[0].rowCount);
                    setRowCount(response.data[0].rowCount);
                    setOwners(response.data);
                    setDataStatus('ready')
                }
                initializeMatchCriteria(response.data);
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //get owners
    const getOwners = async (criteria) => {
        //console.log('Getting Unmapped Owners...');

        const apiUrl = 'datafiles/' + criteria.dataFileId
            + '/unmappedreceivers?skipcount=' + criteria.skipCount + '&takecount=' + criteria.takeCount + '&matchtakecount=' + criteria.matchTakeCount
            + (criteria.ownerName === "" ? '' : '&ownerName=' + criteria.ownerName);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //get owner matches
    const getOwnerMatches = async (criteria) => {
        console.log('Getting Owner Matches for OwnerId: ' + criteria.ownerId + ', OwnerName: ' + criteria.ownerName + ', Address: ' + criteria.address);

        ////note: using criteria param instead of matchCriteria from state so we can use current criteria
        //let apiUrl = baseUrl + 'companies/' + criteria.ownerId + '/receivermatches?skipcount=' + criteria.skipCount + '&takecount=' + criteria.takeCount
        //    + (criteria.ownerName === "" ? '' : '&receiverName=' + criteria.ownerName)
        //    + (criteria.address === "" ? '' : '&address=' + criteria.address)
        //    + (criteria.city === "" ? '' : '&city=' + criteria.city)
        //    + (criteria.state === "" ? '' : '&state=' + criteria.state)
        //    + (criteria.zip === "" ? '' : '&zip=' + criteria.zip);

        //console.log(apiUrl);
        //let json = await fetch(apiUrl)
        //    .then(response => response.json())
        //    .catch(ex => {
        //        throw ex;                                   //use this to throw the raw error object
        //        //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        //    })

        //return { success: true, rowCount: json.rowCount, data: json.data };


        //note: using criteria param instead of matchCriteria from state so we can use current criteria
        const apiUrl = 'companies/' + criteria.ownerId + '/receivermatches?skipcount=' + criteria.skipCount + '&takecount=' + criteria.takeCount
            + (criteria.ownerName === "" ? '' : '&receiverName=' + criteria.ownerName)
            + (criteria.address === "" ? '' : '&address=' + criteria.address)
            + (criteria.city === "" ? '' : '&city=' + criteria.city)
            + (criteria.state === "" ? '' : '&state=' + criteria.state)
            + (criteria.zip === "" ? '' : '&zip=' + criteria.zip);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, rowCount: json.rowCount, data: json.data };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle match criteria control changes
    const handleMatchCriteriaChange = (ownerId, event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('ownerId: ' + ownerId, 'Field: ' + fieldName + ', Value: ' + value);

        const newMatchCriteria = [];
        matchCriteria.map(criteria => {
            if (criteria.ownerId === ownerId) {
                newMatchCriteria.push({
                    ...criteria,
                    [fieldName]: value
                });
            }
            else {
                newMatchCriteria.push(criteria);
            }
            //console.log('Updating match criteria for ownerId: ' + owner.receiverId + ', ' + owner.ownerName);
        });
        setMatchCriteria(newMatchCriteria);

        //newMatchCriteria.map(criteria => {
        //    console.log('New match criteria for ownerId: ' + criteria.ownerId + ', ' + criteria.skipCount + ', ' + criteria.takeCount + ', ' + criteria.ownerName + ', ' + criteria.address + ', ' + criteria.city + ', ' + criteria.state + ', ' + criteria.zip);
        //});
    }

    const initializeMatchCriteria = async (owners) => {
        const newMatchCriteria = [];
        owners.map(owner => {
            //console.log('Initializing match criteria for ownerId: ' + owner.receiverId + ', ' + owner.ownerName);
            newMatchCriteria.push({
                ownerId: owner.receiverId,
                skipCount: 0,
                takeCount: 2,
                currentPage: 1,
                ownerName: (owner.ownerName ?? ''),
                address: '',
                city: '',
                state: '',
                zip: '',
                isChecked: false
            });
        });
        setMatchCriteria(newMatchCriteria);

        //newMatchCriteria.map(criteria => {
        //    console.log('New match criteria for ownerId: ' + criteria.ownerId + ', ' + criteria.skipCount + ', ' + criteria.takeCount + ', ' + criteria.ownerName + ', ' + criteria.address + ', ' + criteria.city + ', ' + criteria.state + ', ' + criteria.zip + ', ' + criteria.currentPage);
        //});
    }

    //handle reset button
    const handleResetButton = async () => {

        setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            ownerName: '',
            skipCount: 0
        });

        setCurrentPage(1);

        await populateOwners(1, true);
    };

    //handle map selected button
    const handleMapSelectedButton = async () => {
        console.log('Mapping selected owners...');
        setDataStatus('loading');

        const selectedOwners = [];
        owners.map(owner => {
            if (!!owner.isChecked) {
                selectedOwners.push({
                    dataFileId: dataCriteria.dataFileId,
                    receiverId: owner.receiverId,
                    mapToReceiverId: owner.receiverId
                });
            } else {
                const receiverMatches = owner.receiverMatches.data;
                receiverMatches.map(match => {
                    if (!!match.isChecked) {
                        selectedOwners.push({
                            dataFileId: dataCriteria.dataFileId,
                            receiverId: owner.receiverId,
                            mapToReceiverId: match.id
                        });
                    }
                });
            }
        });

        console.log('Selected Owner Count: ' + selectedOwners.length);
        selectedOwners.map(selectedOwner => {
            console.log('Selected owner - OwnerId: ' + selectedOwner.receiverId + ', SelectedOwnerId: ' + selectedOwner.mapToReceiverId);
        });

        await mapSelectedOwners(selectedOwners, false)
            .then(response => {
                if (response.success) {
                    //TODO: repopulate owners
                    handleResetButton(1);
                    //note: data status is set to 'ready' in handleResetButton()
                    //setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle map all button
    const handleMapAllButton = async () => {
        console.log('Mapping all owners...');
        setDataStatus('loading');
        setSureModalShow(false);

        const selectedOwners = [{
            dataFileId: dataCriteria.dataFileId,
            receiverId: 0,
            mapToReceiverId: 0
        }];

        console.log('Selected Owner Count: ' + selectedOwners.length);
        selectedOwners.map(selectedOwner => {
            console.log('Selected owner - OwnerId: ' + selectedOwner.receiverId + ', SelectedOwnerId: ' + selectedOwner.mapToReceiverId);
        });

        await mapSelectedOwners(selectedOwners, true)
            .then(response => {
                if (response.success) {
                    //TODO: repopulate owners
                    handleResetButton(1);
                    //note: data status is set to 'ready' in handleResetButton()
                    //setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //populate owners
    const mapSelectedOwners = async (selectedOwners, mapAllUnverified) => {
        //console.log('Mapping Selected Owners...');

        //let apiUrl = baseUrl + 'accounts/' + currentAccount.id + '/mapReceivers/';
        //let body = JSON.stringify(selectedOwners);

        //console.log(apiUrl);
        //console.log(body);

        //let json = await fetch(apiUrl, {
        //    method: 'PUT',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify(selectedOwners)
        //    })
        //    .then(response => {
        //        if (response === true) {
        //            alert('true response');
        //        }
        //    })
        //    .catch(ex => {
        //        throw ex;                                   //use this to throw the raw error object
        //        //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        //    })

        //return { success: true };



        const apiUrl = 'accounts/' + currentAccount.id + '/mapReceivers?mapAllUnverified=' + mapAllUnverified;
        const jsonBody = JSON.stringify(selectedOwners);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    //submit form
    const submitForm = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        setCurrentPage(1);

        await populateOwners(1);
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        //console.log('Current Page: ' + currentPage);

        setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        //don't need to do here, since pagination component sets before calling this method
        //setCurrentPage(1);

        await populateOwners(currentPage);
    };

    //handle owner checkbox click
    const handleOwnerCheckboxClick = async (ownerId) => {
        console.log('OwnerId: ' + ownerId);

        const newOwners = owners.map(owner => {
            if (owner.receiverId === ownerId) {
                const receiverMatches = owner.receiverMatches.data;
                const newMatches = receiverMatches.map(match => {
                    //set the match's checkbox to false (for every match)
                    return {
                        ...match,
                        isChecked: false
                    };
                });

                //flip the bit on the owner's checkbox
                const ownerChecked = !!owner.isChecked;
                return {
                    ...owner,
                    receiverMatches: { rowCount: owner.receiverMatches.rowCount, data: newMatches },
                    isChecked: !ownerChecked
                };
            }
            else {
                return owner;
            }
        });

        //newOwners.map(newOwner => {
        //    console.log('New owner - OwnerId: ' + newOwner.receiverId + ', OwnerName:' + newOwner.ownerName + ', IsChecked: ' + !!newOwner.isChecked);
        //    newOwner.receiverMatches.data.map(match => {
        //        console.log('MatchId: ' + match.id + ', MatchName:' + match.companyName + ', IsChecked: ' + !!match.isChecked);
        //    });
        //});

        setOwners(newOwners);
    };

    //handle match reset button click
    const handleMatchResetClick = async (owner, criteriaIndex, currentPage) => {
        console.log('ownerId: ' + owner.receiverId);

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * matchCriteria[criteriaIndex].takeCount);
        //console.log('Skip Count: ' + skipCount);

        const newMatchCriteria = [];
        matchCriteria.map(criteria => {
            if (criteria.ownerId === owner.receiverId) {
                newMatchCriteria.push({
                    ...criteria,
                    ownerName: owner.ownerName,
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    currentPage: currentPage,
                    skipCount: skipCount
                });
            }
            else {
                newMatchCriteria.push(criteria);
            }
            //console.log('Updating match criteria for ownerId: ' + owner.receiverId + ', ' + owner.ownerName);
        });
        setMatchCriteria(newMatchCriteria);

        await populateOwnerMatches(owner, criteriaIndex, currentPage, true);
    };

    //handle match search button click
    const handleMatchSearchClick = async (owner, criteriaIndex, currentPage) => {
        console.log('ownerId: ' + owner.receiverId);

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * matchCriteria[criteriaIndex].takeCount);
        //console.log('Skip Count: ' + skipCount);

        const newMatchCriteria = [];
        matchCriteria.map(criteria => {
            if (criteria.ownerId === owner.receiverId) {
                newMatchCriteria.push({
                    ...criteria,
                    currentPage: currentPage,
                    skipCount: skipCount
                });
            }
            else {
                newMatchCriteria.push(criteria);
            }
            //console.log('Updating match criteria for ownerId: ' + owner.receiverId + ', ' + owner.ownerName);
        });
        setMatchCriteria(newMatchCriteria);

        await populateOwnerMatches(owner, criteriaIndex, currentPage);
    };

    //handle match page click
    const handleMatchPageClick = async (owner, currentPage, takeCount) => {
        //console.log('OwnerId: ' + owner.receiverId + ', Current Page: ' + pageNumber);

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * takeCount);
        //console.log('Skip Count: ' + skipCount);

        let index = 0;
        let criteriaIndex = 0;
        const newMatchCriteria = [];
        matchCriteria.map(criteria => {
            if (criteria.ownerId === owner.receiverId) {
                newMatchCriteria.push({
                    ...criteria,
                    currentPage: currentPage,
                    skipCount: skipCount
                });
                criteriaIndex = index;
            }
            else {
                newMatchCriteria.push(criteria);
            }
            index++;
        });
        //already done in setMatchCurrentPage(); just doing here to get the criteriaIndex
        setMatchCriteria(newMatchCriteria);

        await populateOwnerMatches(owner, criteriaIndex, currentPage);
    };

    //populate owner matches
    const populateOwnerMatches = async (owner, criteriaIndex, currentPage, reset) => {
        //console.log('Current Page: ' + currentPage);

        //note: have to recalcaulate skipCount here since state updates aren't active until re-render
        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * matchCriteria[criteriaIndex].takeCount);
        //console.log('Skip Count: ' + skipCount);

        //setDataStatus('loading');

        const currentCriteria = {
            ownerId: owner.receiverId,
            ownerName: (reset ? owner.ownerName : matchCriteria[criteriaIndex].ownerName),
            address: (reset ? '' : matchCriteria[criteriaIndex].address),
            city: (reset ? '' : matchCriteria[criteriaIndex].city),
            state: (reset? '' : matchCriteria[criteriaIndex].state),
            zip: (reset ? '' : matchCriteria[criteriaIndex].zip),
            skipCount: skipCount,
            takeCount: matchCriteria[criteriaIndex].takeCount
        }

        await getOwnerMatches(currentCriteria)
            .then(response => {
                const newOwners = owners.map(savedOwner => {
                    if (savedOwner.receiverId === owner.receiverId) {
                        // Update matches for this Owner
                        //console.log('Selected Owner: ' + savedOwner.receiverId);
                        return {
                            ...savedOwner,
                            receiverMatches: response,
                        };
                    } else {
                        // No change
                        //console.log('Owner: ' + savedOwner.receiverId);
                        return savedOwner;

                    }
                });
                setOwners(newOwners);

                if (response.rowCount === 0) {
                    //setDataStatus('nodata')
                }
                else {
                    //setDataStatus('ready')
                }
                
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle match checkbox click
    const handleMatchCheckboxClick = async (ownerId, matchId) => {
        console.log('OwnerId: ' + ownerId, ', MatchId: ' + matchId);

        const newOwners = owners.map(owner => {
            if (owner.receiverId === ownerId) {
                const receiverMatches = owner.receiverMatches.data;
                const newMatches = receiverMatches.map(match => {
                    if (match.id === matchId) {
                        //flip the bit on the match's checkbox
                        const matchChecked = !!match.isChecked;
                        return {
                            ...match,
                            isChecked: !matchChecked
                        };
                    }
                    else {
                        //set the match's checkbox to unchecked
                        return {
                            ...match,
                            isChecked: false
                        };
                    }
                });

                //set the owner's checkbox to unchecked
                return {
                    ...owner,
                    receiverMatches: { rowCount: owner.receiverMatches.rowCount, data: newMatches },
                    isChecked: false
                };
            }
            else {
               return owner;
            }
        });

        //newOwners.map(newOwner => {
        //    console.log('New owner - OwnerId: ' + newOwner.receiverId + ', OwnerName:' + newOwner.ownerName + ', IsChecked: ' + !!newOwner.isChecked);
        //    newOwner.receiverMatches.data.map(match => {
        //        console.log('MatchId: ' + match.id + ', MatchName:' + match.companyName + ', IsChecked: ' + !!match.isChecked);
        //    });
        //});

        setOwners(newOwners);
    };

    const handleKeypress = async (e, owner, criteriaIndex, currentPage) => {
        //search if enter key was pressed
        if (e.key === 'Enter') {
            //alert('OwnerId: ' + owner.receiverId + ', Index: ' + criteriaIndex + ', Page: ' + currentPage);

            let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * matchCriteria[criteriaIndex].takeCount);
            //console.log('Skip Count: ' + skipCount);

            const newMatchCriteria = [];
            matchCriteria.map(criteria => {
                if (criteria.ownerId === owner.receiverId) {
                    newMatchCriteria.push({
                        ...criteria,
                        currentPage: currentPage,
                        skipCount: skipCount
                    });
                }
                else {
                    newMatchCriteria.push(criteria);
                }
                //console.log('Updating match criteria for ownerId: ' + owner.receiverId + ', ' + owner.ownerName);
            });
            setMatchCriteria(newMatchCriteria);

            await populateOwnerMatches(owner, criteriaIndex, currentPage);
        }
    };

    const setMatchCurrentPage = (owner, pageNumber) => {
        //console.log('OwnerId: ' + owner.receiverId + ', PageNumber: ' + pageNumber);

        //matchCriteria.map(criteria => {
        //    if (criteria.ownerId === owner.receiverId) {
        //        console.log('Pre match state criteria - OwnerId: ' + criteria.ownerId + ', PageNumber: ' + criteria.currentPage);
        //    }
        //});

        const newMatchCriteria = [];
        matchCriteria.map(criteria => {
            if (criteria.ownerId === owner.receiverId) {
                newMatchCriteria.push({
                    ...criteria,
                    currentPage: pageNumber
                });
            }
            else {
                newMatchCriteria.push(criteria);
            }
        });

        //newMatchCriteria.map(criteria => {
        //    if (criteria.ownerId === owner.receiverId) {
        //        console.log('New match criteria - OwnerId: ' + criteria.ownerId + ', PageNumber: ' + criteria.currentPage);
        //    }
        //});

        setMatchCriteria(newMatchCriteria);

        //matchCriteria.map(criteria => {
        //    if (criteria.ownerId === owner.receiverId) {
        //        console.log('Post match state criteria - OwnerId: ' + criteria.ownerId + ', PageNumber: ' + criteria.currentPage);
        //    }
        //});
    };

    const getMatchCurrentPage = (owner, msg) => {
        //console.log('Getting CurrentPage for OwnerId: ' + owner.receiverId);

        //console.log('Match Criteria Count: ' + matchCriteria.length);
        let currPage = 0;
        matchCriteria.map(criteria => {
            if (criteria.ownerId === owner.receiverId) {
                //if (criteria.ownerId == 2) {
                //    console.log('Match criteria - OwnerId: ' + criteria.ownerId + ', PageNumber: ' + criteria.currentPage + ', Msg: ' + (msg ?? ''));
                //}
                currPage = criteria.currentPage;
                
            }
        });

        return currPage;
    };

    ////render page loading
    ////note: spinner overlay will eventually be at the page level (I think)
    //const showPageLoading = () => {
    //    return (
    //        <div>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </div>
    //    );
    //}

    ////render page loading
    //const showDataLoading = () => {
    //    return (
    //        <Row>
    //            <img src={spinnerIcon} alt="loading..." />
    //        </Row>
    //    );
    //}

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body>
                {(() => {
                    switch (dataStatus) {
                        case 'nodata':
                            return <p>No Data Loaded.</p>
                        case 'loading':
                            return showDataLoading()
                        case 'ready':
                            return showData()
                        case 'error':
                            return showDataError()
                        default:
                            return <Row>No Data Loaded.</Row>
                    }
                    })()}
                </Card.Body>
                <Card.Footer>
                    <Container>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <PaginationComponent
                                    itemsCount={rowCount}
                                    itemsPerPage={dataCriteria.takeCount}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageClickHandler={handlePageClick}
                                    alwaysShown={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render owners
    const showData = () => {
        return (
            <Container>
                <Row>
                    <ul style={{ listStyleType: "none", height: "525px", overflow: "auto" }}>
                        {owners.map((owner, index) => (
                            <li key={owner.receiverId} style={{ marginBottom: "0px" }} >
                                <Card>
                                    {/*<Card.Header>{owner.ownerName}</Card.Header>*/}
                                    <Card.Body style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                        <Container>
                                            <Row>
                                                <Col xs="3">
                                                    <Container>
                                                        <Row>
                                                            <Col>
                                                                <div style={{ fontWeight: "bold", fontSize: '12px' }}>Imported Owner Info:</div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="1">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="chkSelect"
                                                                    label=""
                                                                    checked={!!owner.isChecked}
                                                                    onChange={() => handleOwnerCheckboxClick(owner.receiverId)}
                                                                />
                                                            </Col>
                                                            <Col style={{ fontSize: '11px' }} >
                                                                <div>{owner.ownerName}</div>
                                                                <div>{(owner.additionalNameInfo != null && owner.additionalNameInfo !== '') ? "(" + owner.additionalNameInfo + ")" : ""}</div>
                                                                <div>{owner.address1}</div>
                                                                <div>{owner.address2}</div>
                                                                <div>{owner.address3}</div>
                                                                <div>{owner.city}, {owner.state} {owner.zip}</div>
                                                                <div style={{ marginBottom: '4px' }} >
                                                                    <Button id={"s" + owner.id} variant="primary" size="sm" style={{ width: "80px", marginTop: "5px", fontSize: '11px' }} value={owner.id}
                                                                        onClick={(e) => handleMatchResetClick(owner, index, 1)}>
                                                                        Reset
                                                                    </Button>
                                                                    <Button id={"s" + owner.id} variant="primary" size="sm" style={{ width: "80px", marginTop: "5px", marginLeft: "10px", fontSize: '11px' }} value={owner.id}
                                                                        onClick={() => handleMatchSearchClick(owner, index, 1)}>
                                                                        Search
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Col>
                                                <Col xs="9" style={{ borderLeft: "1px solid black" }}>
                                                    <Container>
                                                        <Row>
                                                            <Col>
                                                                <div style={{ fontWeight: "bold", fontSize: '12px' }}>Owner Search:</div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <table className="table table-striped" aria-labelledby="tabelLabel">
                                                                <thead>
                                                                    <tr style={{ fontSize: '11px' }}>
                                                                        <th>&nbsp;</th>
                                                                        <th><div>Name</div>
                                                                            <div>
                                                                                <Form.Control name="ownerName" size="sm" style={{ fontSize: '11px' }} aria-label="Owner Name" value={matchCriteria[index].ownerName} onChange={(e) => handleMatchCriteriaChange(owner.receiverId, e)} onKeyPress={(e) => handleKeypress(e, owner, index, 1)} />
                                                                            </div>
                                                                        </th>
                                                                        <th><div>Address</div>
                                                                            <div>
                                                                                <Form.Control name="address" size="sm" style={{ fontSize: '11px' }} aria-label="Owner Address" value={matchCriteria[index].address} onChange={(e) => handleMatchCriteriaChange(owner.receiverId, e)} onKeyPress={(e) => handleKeypress(e, owner, index, 1)} />
                                                                            </div>
                                                                        </th>
                                                                        <th><div>City</div>
                                                                            <div>
                                                                                <Form.Control name="city" size="sm" style={{ fontSize: '11px' }} aria-label="Owner City" value={matchCriteria[index].city} onChange={(e) => handleMatchCriteriaChange(owner.receiverId, e)} onKeyPress={(e) => handleKeypress(e, owner, index, 1)} />
                                                                            </div>
                                                                        </th>
                                                                        <th><div>State</div>
                                                                            <div>
                                                                                <Form.Control name="state" size="sm" style={{ fontSize: '11px' }} aria-label="Owner State" value={matchCriteria[index].state} onChange={(e) => handleMatchCriteriaChange(owner.receiverId, e)} onKeyPress={(e) => handleKeypress(e, owner, index, 1)} />
                                                                            </div>
                                                                        </th>
                                                                        <th><div>Zip</div>
                                                                            <div>
                                                                                <Form.Control name="zip" size="sm" style={{ fontSize: '11px' }} aria-label="Owner Zip" value={matchCriteria[index].zip} onChange={(e) => handleMatchCriteriaChange(owner.receiverId, e)} onKeyPress={(e) => handleKeypress(e, owner, index, 1)} />
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {owner.receiverMatches.data.map((ownerMatch) => (
                                                                        <tr key={ownerMatch.id} style={{ fontSize: '11px'}} >
                                                                            <td>
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    id="chkSelect"
                                                                                    label=""
                                                                                    checked={!!ownerMatch.isChecked}
                                                                                    onChange={() => handleMatchCheckboxClick(owner.receiverId, ownerMatch.id)}
                                                                                />
                                                                            </td>
                                                                            <td>{ownerMatch.companyName}<br />{(ownerMatch.additionalNameInfo != null && ownerMatch.additionalNameInfo !== '') ? "(" + ownerMatch.additionalNameInfo + ")" : ""}</td>
                                                                            <td>{ownerMatch.address1}<br />{ownerMatch.address2}<br />{ownerMatch.address3}</td>
                                                                            <td>{ownerMatch.city}</td>
                                                                            <td>{ownerMatch.state}</td>
                                                                            <td>{ownerMatch.zip}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            {/*{(() => {*/}
                                                            {/*    if (owner.receiverMatches.rowCount === 0) {*/}
                                                            {/*        return <Row><p style={{ marginLeft: "20px", fontSize: '14px' }}>No matches returned for the specified criteria.</p></Row>*/}
                                                            {/*    }*/}
                                                            {/*})()}*/}
                                                        </Row>
                                                        <Row>
                                                            <Col className="d-flex justify-content-end">
                                                                <MatchPaginationComponent
                                                                    criteriaIndex={index}
                                                                    owner={owner}
                                                                    itemsCount={owner.receiverMatches.rowCount}
                                                                    itemsPerPage={matchCriteria[index].takeCount}
                                                                    currentPage={matchCriteria[index].currentPage}
                                                                    setCurrentPage={setMatchCurrentPage}
                                                                    pageClickHandler={handleMatchPageClick}
                                                                    alwaysShown={false}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Card>
                            </li>
                        ))}
                    </ul>
                </Row>
            </Container>
        );
    }

    //pagination example
    //https://github.com/lukaaspl/ellipsis-pagination/blob/master/src/components/Pagination.js

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container>
                <Form>
                    <Row style={{  height: '30px' }}>
                        <Col xs="auto" style={{ paddingLeft: '20px' }} >
                            <Form.Group className="mb-3" controlId="ownerName">
                                <Form.Label style={{ fontSize: '14px', fontWeight: '600' }}>Owner Name</Form.Label>
                                </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerName">
                                {/*<Form.Label style={{ fontSize: '14px' }}>Owner Name</Form.Label>*/}
                                <Form.Control name="ownerName" size="sm" style={{ fontSize: '12px' }} aria-label="Owner Name" value={dataCriteria.ownerName} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonReset">
                                {/*<Form.Label as={Row}><br /></Form.Label>*/}
                                <Button
                                    type='button'
                                    size='sm'
                                    disabled={pageStatus !== 'ready'}
                                    style={{ width: "80px", fontSize: '12px' }}
                                    onClick={pageStatus === 'ready' ? handleResetButton : null}
                                >
                                    Reset
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                {/*<Form.Label as={Row}><br /></Form.Label>*/}
                                <Button
                                    type='submit'
                                    size='sm'
                                    disabled={pageStatus !== 'ready'}
                                    style={{ width: "80px, fontSize: '12px'"}}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>                                    
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonMap">
                                {/*<Form.Label as={Row}><br /></Form.Label>*/}
                                <Button
                                    type='button'
                                    size='sm'
                                    disabled={dataStatus !== 'ready'}
                                    style={{ width: "120px", fontSize: '12px' }}
                                    onClick={dataStatus === 'ready' ? handleMapSelectedButton : null}
                                >
                                    Map Selected
                                </Button>
                            </Form.Group>
                        </Col>
                        {(() => {
                            if (!baseUrl.startsWith("https://totdata-api.")) {
                                return <Col xs="auto">
                                    <Form.Group as={Col} className="mb-3" controlId="buttonMap">
                                        {/*<Form.Label as={Row}><br /></Form.Label>*/}
                                        <Button
                                            type='button'
                                            variant='warning'
                                            size='sm'
                                            disabled={dataStatus !== 'ready'}
                                            style={{ width: "120px", fontSize: '12px' }}
                                            onClick={dataStatus === 'ready' ? () => setSureModalShow(true) : null}
                                        >
                                            Map All
                                        </Button>
                                    </Form.Group>
                                </Col>
                            }
                        })()}
                    </Row>
                </Form>
            </Container>
        );
    }

    return (
        <div>
            {/*<h1>Owner Mappings1</h1>*/}
            {/*<p>Owner Mappings</p>*/}
            {/*<div><br /></div>*/}
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <SureModal
                show={sureModalShow}
                onHide={() => setSureModalShow(false)}
                handleMapAllButton={handleMapAllButton}
            />
      </div>
    );
}

export default OwnerMappings;
