import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import SidebarNav from './SidebarNav'

import logo from './/./../../../assets/brand/logo'
import signet from './/./../../../assets/brand/signet'
import { ReactComponent as Logo } from './/./../../../assets/brand/coreui.svg';

export default function Sidebar(props) {
    const { isShow, isShowMd, accountProfile } = props
  const [isNarrow, setIsNarrow] = useState(false)

  const toggleIsNarrow = () => {
    const newValue = !isNarrow
    localStorage.setItem('isNarrow', newValue ? 'true' : 'false')
    setIsNarrow(newValue)
  }

  // On first time load only
  useEffect(() => {
    if (localStorage.getItem('isNarrow')) {
      setIsNarrow(localStorage.getItem('isNarrow') === 'true')
    }
  }, [setIsNarrow])

  return (
    <div
      className={classNames('sidebar d-flex flex-column position-fixed h-100', {
        'sidebar-narrow': isNarrow,
        show: isShow,
        'md-hide': !isShowMd,
      })}
      id="sidebar"
    >
      <div className="sidebar-brand d-none d-md-flex align-items-center justify-content-center">
        <svg
          className="sidebar-brand-full"
          width="118"
          height="46"
        >
                  {`${logo}`}
              </svg>
        <Logo className="sidebar-brand-narrow d-none"
            width="46"
            height="46" />
        <svg
          className="sidebar-brand-narrow d-none"
          width="46"
          height="46"
        >
                  {`${signet}`}
        </svg>
      </div>

      <div className="sidebar-nav flex-fill">
              <SidebarNav accountProfile={accountProfile} />
      </div>

      {/*<Button*/}
      {/*  variant="link"*/}
      {/*  className="sidebar-toggler d-none d-md-inline-block rounded-0 text-end pe-4 fw-bold shadow-none"*/}
      {/*  onClick={toggleIsNarrow}*/}
      {/*  type="button"*/}
      {/*  aria-label="sidebar toggler"*/}
      {/*>*/}
      {/*  <FontAwesomeIcon className="sidebar-toggler-chevron" icon={faAngleLeft} fontSize={24} />*/}
          {/*</Button>*/}

          <Button
              variant="link"
              className="sidebar-toggler d-none d-md-inline-block rounded-0 text-end pe-4 fw-bold shadow-none"
              //onClick={toggleIsNarrow}
              type="button"
              aria-label="sidebar toggler"
          >
              {/*<FontAwesomeIcon className="sidebar-toggler-chevron" icon={faAngleLeft} fontSize={24} />*/}
          </Button>
    </div>
  )
}

export const SidebarOverlay = (props) => {
  const { isShowSidebar, toggleSidebar } = props

  return (
    <div
      tabIndex={-1}
      aria-hidden
      className={classNames('sidebar-overlay position-fixed top-0 bg-dark w-100 h-100 opacity-50', {
        'd-none': !isShowSidebar,
      })}
      onClick={toggleSidebar}
    />
  )
}
