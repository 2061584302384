import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";


const iconStyle = {
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

const CdexUdf = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [cdexUdf, setCdexUdf] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [originalCdexUdf, setOriginalCdexUdf] = useState([]);
    const [yesNo, setYesNo] = useState(['Y', 'N']);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await populateCdexUdfs()
                .then(response => {
                    //console.log('CdexUdfs: ' + response.data.accountId + ' - ' + response.data.noRevProductCode + ' - ' +  response.data.noRevTransactionCode);
                    if (response.data === undefined) {
                        console.log('no udf');
                        setDataStatus('nodata')
                        setDataErrors('No udf loaded.');
                        setPageStatus('error')
                        setPageErrors('No udf loaded.');
                    }
                    else {
                        console.log('Cdex UDF: ' + response.data.accountId + ' - ' + response.data.noRevProductCode + ' - ' + response.data.noRevTransactionCode);
                        let cdexUdf = response.data;
                        if (cdexUdf.accountId === undefined) {
                            cdexUdf = { accountId: currentAccount.id, distributionPercent: 'N', fileExtension: '', id: 0, noRevProductCode: '', noRevTransactionCode: '', noZip: 'N' }
                        }
                        
                        setOriginalCdexUdf(cdexUdf);
                        setCdexUdf(cdexUdf);
                        setDataStatus('ready')
                        setPageStatus('ready')
                    }
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    setDataStatus('error');
                    setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //populate cdexUdfs
    const populateCdexUdfs = async () => {
        //console.log('Getting CdexUdfs...');

        const apiUrl = 'accounts/' + currentAccount.id + '/cdexudfs';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        let value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);        

        switch (fieldName) {
            case 'noRevProductCode':
                if (value.length > 2) {
                    value = value.substring(0, 2);
                }
                break;
            case 'noRevTransactionCode':
                console.log('hello');
                if (value.length > 2) {
                    value = value.substring(0, 2);
                }
                break;
            case 'fileExtension':
                if (value.length > 0 && value.substr(0, 1) != '.')
                    value = '.' + value;

                if (value == '.')
                    value = ''

                if (value.length > 5) {
                    value = value.substring(0, 5);
                }
                break;
            default:
                //do nothing
        }

        console.log('Field: ' + fieldName + ', Value: ' + value);

        //create modified cdexudf
        const modifiedCdexUdf = {
            ...cdexUdf,
            [fieldName]: value
        };

        if (checkIsDirty(originalCdexUdf, modifiedCdexUdf)) {
            setIsDirty(true);
        }
        else {
            setIsDirty(false);
        }

        setCdexUdf(modifiedCdexUdf);
    }

    function checkIsDirty(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return true;
            }
        }

        return false;
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {

        switch (buttonId) {
            case "Edit":
                console.log('editing...');
                setDataStatus('editing');
                break;
            case "Cancel":
                console.log('cancelling...');
                setCdexUdf(originalCdexUdf);
                setDataStatus('ready');
                break;
            case "Save":
                console.log('saving...');
                //save logic here...
                await updateCdexUdf();
                break;
        }
    }

    //update cdexUdf
    const updateCdexUdf = async () => {
        console.log('Saving cdexUdf...');
        setDataStatus('loading');
        setPageStatus('loading');

        //const newCdexUdf = {
        //    ...cdexUdf, accountId: currentAccount.id
        //}

        await saveCdexUdf()
            .then(response => {
                if (response.success) {
                    setOriginalCdexUdf(cdexUdf);
                    setIsDirty(false);
                    setDataStatus('ready');
                    setPageStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save cdexUdf
    const saveCdexUdf = async () => {
        //console.log('Saving CdexUdf...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updatecdexudfs';
        const jsonBody = JSON.stringify(cdexUdf);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render cdexUdf info
    const showCdexUdf = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >View Cdex Udf</div>
                                </Col>
                                {(() => {
                                    if (accountProfile.hasImpersonate) {
                                        return <Col style={{ display: 'flex', justifyContent: 'right' }}>
                                            <FontAwesomeIcon icon={faPencil} style={{ cursor: 'pointer', padding: '5px', width: '20px', height: '20px' }} onClick={() => handleFormButtons('Edit')} />
                                        </Col>
                                    }
                                })()}
                            </Row>
                            <Row style={{ fontWeight: '500', marginTop: '20px' }}>
                                <Col xs={4} >
                                    <u>User Defined Field</u>
                                </Col>
                                <Col xs={2}>
                                    <u>Value</u>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    NoRevProductCode:
                                </Col>
                                <Col xs={2}>
                                    {originalCdexUdf.noRevProductCode}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    NoRevTransactionCode:
                                </Col>
                                <Col xs={2}>
                                    {originalCdexUdf.noRevTransactionCode}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    Distribution Percent:
                                </Col>
                                <Col xs={2}>
                                    {originalCdexUdf.distributionPercent}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    File Extension:
                                </Col>
                                <Col xs={2}>
                                    {originalCdexUdf.fileExtension}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    NoZip:
                                </Col>
                                <Col xs={2}>
                                    {originalCdexUdf.noZip}
                                </Col>
                            </Row>     
                            {(() => {
                                if (accountProfile.hasImpersonate) {
                                    return <>
                                        <hr />
                                        <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                            <Button id="editCdexUdf" variant="primary" size="sm" style={{ width: "70px" }}
                                                disabled={dataStatus !== 'ready'}
                                                onClick={dataStatus === 'ready' ? () => handleFormButtons('Edit') : null}
                                            >
                                                Edit
                                            </Button>
                                        </div>   
                                    </>
                                }
                            })()}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    //render edit cdexUdf
    const editCdexUdf = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4 shadow" style={{border: 'none', borderRadius: '10px'}} >
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.75rem', fontWeight: '600' }} >Edit CDEX UDF</div>
                                </Col>
                                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                                    <FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer', padding: '5px', width: '20px', height: '20px' }} onClick={() => handleFormButtons('Cancel')} />
                                </Col>
                            </Row>
                            <Form style={{marginTop: '20px'}} >
                            <Row>
                                <Col xs={4}>
                                    NoRevProductCode:
                                </Col>
                                <Col xs={2}>
                                    <Form.Group className="mb-3" controlId="noRevProductCode">
                                        <Form.Control name="noRevProductCode" size="sm" aria-label="NoRevProductCode" value={cdexUdf.noRevProductCode} onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    NoRevTransactionCode:
                                </Col>
                                <Col xs={2}>
                                        <Form.Group className="mb-3" controlId="noRevTransactionCode">
                                            <Form.Control name="noRevTransactionCode" size="sm" aria-label="NoRevTransactionCode" value={cdexUdf.noRevTransactionCode} onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    Distribution Percent:
                                </Col>
                                <Col xs={2}>
                                    <Form.Group className="mb-3" controlId="distributionPercent">
                                        <Form.Select name="distributionPercent" size="sm" aria-label="DistributionPercent" value={(cdexUdf.distributionPercent ?? '') == '' ? 'N' : cdexUdf.distributionPercent} onChange={handleChange} >
                                            {yesNo.map((value, i) => (
                                                <option key={i} value={value}>{value}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    File Extension:
                                </Col>
                                <Col xs={2}>
                                    <Form.Group className="mb-3" controlId="fileExtension">
                                        <Form.Control name="fileExtension" size="sm" aria-label="FileExtension" value={cdexUdf.fileExtension} onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    NoZip:
                                </Col>
                                <Col xs={2}>
                                    <Form.Group className="mb-3" controlId="noZip">
                                        <Form.Select name="noZip" size="sm" aria-label="NoZip" value={(cdexUdf.noZip ?? '') == '' ? 'N' : cdexUdf.noZip} onChange={handleChange} >
                                            {yesNo.map((value, i) => (
                                                <option key={i} value={value}>{value}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                </Row>
                                <hr />
                                <Row style={{marginTop: '-20px'}} >
                                    <Col className="d-flex justify-content-end">
                                        <Form.Group className="mb-3" controlId="buttonClear">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button id="editCdexUdf" variant="primary" size="sm" style={{ width: "70px" }}
                                                disabled={dataStatus !== 'editing'}
                                                onClick={dataStatus === 'editing' ? () => handleFormButtons('Cancel') : null}
                                            >
                                                Cancel
                                            </Button>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                size='sm'
                                                style={{marginLeft: '20px'}}
                                                disabled={!isDirty}
                                                onClick={isDirty ? () => handleFormButtons('Save') : null}
                                            >
                                                Save
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    return (
      <div>
            <h1>User-Defined Fields</h1>
            <br />

            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        //return showTableContainer()
                        return (dataStatus === 'ready' ? showCdexUdf() : (dataStatus === 'editing' ? editCdexUdf() : null))
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
      </div>
    );
}

export default CdexUdf;
