import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import PublicHeaderFeaturedNav from './PublicHeaderFeaturedNav'
import HeaderLogo from './HeaderLogo'
import LoginRegistrationNav from './LoginRegistrationNav'
import HeaderNotificationNav from './HeaderNotificationNav'
import HeaderProfileNav from './HeaderProfileNav'
import { Button, Container } from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";

export default function PublicHeader(props) {
    const { toggleSidebar, toggleSidebarMd, setAccountProfile, accountProfile } = props
    const { isAuthenticated } = useAuth0();

  return (
      <header className="header sticky-top mb-4 p-2" style={{ backgroundColor: '#141416' }}>
          <Container fluid className="header-navbar d-flex align-items-center">
              <div className="header-nav d-none d-md-flex" style={{ marginTop: '22px', marginLeft: '50px' }} >
            <HeaderLogo />
        </div>
        {/*      <div className="header-nav d-none d-md-flex" style={{marginLeft: '20px'}} >*/}
        {/*    <PublicHeaderFeaturedNav />*/}
        {/*</div>*/}
        {/*<div className="header-nav ms-auto">*/}
        {/*  <HeaderNotificationNav />*/}
        {/*</div>*/}

        <div className="header-nav ms-auto">
            {(() => {
                      if (!isAuthenticated) {
                    return <LoginRegistrationNav />
                }
                else {
                          return 'Welcome, ' + ((accountProfile.firstName ?? '') == '' ? accountProfile.email : accountProfile.firstName)
                }
            })()}
        </div>

        <div className="header-nav ms-2">                  
            {(() => {
                      if (isAuthenticated) {
                          return <HeaderProfileNav setAccountProfile={setAccountProfile} />
                    }
                    else {
                        return ''
                    }
            })()}
        </div>
      </Container>
      {/*<div className="header-divider border-top my-2 ms-n2 me-n2" />*/}
      {/*<Container fluid>*/}
      {/*  <Breadcrumb />*/}
      {/*</Container>*/}
    </header>
  )
}
