import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card, ToggleButton, ToggleButtonGroup, Modal } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import { apiGet, apiPut } from "../services/AuthService.js";
import { useAuth0 } from "@auth0/auth0-react";
import starImage from './/./../assets/img/star_transparent.png'
import goldCheck from './/./../assets/img/checkmark_gold_transparent.png'
import blackCheck from './/./../assets/img/checkmark_black_transparent.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { getMembership } from '../services/MembershipService.js'

function MembershipModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'right' }}>
                            <FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer', padding: '5px', width: '25px', height: '25px' }} onClick={props.onHide} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <div style={{ color: 'red', fontSize: '1.1rem', fontWeight: '700', marginBottom: '10px' }} >We are in the process upgrading our payment module. This function will be available very soon. Please try again later.  In the meantime, please continue to enjoy your free trial, on us!  Thank you for your patience!!</div>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col className="d-flex justify-content-center">*/}
                    {/*        <Button size="lg"*/}
                    {/*            onClick={props.onMemberButtonClick}*/}
                    {/*            style={{*/}
                    {/*                width: '350px',*/}
                    {/*                border: '1px solid #C8B568',*/}
                    {/*                borderRadius: '20px',*/}
                    {/*                backgroundColor: '#C8B568',*/}
                    {/*                color: '#fff',*/}
                    {/*                fontSize: '1rem',*/}
                    {/*                fontWeight: '600',*/}
                    {/*                padding: '5px',*/}
                    {/*                marginTop: '16px',*/}
                    {/*                marginBottom: '10px'*/}
                    {/*            }} >*/}
                    {/*            Yes - Upgrade my Membership Now!*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const Membership = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    //const [accountDetails, setAccountDetails] = useState([]);

    const [memberModalShow, setMemberModalShow] = useState(false);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            let membershipUpdateFailed = false;
            if (location.state !== null) {
                const checkoutSessionId = location.state.checkoutSessionId;
                console.log('CheckoutSessionId: ' + checkoutSessionId);

                await completeCheckoutAndUpgrade(checkoutSessionId)
                    .then(response => {
                        if (response.success) {
                            setDataStatus('ready');
                            setPageStatus('ready');
                        }
                        else {
                            setDataStatus('error');
                            setDataStatus('nodata');

                            setPageStatus('error');
                            setPageErrors('An unknown error occurred.');
                            membershipUpdateFailed = true;
                        }
                    })
                    .catch(ex => {
                        setDataStatus('error');
                        setDataErrors(ex.message);

                        setPageStatus('error');
                        setPageErrors(ex.message);

                        membershipUpdateFailed = true;
                    })
                    .finally(response => {
                        //do something
                    })
            }

            if (membershipUpdateFailed) {
                return;
            } else {
                if ((accountProfile.subscriptionTypeId ?? 0) === 0) {
                    await getMembership(currentAccount.id, getAccessTokenSilently, accountProfile, setAccountProfile)
                        .then(response => {
                            if (response.success) {
                                setPageStatus('ready');
                            }
                            else {
                                setPageStatus('error');
                                setPageErrors('Failed to get account membership info.');
                            }
                        })
                        .catch(ex => {
                            //TODO: handle so payment button doesn't get displayed if membership not set (or verify it doesn't get displayed)
                            setPageStatus('error');
                            setPageErrors(ex.message);
                        })
                        .finally(response => {
                            //do something
                        });
                } else {
                    setPageStatus('ready')
                }
            }

        })();
    }, []);

    //get user profile
    const populateAccountDetails = async () => {
        //console.log('Getting User Profile...');

        //TODO: don't need email anymore since auth0Id is used (we're not logging in; just getting profile for the login)
        let apiUrl = 'accounts/userprofile';
        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //handle upgrade button
    const handleUpgradeButton = async () => {

        //await updateMembership();
        //setAccountProfile({ ...accountProfile, subscriptionStatusId: 2 });

        //if (accountProfile.hasImpersonate) {
            navigate('/payment', { state: { billingPeriod: priceValue } });
        //} else {
        //    setMemberModalShow(true);
        //}        
    }

    //handle payment success
    const handlePaymentSuccess = async () => {

        alert('yo hello');
        //await updateMembership();
        //setAccountProfile({ ...accountProfile, subscriptionStatusId: 2 });
    }

    ////update membership
    //const updateMembership = async () => {
    //    //console.log('Updating Membership...');
    //    setDataStatus('loading');

    //    await saveMembership()
    //        .then(response => {
    //            if (response.success) {
    //                setDataStatus('ready')
    //            }
    //            else {
    //                //console.log('nodata');
    //                //setDataStatus('nodata')
    //            }
    //        })
    //        .catch(ex => {
    //            setDataStatus('error');
    //            setDataErrors(ex.message);
    //        })
    //        .finally(response => {
    //            //do something
    //            setDataStatus('ready')
    //        })
    //};

    //get complete checkout and upgrade
    const completeCheckoutAndUpgrade = async (checkoutSessionId) => {
        //console.log('Getting Checkout Session Status...');

        let apiUrl = 'a_payments/completeCheckoutAndUpgrade?accountId=' + currentAccount.id + '&checkoutSessionId=' + checkoutSessionId;
        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok);

        return { success: response.ok }
    };

    ////save membership
    //const saveMembership = async () => {
    //    //console.log('Saving Membership...');

    //    const apiUrl = 'accounts/' + currentAccount.id + '/updatemembership';
    //    const jsonBody = JSON.stringify('');
    //    //console.log(apiUrl);
    //    //console.log(jsonBody);

    //    const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);
    //    //const json = await response.json();

    //    return { success: response.ok };
    //};

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render membership info
    const showMembership = () => {
        return <Container>
            <Row>
                <Col className="d-flex justify-content-center">
                    {showPriceOptions()}
                </Col>
            </Row>

            {/*<Row style={{ marginTop: '50px' }} >*/}
            {/*    <Col xs={1}></Col>*/}
            {/*    <Col xs={5}>*/}
            {/*        {showBlackGoldPlan()}*/}
            {/*    </Col>*/}
            {/*    <Col xs={5}>*/}
            {/*        {showWildCatterPlan()}*/}
            {/*    </Col>*/}
            {/*    <Col xs={1}></Col>*/}
            {/*</Row>*/}

            <Row className="justify-content-md-center" style={{ marginTop: '50px' }} >
                <Col xs={7}>
                    {(() => {
                        if (accountProfile.subscriptionTypeId === 2) {                            
                            //BlackGold
                            switch (accountProfile.subscriptionStatusId) {
                                case 1:  //Trial
                                    if (accountProfile.displayTrialModal) {
                                        return showCurrentPlanTrial()
                                    } else {
                                        return showCurrentPlanExpired()
                                    }
                                case 2:  //Current
                                    return showCurrentPlanBlackGold()
                                case 3:  //PastDue
                                    return showCurrentPlanPastDue()     //TODO: make 
                                default:
                                //return null
                            }
                        }
                        else {
                            //WildCatters (just show trial expired)
                            return showCurrentPlanExpired()
                        }
                    })()}
                </Col>
            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: '-12px' }} >
                <Col xs={7} style={{ fontSize: '.9rem' }} >
                    <i>*Plans may be cancelled at any time by contacting Support.</i>
                </Col>
            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: '50px' }} >
                <Col xs={7}>
                    {showComparePlans()}
                </Col>
            </Row>
        </Container>
    }

    const [priceValue, setPriceValue] = useState(1);
    const handlePriceChange = (val) => setPriceValue(val);

    const btnPriceStyle = {
        width: '200px',
        height: '80px',
        backgroundColor: '#fff',
        border: '1px solid #C8B568',
        color: '#C8B568',
        cursor: 'auto'
    };

    const btnPriceStyleSelected = {
        width: '200px',
        height: '80px',
        backgroundColor: '#C8B568',
        border: '1px solid #C8B568',
        //boxShadow: '0px 0px 10px gray',
        cursor: 'auto'
    };

    //render price options
    const showPriceOptions = () => {
        return <>
        <ToggleButtonGroup type="radio" name="priceOptions" value={priceValue} onChange={handlePriceChange}>
                <ToggleButton id="tbg-btn-1" type="radio" size="lg" className="btnStyle" style={(priceValue === 1 ? btnPriceStyleSelected : btnPriceStyle)} value={1}>
                    <div style={{ fontSize: '1.5rem', fontWeight: '600' }}>Annual</div>
                    <div style={{ fontSize: '1.1rem', fontWeight: '700' }}>$120/year</div>
                </ToggleButton>
                {/*<ToggleButton id="tbg-btn-2" type="radio" size="lg" style={(priceValue === 2 ? btnPriceStyleSelected : btnPriceStyle)} value={2}>*/}
                {/*    <div style={{ fontSize: '1.5rem', fontWeight: '600' }}>Monthly</div>*/}
                {/*    <div style={{ fontSize: '1.1rem', fontWeight: '700' }}>$12/mo.</div>*/}
                {/*</ToggleButton>*/}
            </ToggleButtonGroup>
        </>
    }

    const [planValue, setPlanValue] = useState(1);
    const handlePlanChange = (val) => setPlanValue(val);

    const btnPlanStyle = {
        width: '300px',
        border: '1px solid #C8B568',
        borderRadius: '20px',
        backgroundColor: '#fff',
        color: '#C8B568',
        fontWeight: '600'
    };

    const btnPlanStyleSelected = {
        width: '300px',
        border: '1px solid #C8B568',
        borderRadius: '5px',
        backgroundColor: '#C8B568',
        color: '#fff',
        fontWeight: '600',
        boxShadow: '0px 0px 10px gray'
    };

    //render blackgold plan
    const showBlackGoldPlan = () => {
        return <Card className="mb-4 shadow">
            <Card.Header style={{ backgroundColor: '#C8B568' }}>
                <Row>
                    <Col className="d-flex justify-content-center" style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff' }}>
                        BlackGold Plan<img src={starImage} alt="BlackGold" style={{ width: '40px', height: '40px', marginLeft: '20px' }} />
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <div>Text 1</div>
                        <div>Text 2</div>
                        <div>Text 3</div>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}} >
                    <Col className="d-flex justify-content-center">
                        <Button style={(planValue === 1 ? btnPlanStyleSelected : btnPlanStyle)} onClick={() => handlePlanChange(1)} >
                            Select BlackGold
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    //render wildcatter plan
    const showWildCatterPlan = () => {
        return <Card className="mb-4">
            <Card.Header style={{ backgroundColor: '#C8B568' }}>
                <Row>
                    <Col className="d-flex justify-content-center" >
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff', alignContent: 'center' }} >WildCatters Plan</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <div>Text 1</div>
                        <div>Text 2</div>
                        <div>Text 3</div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }} >
                    <Col className="d-flex justify-content-center">
                        <Button style={(planValue === 2 ? btnPlanStyleSelected : btnPlanStyle)} onClick={() => handlePlanChange(2)} >
                            Select WildCatters
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    //render current plan (during trial period)
    const showCurrentPlanTrial = () => {
        return <Card className="mb-4">
            <Card.Header style={{ backgroundColor: '#C8B568' }}>
                <Row>
                    <Col className="d-flex justify-content-center" >
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff', alignContent: 'center' }} >Your Current Plan</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col style={{color: 'black', fontWeight: '700'}} >
                        Your BlackGold trial membership plan will expire on {accountProfile.trialEndDate}.  Upgrade now to continue to enjoy all the benefits of our BlackGold plan!
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }} >
                    <Col className="d-flex justify-content-center">
                        <Button style={btnPlanStyleSelected} onClick={handleUpgradeButton} >
                            Yes, please upgrade my plan!
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    //render current plan expired (trial period expired)
    const showCurrentPlanExpired = () => {
        return <Card className="mb-4">
            <Card.Header style={{ backgroundColor: '#C8B568' }}>
                <Row>
                    <Col className="d-flex justify-content-center" >
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff', alignContent: 'center' }} >Your Current Plan</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col style={{ color: 'black', fontWeight: '700' }} >
                        Your BlackGold trial membership plan has expired!  You are currently enrolled in the WildCatters plan.  Upgrade now to enjoy all the benefits of our BlackGold plan!
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }} >
                    <Col className="d-flex justify-content-center">
                        <Button style={btnPlanStyleSelected} onClick={handleUpgradeButton} >
                            Yes, please upgrade my plan!
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    //render current plan blackgold (paid)
    const showCurrentPlanBlackGold = () => {
        return <Card className="mb-4">
            <Card.Header style={{ backgroundColor: '#C8B568' }}>
                <Row>
                    <Col className="d-flex justify-content-center" >
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff', alignContent: 'center' }} >Your Current Plan</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col style={{ color: 'black', fontWeight: '700' }} >
                        Congratulations, you are currently enrolled in our BlackGold plan!  We hope you're enjoying all the benefits that BlackGold provides!
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    //render current plan past due (payment past due)
    const showCurrentPlanPastDue = () => {
        return <Card className="mb-4">
            <Card.Header style={{ backgroundColor: '#C8B568' }}>
                <Row>
                    <Col className="d-flex justify-content-center" >
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff', alignContent: 'center' }} >Your Current Plan</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col style={{ color: 'black', fontWeight: '700' }} >
                        You are currently enrolled in our BlackGold plan.  However, there's a problem with your payment.  Please contact Support to continue to enjoy all the benefits that BlackGold provides!
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    ////render compare plans
    //const showComparePlansBAK = () => {
    //    return <Card className="mb-4">
    //        <Card.Header style={{ backgroundColor: '#C8B568'}} >
    //            <Row>
    //                <Col className="d-flex justify-content-center">
    //                    <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff' }} >Compare Plans</div>
    //                </Col>
    //            </Row>
    //        </Card.Header>
    //        <Card.Body style={{paddingTop: '0px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px'}} >
    //            <Row style={{borderBottom: '1px solid black'}} >
    //                <Col xs={6} style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: '700', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center"><br/>What you get</Col></Row></Container></Col>
    //                <Col xs={3} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: '#C8B568', color: '#fff', fontWeight: '700', fontSize: '1.3rem', boxShadow: '0px 0px 10px gray', textShadow: '5px 5px 10px black' }} ><Container><Row><Col className="d-flex justify-content-center">BlackGold<br/>(Premium)</Col></Row></Container></Col>
    //                <Col xs={3} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: 'black', color: '#fff', borderBottom: '1px solid #C8B568', fontWeight: '600', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center">WildCatters<br />&nbsp;&nbsp;(No Cost)</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Access to all Operator Data*</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>CDEX</u> Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}>&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>XLSX</u> Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Revenue - PDF Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>1099's</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>JIB - <u>XLSX</u> Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>JIB - PDF Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Priority Online Support</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} className="d-flex justify-content-center" style={{ paddingTop: '10px', paddingBottom: '10px', borderTop: '1px solid black', backgroundColor: '#C8B568' }}><i>*Avail./Participating</i></Col>
    //                {/*<Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '3px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
    //                {/*<Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
    //            </Row>
    //        </Card.Body>
    //    </Card>
    //}

    ////render compare plans
    //const showComparePlansBAKWithCellShadows = () => {
    //    return <Card className="mb-4">
    //        <Card.Header style={{ backgroundColor: '#C8B568' }} >
    //            <Row>
    //                <Col className="d-flex justify-content-center">
    //                    <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff' }} >Compare Plans</div>
    //                </Col>
    //            </Row>
    //        </Card.Header>
    //        <Card.Body style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px' }} >
    //            <Row style={{ borderBottom: '1px solid black' }} >
    //                <Col xs={6} style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: '700', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center"><br />What you get</Col></Row></Container></Col>
    //                <Col xs={3} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: '#C8B568', color: '#fff', fontWeight: '700', fontSize: '1.3rem', boxShadow: '0px 0px 10px gray', textShadow: '5px 5px 10px black' }} ><Container><Row><Col className="d-flex justify-content-center">BlackGold<br />(Premium)</Col></Row></Container></Col>
    //                <Col xs={3} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: 'black', color: '#fff', borderBottom: '1px solid #C8B568', fontWeight: '600', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center">WildCatters<br />&nbsp;&nbsp;(No Cost)</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Access to all Operator Data*</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>CDEX</u> Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}>&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>XLSX</u> Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Revenue - PDF Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>1099's</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>JIB - <u>XLSX</u> Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>JIB - PDF Format</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Priority Online Support</i></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '0px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} className="d-flex justify-content-center" style={{ paddingTop: '10px', paddingBottom: '10px', borderTop: '1px solid black', backgroundColor: '#C8B568' }}><i>*Avail./Participating</i></Col>
    //                {/*<Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '3px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
    //                {/*<Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
    //            </Row>
    //        </Card.Body>
    //    </Card>
    //}

    //render compare plans
    const showComparePlans = () => {
        return <Card className="mb-4">
            <Card.Header style={{ backgroundColor: '#C8B568' }} >
                <Row>
                    <Col className="d-flex justify-content-center">
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff' }} >Compare Plans</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px' }} >
                <Row style={{ borderBottom: '1px solid black' }} >
                    <Col xs={6} style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: '700', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center"><br />What you get</Col></Row></Container></Col>
                    <Col xs={3} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: '#C8B568', color: '#fff', fontWeight: '700', fontSize: '1.3rem', boxShadow: '0px 0px 20px gray', textShadow: '5px 5px 10px black' }} ><Container><Row><Col className="d-flex justify-content-center">BlackGold<br />(Premium)</Col></Row></Container></Col>
                    <Col xs={3} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: 'black', color: '#fff', borderBottom: '1px solid #C8B568', fontWeight: '600', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center">WildCatters<br />&nbsp;&nbsp;(No Cost)</Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Access to all Operator Data*</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>CDEX</u> Format</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}>&nbsp;</Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>XLSX</u> Format</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Revenue - PDF Format</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>1099's*</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>JIB - <u>XLSX</u> Format</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>JIB - PDF Format</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600', marginBottom: '10px' }}><i>Priority Online Support</i></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
                    <Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center" style={{ paddingTop: '10px', paddingBottom: '10px', borderTop: '1px solid black', backgroundColor: '#C8B568' }}><i>*Avail./Participating</i></Col>
                    {/*<Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '3px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
                    {/*<Col xs={3} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
                </Row>
            </Card.Body>
        </Card>
    }

    ////render compare plans
    //const showComparePlans = () => {
    //    return <Card className="mb-4">
    //        <Card.Header style={{ backgroundColor: '#C8B568' }} >
    //            <Row>
    //                <Col className="d-flex justify-content-center">
    //                    <div style={{ fontSize: '1.5rem', fontWeight: '600', color: '#fff' }} >Compare Plans</div>
    //                </Col>
    //            </Row>
    //        </Card.Header>
    //        <Card.Body style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px' }} >
    //            <Row>
    //                <Col xs={6}>
    //                    <Row style={{ borderBottom: '1px solid black', height: '85px' }} >
    //                        <Col xs={12} style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: '700', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center"><br />What you get</Col></Row></Container></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Access to all Operator Data*</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>CDEX</u> Format</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>Revenue - <u>XLSX</u> Format</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Revenue - PDF Format</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>1099's</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', color: '#C8B568', fontWeight: '700' }}><i>JIB - <u>XLSX</u> Format</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>JIB - PDF Format</i></Col>
    //                        </Row>
    //                    <Row>
    //                        <Col xs={12} style={{ paddingTop: '20px', fontSize: '1.3rem', fontWeight: '600' }}><i>Priority Online Support</i></Col>
    //                        </Row>
    //                    {/*<Row>*/}
    //                    {/*    <Col xs={12} className="d-flex justify-content-center" style={{ paddingTop: '10px', paddingBottom: '10px', borderTop: '1px solid black', backgroundColor: '#C8B568' }}><i>*Avail./Participating</i></Col>*/}
    //                    {/*</Row>*/}
    //                </Col>

    //                <Col xs={3} style={{ boxShadow: '0px 0px 10px gray' }}>
    //                    <Row style={{ borderBottom: '1px solid black' }} >
    //                        <Col xs={12} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: '#C8B568', color: '#fff', fontWeight: '700', fontSize: '1.3rem' }} ><Container><Row style={{ height: '85px', marginTop:'0px', marginBottom: '-21px' }}><Col className="d-flex justify-content-center">BlackGold<br />(Premium)</Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                <Row>
    //                    <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={blackCheck} alt="BlackCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //                    </Row>
    //                {/*<Row>*/}
    //                {/*    */}{/*<Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: '#C8B568', boxShadow: '3px 0px 10px gray' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
    //                {/*</Row>*/}
    //        </Col>

    //        <Col xs={3}>
    //            <Row style={{ borderBottom: '1px solid black', height: '85px' }} >
    //                <Col xs={12} style={{ paddingTop: '10px', paddingBottom: '10px', borderLeft: '1px solid black', backgroundColor: 'black', color: '#fff', borderBottom: '1px solid #C8B568', fontWeight: '600', fontSize: '1.3rem' }} ><Container><Row><Col className="d-flex justify-content-center">WildCatters<br />&nbsp;&nbsp;(No Cost)</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }}>&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} ><img src={goldCheck} alt="GoldCheck" style={{ width: '35px', height: '35px' }} /></Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            <Row>
    //                <Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>
    //            </Row>
    //            {/*<Row>*/}
    //            {/*    */}{/*<Col xs={12} style={{ borderLeft: '1px solid black', backgroundColor: 'black' }} ><Container><Row><Col className="d-flex justify-content-center" style={{ paddingTop: '10px' }} >&nbsp;</Col></Row></Container></Col>*/}
    //            {/*</Row>*/}
    //                </Col>
    //            </Row>
    //        </Card.Body>
    //    </Card>
    //}

    return (
        <div>     
            <h1>Membership Plan</h1>
            <div><br /></div>

            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showMembership()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <MembershipModal
                show={memberModalShow}
                onHide={() => setMemberModalShow(false)}
                onMemberButtonClick={() => navigate('/membership')}
            />
      </div>
    );
}

export default Membership;
