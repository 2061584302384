import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

//TODO: think we can get rid of owner here, and just use criteriaIndex for setCurrentPage() and pageClickHandler() calls
//(would need to update those methods to use criteriaIndex also)
const MatchPaginationComponent = ({
    criteriaIndex,
    owner,
    itemsCount,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    pageClickHandler,
    alwaysShown = true
}) => {
    const pagesCount = Math.ceil(itemsCount / itemsPerPage);
    const isPaginationShown = alwaysShown ? true : pagesCount > 1;
    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;

    //if (owner.receiverId === 2) {
    //    console.log('MatchPager for: ' + owner.ownerName + ', ItemsCount: ' + itemsCount + ', ItemsPerPage: ' + itemsPerPage + ', CurrentPage: ' + currentPage + ', PagesCount: ' + pagesCount + ', CriteriaIndex: ' + criteriaIndex);
    //}

    const changePage = number => {        
        //console.log('Match changePage CurrentPage: ' + currentPage + ', CriteriaIndex: ' + criteriaIndex);
        if (currentPage === number) return;
        setCurrentPage(owner, number);
    };

    const onPageNumberClick = pageNumber => {
        //console.log('Match onPageNumberClick CurrentPage: ' + currentPage + ', CriteriaIndex: ' + criteriaIndex);
        if (currentPage === pageNumber) {
            return;
        }
        else {            
            setCurrentPage(owner, pageNumber);
            pageClickHandler(owner, pageNumber, itemsPerPage);
        }
    };

    const onPreviousPageClick = () => {
        //console.log('Match onPreviousPageClick CurrentPage: ' + currentPage + ', CriteriaIndex: ' + criteriaIndex);
        changePage(currentPage => currentPage - 1);
        pageClickHandler(owner, currentPage - 1, itemsPerPage);
    };

    const onNextPageClick = () => {
        changePage(currentPage => currentPage + 1);
        pageClickHandler(owner, currentPage + 1, itemsPerPage);
    };

    const setLastPageAsCurrent = () => {
        //console.log('Match setLastPageAsCurrent CurrentPage: ' + currentPage + ', CriteriaIndex: ' + criteriaIndex);
        if (currentPage > pagesCount) {
            setCurrentPage(owner, pagesCount);
        }
    };

    let isPageNumberOutOfRange;

    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers =
            Math.abs(pageNumber - currentPage) <= 9;

        //console.log('Match pageNumbers CurrentPage: ' + currentPage + ', CriteriaIndex: ' + criteriaIndex);

        if (
            isPageNumberFirst ||
            isPageNumberLast ||
            isCurrentPageWithinTwoPageNumbers
        ) {
            isPageNumberOutOfRange = false;
            return (
                <Pagination.Item
                    key={pageNumber}
                    onClick={() => onPageNumberClick(pageNumber)}
                    active={pageNumber === currentPage}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" disabled />;
        }

        return null;
    });

    useEffect(setLastPageAsCurrent, [pagesCount]);
    //useEffect(pageNumbers, [pagesCount]);

    return (
        <>
            {isPaginationShown && (
                <Pagination>
                    <Pagination.Prev
                        onClick={onPreviousPageClick}
                        disabled={isCurrentPageFirst}
                    />
                    {pageNumbers}
                    <Pagination.Next
                        onClick={onNextPageClick}
                        disabled={isCurrentPageLast}
                    />
                </Pagination>
            )}
        </>
    );
};

export default MatchPaginationComponent;