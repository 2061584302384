import React, { useCallback, useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import Sidebar, { SidebarOverlay } from './Sidebar/Sidebar'
import Header from './Header/Header'
//import Header from './Header/Header_v3'
import PublicHeader from './Header/PublicHeader'
import Footer from './Footer/Footer'
import PublicFooter from './Footer/PublicFooter'
import { Container } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet } from "../../services/AuthService.js";

export default function AdminLayout({ children }) {
    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();

    // Show status for xs screen
    const [isShowSidebar, setIsShowSidebar] = useState(false)

    // Show status for md screen and above
    //const [isShowSidebarMd, setIsShowSidebarMd] = useState(true)
    const [isShowSidebarMd, setIsShowSidebarMd] = useState(false)

    // Show status for md screen and above (home page)
    const [isHomeShowSidebarMd, setIsHomeShowSidebarMd] = useState(false)

    //Show logged in status
    const [currentAccount, setCurrentAccount] = useState();
    const [accountProfile, setAccountProfile] = useState({ email: "", isAdmin: false, hasImpersonate: false, firstName: "", lastName: "", nickname: "", accountId: 0, accountName: "", accountType: "" });
    
    const toggleIsShowSidebar = () => {
        setIsShowSidebar(!isShowSidebar)
    }

    const toggleIsShowSidebarMd = () => {
        console.log('Toggling sidebar...');
        //const newValue = !isShowSidebarMd
        //localStorage.setItem('isShowSidebarMd', newValue ? 'true' : 'false')
        //setIsShowSidebarMd(newValue)
        setIsShowSidebarMd(!isShowSidebarMd)
  }

      // Clear and reset sidebar
      const resetIsShowSidebar = () => {
        setIsShowSidebar(false)
      }

      const onResize = useCallback(() => {
        resetIsShowSidebar()
      }, [])

      const { ref } = useResizeDetector({ onResize })

    ////update current account
    //const updateCurrentAccount = async (updatedAccount) => {
    //    console.log('Updating Current User...');

        //if (updatedAccount == null) {
        //    console.log('Removing Current User...');
        //    localStorage.removeItem("currentAccount");
        //}
        //else {
        //    console.log('Adding Updated Current User...');
        //    //store user account locally (as json string)
        //    localStorage.setItem("currentAccount", JSON.stringify(updatedAccount));
        //}

        //update state variable
        //setCurrentAccount(updatedAccount);
    //}

    const [logoutRedirectUrl, setLogoutRedirectUrl] = useState(window.location.origin);
    const [queryParameters] = useSearchParams();

    useEffect(() => {
        console.log('AdminLayout loading...');

        //check for external signin param
        var isExternal = queryParameters.get("signin");
        if (isExternal !== null) {
            var logoutRedirectUrl = "https://totdata.com";
            localStorage.setItem('logoutRedirectUrl', logoutRedirectUrl);
        }        

        //check local storage for external redirect url
        const externalRedirectUrl = localStorage.getItem('logoutRedirectUrl');
        if (externalRedirectUrl) {
            setLogoutRedirectUrl(externalRedirectUrl);
            console.log('Logout Redirect Url: ' + externalRedirectUrl);
        } else {
            console.log('Logout Redirect Url: ' + window.location.origin);
        }

        //alert('Admin Is Auth: ' + isAuthenticated);
        if (isAuthenticated) {

            //console.log('User: ' + user.email);
            //console.log('User All: ' + JSON.stringify(user));

            const getClaims = async () => {
                const claims = await getIdTokenClaims();
                console.log('Claims: ' + JSON.stringify(claims));

                //claims["totdata/app_metadata"].account["name"] = "yoAccountName";
                //claims["totdata/app_metadata"].profile["firstName"] = "yoFirstName"
                //claims["totdata/app_metadata"].profile["lastName"] = "yoLastName"

                const email = user.email;
                const isAdmin = claims["totdata/roles"].includes("admin");
                const hasImpersonate = claims["totdata/roles"].includes("impersonate");
                const firstName = claims["totdata/app_metadata"].profile["firstName"];
                const lastName = claims["totdata/app_metadata"].profile["lastName"];
                const nickname = user.nickname;
                const accountId = claims["totdata/app_metadata"].account["id"];
                const accountName = claims["totdata/app_metadata"].account["name"];
                const accountType = claims["totdata/app_metadata"].account["type"];
                const currentAccountId = claims["totdata/app_metadata"].currentAccountId;

                console.log('PROFILE:');
                console.log('Email: ' + email);
                console.log('IsAdmin: ' + isAdmin);
                console.log('FirstName: ' + firstName);
                console.log('LastName: ' + lastName);
                console.log('Nickname: ' + nickname);
                console.log('ACCOUNT:');
                console.log('Id: ' + accountId);
                console.log('Name: ' + accountName);
                console.log('Type: ' + accountType);
                console.log('SELECTED ACCOUNT:');
                console.log('Selected AcountId: ' + currentAccountId);

                setAccountProfile({ email: user.email, isAdmin: isAdmin, hasImpersonate: hasImpersonate, firstName: firstName, lastName: lastName, nickname: nickname, accountId: accountId, accountName: accountName, accountType: accountType });
                setCurrentAccount({ id: currentAccountId });


                let subscriptionTypeId = 0;
                let subscriptionStatusId = 0;
                let displayTrialModal = false;

                const date = new Date();
                var day = date.getDate() - 1;
                date.setDate(day);
                let trialEndDate = date;

                setAccountProfile({ email: user.email, isAdmin: isAdmin, hasImpersonate: hasImpersonate, firstName: firstName, lastName: lastName, nickname: nickname, accountId: accountId, accountName: accountName, accountType: accountType, subscriptionTypeId: subscriptionTypeId, subscriptionStatusId: subscriptionStatusId, trialEndDate: '01/01/2024', displayTrialModal: displayTrialModal });
                setCurrentAccount({ id: currentAccountId });
                
                populateMembership(currentAccountId)
                    .then(response => {
                        //console.log('User Profile: ' + response.data.email + ' - ' +  response.data.firstName);
                        if (response.data.length === 0) {
                            console.log('failed to get account membership info');
                        }
                        else {
                            subscriptionTypeId = response.data.subscriptionTypeId ?? 0;
                            subscriptionStatusId = response.data.subscriptionStatusId ?? 0;
                            trialEndDate = response.data.trialEndDateString ?? '01/01/2024';
                            displayTrialModal = response.data.displayTrialModal ?? false;
                            localStorage.setItem('displayTrialModal', displayTrialModal);

                            console.log('got account membership info');
                        }
                    })
                    .catch(ex => {
                        //setPageStatus('error');
                        //setPageErrors(ex.message);

                        //setDataStatus('error');
                        //setDataErrors(ex.message);
                    })
                    .finally(response => {
                        setAccountProfile({ email: user.email, isAdmin: isAdmin, hasImpersonate: hasImpersonate, firstName: firstName, lastName: lastName, nickname: nickname, accountId: accountId, accountName: accountName, accountType: accountType, subscriptionTypeId: subscriptionTypeId, subscriptionStatusId: subscriptionStatusId, trialEndDate: trialEndDate, displayTrialModal: displayTrialModal });
                        //setCurrentAccount({ id: currentAccountId });
                    });

                return claims;
            }
            
            getClaims()
                .then(result => {
                    let hasAccount = result["totdata/app_metadata"].hasAccount;
                    hasAccount = (hasAccount == undefined ? false : hasAccount ?? false);
                    console.log('Set Has Account: ' + hasAccount);
                });
        }
    }, [setIsShowSidebarMd, isAuthenticated])

    //populate account membership
    const populateMembership = async (accountId) => {
        //console.log('Getting Account Membership...');

        const apiUrl = 'accounts/' + accountId + '/membership';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

  return (
    <>
          <div ref={ref} className="position-absolute w-100" />          
        {(() => {
              if (accountProfile) {
                  //return <Sidebar isShow={isShowSidebar} isShowMd={(location.pathname.split("/")[1] !== '') && isShowSidebarMd} currentAccount = {currentAccount} />
                  return <Sidebar isShow={isShowSidebar} isShowMd={isShowSidebarMd} accountProfile={accountProfile} />
            }
        })()}
               
        {(() => {
                  if (isAuthenticated) {
                      return <div className="wrapper d-flex flex-column min-vh-100">
                          <Header setAccountProfile={setAccountProfile} toggleSidebar={toggleIsShowSidebar} toggleSidebarMd={toggleIsShowSidebarMd} setIsShowSidebarMd={setIsShowSidebarMd} accountProfile={accountProfile} baseUrl={baseUrl} logoutRedirectUrl={logoutRedirectUrl} />
                          <div className="body flex-grow-1 px-3">
                              <Container fluid="lg">
                                  <Outlet context={[currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd]} />
                              </Container>
                          </div>
                          <Footer />
                      </div>
            }
            else {
                      return <div className="wrapper d-flex flex-column min-vh-100" style={{ backgroundColor: '#141416' }}>
                          <PublicHeader toggleSidebar={toggleIsShowSidebar} toggleSidebarMd={toggleIsShowSidebarMd} setAccountProfile={setAccountProfile} accountProfile={accountProfile} />
                          <Outlet context={[currentAccount, accountProfile, setAccountProfile]} />
                          {/*<PublicFooter />*/}
                      </div>
            }
        })()}  
          
        {(() => {
            if (currentAccount) {
                return <SidebarOverlay isShowSidebar={isShowSidebar} toggleSidebar={toggleIsShowSidebar} />
            }
        })()}
    </>
  )
}
