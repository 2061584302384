import React, { useState } from 'react';
import { Badge, Dropdown, Nav, NavItem, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import LoginButton from "../../../components/buttons/login-button.js";
import LogoutButton from "../../../components/buttons/logout-button.js";
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faCreditCard,
  faEnvelopeOpen,
  faFile,
  faMessage,
  faUser,
    faBuilding,
    faContactBook,
    faBookmark,
  faCompass
} from '@fortawesome/free-regular-svg-icons'
import {
    faChevronDown,
    faRibbon
} from '@fortawesome/free-solid-svg-icons'
import {
  faGear, faLink, faPowerOff,
} from '@fortawesome/free-solid-svg-icons'

import arrowDownImage from './/./../../../assets/img/chevron_gold_circle_down.png'

const ProfileDropdownItem = (props) => {
  const { icon, children } = props

  return (
    <>
      <FontAwesomeIcon className="me-2" icon={icon} fixedWidth />
      {children}
    </>
  )
}

const imgStyle = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    color: 'transparent',
    transition: 'transform 0.5s ease-in-out'
};

export default function HeaderProfileNav(props) {

    //stateful properties (hooks)
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const { accountProfile, setAccountProfile, toggleSidebarMd, setIsShowSidebarMd, logoutRedirectUrl } = props

    const [menuOpen, setMenuOpen] = useState(false);
    const rotated = document.getElementById("rotated");

    //handle menu item
    const handleMenuItemClick = async (navTarget) => {

        if (navTarget == 'logout') {
            // Call updatesetAccountProfileCurrentAccount on parent
            setAccountProfile(null);
            setIsShowSidebarMd(false);

            //remove external logout redirect url (for future calls only, after logout; this call will still use if specified)
            localStorage.removeItem('logoutRedirectUrl');

            //remove sessionData from local storage
            localStorage.removeItem('sessionData');

            //remove dataFileState from local storage
            localStorage.removeItem('dataFileState');

            //log out
            logout({
                logoutParams: {
                    returnTo: logoutRedirectUrl,
                },
            });
        }
        else {
            //alert('/' + navTarget);
            navigate('/' + (navTarget == 'logout' ? '' : navTarget));
        }
    };

    //handle menu item
    const handleMenuClick = async () => {
        setMenuOpen(!menuOpen);

        if (menuOpen) {
            rotated.style.transform = "rotate(0deg)";
        } else {
            rotated.style.transform = "rotate(180deg)";
        }
    };

    return (
      <>        
        <Nav>                
                <Dropdown as={NavItem} onClick={() => handleMenuClick()}>
                    <Dropdown.Toggle variant="link" bsPrefix="shadow-none" className="py-0 px-2 rounded-0" id="dropdown-profile" >
                        <div className="avatar position-relative">
                            <img
                                id="rotated" 
                                object-fit="fill"
                                className="rounded-circle"
                                src={arrowDownImage}
                                alt="user@email.com"
                                style={imgStyle}
                            />
                    </div>
                </Dropdown.Toggle>
                {(() => {
                        if (accountProfile != null) {
                        return <Dropdown.Menu className="pt-0">                        
                                    <Dropdown.Header className="bg-light fw-bold rounded-top">Account</Dropdown.Header>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('account')}>
                                        <ProfileDropdownItem icon={faBookmark}>Overview</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('profile')}>
                                        <ProfileDropdownItem icon={faUser}>My Profile</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('companies')}>
                                        <ProfileDropdownItem icon={faBuilding}>Companies</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('membership')}>
                                        <ProfileDropdownItem icon={faRibbon}>Membership Plan</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Header className="bg-light fw-bold">Settings</Dropdown.Header>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('settings')}>
                                        <ProfileDropdownItem icon={faGear}>Settings</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Header className="bg-light fw-bold">About</Dropdown.Header>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('contact')}>
                                        <ProfileDropdownItem icon={faContactBook}>Contact</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => handleMenuItemClick('logout')}>
                                        <ProfileDropdownItem icon={faPowerOff}>Logout</ProfileDropdownItem>
                                    </Dropdown.Item>                            
                                </Dropdown.Menu>
                            }
                    else {
                        return <Dropdown.Menu className="pt-0">
                                    <Dropdown.Header className="bg-light fw-bold rounded-top">Account</Dropdown.Header>
                                    <Dropdown.Item onClick={() => handleMenuItemClick('register')}>
                                        <ProfileDropdownItem icon={faLink}>Link to My Documents</ProfileDropdownItem>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => handleMenuItemClick('logout')}>
                                        <ProfileDropdownItem icon={faPowerOff}>Logout</ProfileDropdownItem>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                    }
                })()}
            </Dropdown>        
        </Nav>
      </>
  )
}
