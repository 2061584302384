import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Tab, Tabs } from 'react-bootstrap';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
//import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

const CdexCodes = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const params = useParams();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [revLineTypes, setRevLineTypes] = useState([{ code: '', value: '' }, { code: 'R', value: 'REV' }, { code: 'T', value: 'TAX' }, { code: 'D', value: 'DED' }, { code: 'N', value: 'NET' }]);
    const [originalProductCodes, setOriginalProductCodes] = useState([]);
    const [productCodes, setProductCodes] = useState([]);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [anyProductDirty, setAnyProductDirty] = useState(false);
    const [anyTransactionDirty, setAnyTransactionDirty] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [dataCriteria, setDataCriteria] = useState({
        skipCount: 0,
        takeCount: 50
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const companyTopRef = useRef(null);
    const editRef = useRef(null);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await initializeForm()
                .then(initializeResponse => {
                    populateCdexProductCodes(1)
                        .then(response => {
                            setPageStatus('ready')
                        })
                        .catch(ex => {
                            setPageStatus('error');
                            setPageErrors(ex.message);

                            setDataStatus('error');
                            setDataErrors(ex.message);
                        })
                        .finally(response => {
                            //do something
                        })
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOperators() from useEffect
        //console.log('Initializing form...');

        return { success: true };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    //populate productCodes
    const populateCdexProductCodes = async (currentPage, reset) => {
        //console.log('Current Page: ' + currentPage);
        
        //note: have to recalcaulate skipCount here since state updates aren't active until re-render
        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        //console.log('Skip Count: ' + skipCount);

        //setDataStatus('loading');

        const currentCriteria = {
            skipCount: skipCount,
            takeCount: dataCriteria.takeCount
        }
        
        await getCdexProductCodes(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    //console.log(response.data);
                    setHoveredIndex(null);
                    setSelectedProductIndex(null);
                    setSelectedProduct(null);
                    setAnyProductDirty(false);
                    setAnyTransactionDirty(false);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Company Count: ' + response.data[0].rowCount);
                    setIsReadOnly(true);
                    setHoveredIndex(null);
                    setSelectedProductIndex(0);
                    setSelectedProduct(response.data[0]);
                    setAnyProductDirty(false);
                    setAnyTransactionDirty(false);

                    setRowCount(response.rowCount);
                    setOriginalProductCodes(response.data);
                    setProductCodes(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //get productCodes
    const getCdexProductCodes = async (criteria) => {
        //console.log('Getting CdexProductCodes...');

        const apiUrl = 'accounts/' + currentAccount.id + '/cdexproductcodes?skipcount=' + criteria.skipCount + '&takecount=' + criteria.takeCount;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json();
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, rowCount: json.rowCount, data: json.data };
    };

    //handle product changes
    const handleProductChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        let value = event.target.value;
        //console.log('ownerId: ' + ownerId, 'Field: ' + fieldName + ', Value: ' + value);

        if (fieldName == 'cdexProductCode') {
            if (value.length > 2) {
                value = value.substring(0, 2);
            }
        }

        let anyProductDirty = false;
        let anyProductInvalid = false;
        const newProductList = [];
        productCodes.map((product, index) => {
            if (index === selectedProductIndex) {
                const originalProduct = originalProductCodes[index];
                const originalValue = originalProduct.cdexProductCode;

                const isDirty = (value !== originalValue ? true : false);
                const isValid = (value !== '' ? true : false);

                newProductList.push({
                    ...product,
                    [fieldName]: value,
                    isDirty: isDirty,
                    isValid: isValid
                });

                if (isDirty && isValid)
                    anyProductDirty = true;

                if (isDirty && !isValid)
                    anyProductInvalid = true;

                //console.log('Updating product: ' + product.productCode);
            }
            else {
                newProductList.push(product);

                if (product.isDirty && product.isValid)
                    anyProductDirty = true;

                if (product.isDirty && !product.isValid)
                    anyProductInvalid = true;
            }            
        });
        setProductCodes(newProductList);

        setAnyProductDirty(anyProductDirty && !anyProductInvalid);

        newProductList.map(product => {
            console.log('New Product: ' + product.productCode + ', ' + product.productDesc + ', ' + product.cdexProductCode + ', ' + product.isDirty);
        });
    }

    //handle transaction changes
    const handleTransactionChange = (currentIndex, event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        let value = event.target.value;
        //console.log('Current Index: ' + currentIndex + ', Field: ' + fieldName + ', Value: ' + value);

        if (fieldName == 'cdexTransactionCode') {
            if (value.length > 3) {
                value = value.substring(0, 3);
            }
        }

        let anyTransactionDirty = false;
        let anyTransactionInvalid = false;        
        const newProductList = [];
        const newTransactionList = [];
        productCodes.map((product, productIndex) => {
            const originalProduct = originalProductCodes[productIndex];

            if (productIndex === selectedProductIndex) {

                product.cdexTransactionRowDTO.data.map((transaction, transactionIndex) => {

                    if (transactionIndex === currentIndex) {
                        const originalTransaction = originalProduct.cdexTransactionRowDTO.data[transactionIndex];
                        const originalCdexTranCode = originalTransaction.cdexTransactionCode;
                        const originalRevLineTypeCode = originalTransaction.revLineTypeCode;

                        const cdexTranCodeValue = fieldName === 'cdexTransactionCode' ? value : transaction.cdexTransactionCode;
                        const revLineTypeCode = fieldName === 'revLineTypeCode' ? value : transaction.revLineTypeCode;
                        const isDirty = (cdexTranCodeValue !== originalCdexTranCode || revLineTypeCode !== originalRevLineTypeCode);
                        const isValid = (cdexTranCodeValue !== '' && revLineTypeCode !== '') || (cdexTranCodeValue === '' && revLineTypeCode === '');

                        newTransactionList.push({
                            ...transaction,
                            [fieldName]: value,
                            isDirty: isDirty,
                            isValid: isValid
                        });

                        if (isDirty && isValid)
                            anyTransactionDirty = true;

                        if (isDirty && !isValid)
                            anyTransactionInvalid = true;
                    }
                    else {
                        newTransactionList.push(transaction);

                        if (transaction.isDirty && transaction.isValid)
                            anyTransactionDirty = true;

                        if (transaction.isDirty && !transaction.isValid)
                            anyTransactionInvalid = true;
                    }
                });

                newProductList.push({
                    ...product,
                    cdexTransactionRowDTO: { ...product.cdexTransactionRowDTO, data: newTransactionList }
                });

                //console.log('Updating transaction: ' + transaction.transactionCode);
            }
            else {
                newProductList.push(product);
            }
        });
        setProductCodes(newProductList);
        setSelectedProduct(newProductList[selectedProductIndex]);
        setAnyTransactionDirty(anyTransactionDirty && !anyTransactionInvalid);

        newProductList.map((product, productIndex) => {
            console.log('New Product: ' + product.productCode + ', ' + product.productDesc + ', ' + product.cdexProductCode + ', ' + product.isDirty);

            if (productIndex === selectedProductIndex) {
                product.cdexTransactionRowDTO.data.map(transaction => {
                    console.log('New Transaction: ' + transaction.transactionCode + ', ' + transaction.transactionDesc + ', ' + transaction.cdexTransactionCode + ', ' + transaction.revLineTypeCode + ', ' + transaction.isDirty + ', ' + transaction.isValid);
                });
            }
        });
    }

    //update products
    const updateProducts = async () => {
        console.log('Updating Products...');
        setDataStatus('loading');

        //disable Map buttons while mapping
        const tranDirtyState = anyTransactionDirty;
        setAnyTransactionDirty(false);
        setAnyProductDirty(false);

        //console.log('Id: ' + selectedProduct.id + ', Product Code: ' + selectedProduct.productCode);

        await saveProducts()
            .then(response => {
                if (response.success) {
                    setAnyProductDirty(false);
                    setOriginalProductCodes(response.data);
                    setProductCodes(response.data);
                    setSelectedProduct(response.data[selectedProductIndex]);
                    setAnyTransactionDirty(tranDirtyState);
                    setDataStatus('ready')
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save products
    const saveProducts = async () => {
        //console.log('Saving Products...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updateproductcodes';
        const jsonBody = JSON.stringify(productCodes);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);
        const json = await response.json();

        return { success: response.ok, data: json };
    };

    //update transaction
    const updateTransactions = async () => {
        console.log('Updating Transactions...');
        setDataStatus('loading');

        //disable Map buttons while mapping
        const prodDirtyState = anyProductDirty;
        setAnyTransactionDirty(false);
        setAnyProductDirty(false);

        //console.log('Id: ' + selectedProduct.id + ', Product Code: ' + selectedProduct.productCode);

        await saveTransactions()
            .then(response => {
                if (response.success) {
                    setAnyTransactionDirty(false);                   

                    const newProductList = [];
                    productCodes.map((product, index) => {
                        if (index === selectedProductIndex) {
                            newProductList.push(response.data);
                        }
                        else {
                            newProductList.push(product);
                        }
                    });
                    setOriginalProductCodes(newProductList);
                    setProductCodes(newProductList);
                    setSelectedProduct(newProductList[selectedProductIndex]);

                    setAnyProductDirty(prodDirtyState);
                    setDataStatus('ready')
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save transactions
    const saveTransactions = async () => {
        console.log('Saving Transactions...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updatetransactioncodes';
        const jsonBody = JSON.stringify(selectedProduct);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);
        const json = await response.json();

        return { success: response.ok, data: json };
    };

    //handle mouse over
    const handleMouseOver = async (index) => {
        console.log('Mouse Over: ' + index);

        if (index != selectedProductIndex) {
            setHoveredIndex(index);
        }
    };

    //handle mouse leave
    const handleMouseLeave = async (index) => {
        console.log('Mouse Leave: ' + index);

        if (index != selectedProductIndex) {
            setHoveredIndex(null);
        }
    };

    //handle mouse click
    const handleMouseClick = async (index) => {
        console.log('Mouse Click: ' + index);

        setHoveredIndex(null);
        setSelectedProductIndex(index);
        setSelectedProduct(productCodes[index]);

        let anyTransactionDirty = false;
        let anyTransactionInvalid = false;
        productCodes[index].cdexTransactionRowDTO.data.map(transaction => {
            if (transaction.isDirty && transaction.isValid) {
                anyTransactionDirty = true;
            }

            if (transaction.isDirty && !transaction.isValid) {
                anyTransactionInvalid = true;
            }
        });
        setAnyTransactionDirty(anyTransactionDirty && !anyTransactionInvalid);
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        //console.log('Current Page: ' + currentPage);

        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        //don't need to do here, since pagination component sets before calling this method
        //setCurrentPage(1);

        await populateCdexProductCodes(currentPage);
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{paddingLeft: '12px', paddingRight: '10px'}} >
                    {(() => {
                        switch (dataStatus) {
                            case 'nodata':
                                return <p>No Data Loaded.</p>
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <Row>No Data Loaded.</Row>
                        }
                    })()}
                </Card.Body>
            </Card>
        );
    }

    //render productCodes
    const showData = () => {
        return (
            <Row>
                <Col xs={5} style={{ marginTop: '-16px', marginBottom: '-16px', overflow: 'auto', paddingRight: '0px', paddingLeft: '0px', borderRight: 'solid lightgray 1px' }} >
                {(() => {
                    switch (dataStatus) {
                        case 'ready':
                            return showDataMaster()
                        default:
                            return null
                    }
                    })()}
                </Col>
                <Col xs={7} style={{ height: '530px', overflow: 'auto', paddingLeft: '20px', marginTop: '-10px', marginBottom: '-16px' }}>
                    {(() => {
                        switch (dataStatus) {
                            case 'ready':
                                return showDataDetail()
                            default:
                                return null
                        }
                    })()}
                </Col>
            </Row>
        );
    }

    //render master pane (productCodes list)
    const showDataMaster = () => {
        return (
            <>
                <ul style={{ listStyleType: "none", height: "515px", marginRight: '12px' }}>
                    {productCodes.map((cdexProductCode, index) => (
                        <li key={index} ref={(index == 0 ? companyTopRef : null)}
                            style={{ cursor: 'pointer', paddingLeft: '20px', marginLeft: '-42px', marginRight: '0px', fontSize: '.9rem' }}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            onClick={() => handleMouseClick(index)}>
                            <Row style={{
                                borderBottom: 'solid lightgray 1px',
                                backgroundColor: (hoveredIndex == index ? '#9AC5F4' : (selectedProductIndex == index ? '#4F709C' : 'white')),
                                color: (hoveredIndex == index ? 'white' : (selectedProductIndex == index ? 'white' : 'black'))
                            }} >       
                                <Col xs={3} >
                                    <div>{cdexProductCode.productCode}</div>
                                </Col>
                                <Col xs={5} >
                                    <div>{cdexProductCode.productDesc}</div>
                                </Col>
                                <Col xs={4}>
                                        {(() => {
                                        if (!cdexProductCode.isReadOnly) {
                                                return <Form.Group className="mb-3" controlId="cdexProductCode">
                                                    <Form.Control name="cdexProductCode" size="xs" aria-label="CDEX Product Code" value={cdexProductCode.cdexProductCode} style={{ width: '100' }} onChange={e => handleProductChange(e)} />
                                                        </Form.Group>
                                            }
                                            else {
                                                return <div>{cdexProductCode.cdexProductCode}</div>;
                                            }
                                        })()}                              
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    //render detail pane (company display or edit)
    const showDataDetail = () => {
        return (
            <>
                {(() => {
                    if (isReadOnly) {
                        //return showDataDisplay()
                        return showDataEdit()
                    }
                    else {
                        //return showDataEdit()
                        return showDataDisplay()
                    }
                })()}
            </>
        );
    }

    //render company detail
    const showDataDisplay = () => {
        return (
            <>
                <Row>
                    <Col xs={8}>
                        <div style={{ fontWeight: '600', fontSize: '1.25rem', marginTop: '10px' }} >Sender Detail</div>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end" style={{marginTop: '13px'}} >
                        Company Code:&nbsp;&nbsp;{selectedProduct.companyCode}&nbsp;&nbsp;&nbsp;&nbsp;
                    </Col>
                </Row>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }} />
                <Row style={{ paddingRight: '10px', paddingTop: '10px' }}>
                    <Col xs={2}>
                        Name:
                    </Col>
                    <Col xs={10}>
                        <div>
                            {selectedProduct.companyName}
                        </div>                        
                        <div>
                            {((selectedProduct.additionalNameInfo ?? '') == '' ? null : '(' + selectedProduct.additionalNameInfo + ')')}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Address:
                    </Col>
                    <Col xs={10}>
                        <div>
                            {selectedProduct.address1} {selectedProduct.address2} {selectedProduct.address3}
                        </div>
                        <div>
                            {selectedProduct.city}, {selectedProduct.state} {selectedProduct.zip}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Email:
                    </Col>
                    <Col xs={10}>
                        <div style={{ color: ((selectedProduct.email ?? '') == '' ? 'red' : 'black') }}>{(selectedProduct.email ?? '') == '' ? 'Not available' : selectedProduct.email}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Phone:
                    </Col>
                    <Col xs={10}>
                        <div style={{ color: ((selectedProduct.phone ?? '') == '' ? 'red' : 'black') }}>{(selectedProduct.phone ?? '') == '' ? 'Not available' : selectedProduct.phone}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Fax:
                    </Col>
                    <Col xs={10}>
                        <div style={{ color: ((selectedProduct.fax ?? '') == '' ? 'red' : 'black') }}>{(selectedProduct.fax ?? '') == '' ? 'Not available' : selectedProduct.fax}</div>
                    </Col>                    
                </Row>
                //<Row>
                //    <Col xs={2}>
                //        Tax Reg #:
                //    </Col>
                //    <Col xs={10}>
                //        <div style={{ color: ((selectedProduct.taxRegNumber ?? '') == '' ? 'red' : 'black') }}>{(selectedProduct.taxRegNumber ?? '') == '' ? 'Not available' : selectedProduct.taxRegNumber}</div>
                //    </Col>
                //</Row>
                <Row>
                    <Col xs={3}>
                        CDEX Sender Code:
                    </Col>
                    <Col xs={9}>
                        <div style={{ color: ((selectedProduct.cdexSenderCode ?? '') == '' ? 'red' : 'black') }}>{(selectedProduct.cdexSenderCode ?? '') == '' ? 'Not available' : selectedProduct.cdexSenderCode}</div>
                    </Col>
                </Row>
            </>
        );
    }

    //render detail pane (cdexTransactionCodes list)
    const showDataEdit = () => {
        return (
            <>
                <ul style={{ listStyleType: "none", height: "515px", marginRight: '12px' }}>
                    {selectedProduct.cdexTransactionRowDTO.data.map((cdexTransactionCode, index) => (
                        <li key={index} ref={(index == 0 ? companyTopRef : null)}
                            style={{ paddingLeft: '20px', marginLeft: '-42px', marginRight: '0px', fontSize: '.9rem' }}>
                            {/*onMouseOver={() => handleMouseOver(index)}*/}
                            {/*onMouseLeave={() => handleMouseLeave(index)}*/}
                            {/*onClick={() => handleMouseClick(index)}>*/}
                            <Row style={{
                                borderBottom: 'solid lightgray 1px',
                                //backgroundColor: (hoveredIndex == index ? '#9AC5F4' : (selectedProductIndex == index ? '#4F709C' : 'white')),
                                //color: (hoveredIndex == index ? 'white' : (selectedProductIndex == index ? 'white' : 'black'))
                            }} >
                                <Col xs={3} >
                                    <div>{cdexTransactionCode.transactionCode}</div>
                                </Col>
                                <Col xs={3} >
                                    <div>{cdexTransactionCode.transactionDesc}</div>
                                </Col>
                                <Col xs={3} >
                                    {(() => {
                                        if (!cdexTransactionCode.isReadOnly) {
                                            return <Form.Group className="mb-3" controlId="cdexTransactionCode">
                                                <Form.Control name="cdexTransactionCode" size="xs" aria-label="CDEX Transaction Code" value={cdexTransactionCode.cdexTransactionCode} style={{ width: '100' }} onChange={(e) => handleTransactionChange(index, e)} />
                                            </Form.Group>
                                        }
                                        else {
                                            return <div>{cdexTransactionCode.cdexTransactionCode}</div>;
                                        }
                                    })()}
                                </Col>
                                <Col xs={3} >
                                    {(() => {
                                        if (!cdexTransactionCode.isReadOnly) {
                                            return <Form.Group className="mb-3" controlId="companyCode">
                                                <Form.Select name="revLineTypeCode" size="sm" aria-label="REV Line Type" value={cdexTransactionCode.revLineTypeCode} onChange={(e) => handleTransactionChange(index, e)} >
                                                    {revLineTypes.map((type, i) => (
                                                        <option key={i} value={type.code}>{type.value}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        }
                                        else {
                                            switch (cdexTransactionCode.revLineTypeCode) {
                                                case 'R':
                                                    return <div>REV</div>
                                                case 'T':
                                                    return <div>TAX</div>
                                                case 'D':
                                                    return <div>DED</div>
                                                case 'N':
                                                    return <div>NET</div>
                                                default:
                                                    return <div></div>
                                            }
                                        }
                                    })()}
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    //pagination example
    //https://github.com/lukaaspl/ellipsis-pagination/blob/master/src/components/Pagination.js

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container>         
                <Row>
                    <Col xs={5}>
                        <Button
                            size='sm'
                            style={{ width: '200px' }}
                            disabled={!anyProductDirty}
                            onClick={anyProductDirty ? () => updateProducts() : null}
                        >
                            Map Product Codes
                        </Button>
                    </Col>
                    <Col xs={7}>
                        <Button
                            size='sm'
                            style={{ width: '200px' }}
                            disabled={!anyTransactionDirty}
                            onClick={anyTransactionDirty ? () => updateTransactions() : null}
                        >
                            Map Transaction Codes
                        </Button>
                    </Col>
                </Row>
                <Row style={{fontSize: '.9rem', marginTop: '20px'}} >
                    <Col xs={5}>
                        <Row>
                            <Col xs={3} style={{marginLeft: '-16px'}}>Product Code</Col>
                            <Col xs={5} style={{ marginLeft: '5px' }}>Product Desc</Col>
                            <Col xs={4} style={{ marginLeft: '10px' }}>CDEX Product Code</Col>
                        </Row>
                    </Col>
                    <Col xs={7} style={{borderLeft: 'solid black 1px'}} >
                        <Row>
                            <Col xs={3}>Interest Type Code</Col>
                            <Col xs={3}>Interest Type Desc</Col>
                            <Col xs={3}>CDEX Transaction Code</Col>
                            <Col xs={3}>REV Line Type</Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        );
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col xs={5} style={{marginLeft: '-13px'}} >
                        <h1>CDEX Mappings</h1>
                    </Col>
                </Row>
            </Container>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
        </div>
    );
}

export default CdexCodes;
