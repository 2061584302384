import React from 'react';
import { apiGet } from "./AuthService.js";

export const getMembership = async (accountId, getAccessTokenSilently, accountProfile, setAccountProfile) => {
    //console.log('Getting Account Membership...');

    const apiUrl = 'accounts/' + accountId + '/membership';
    //console.log(apiUrl);

    const response = await apiGet(apiUrl, getAccessTokenSilently);
    const data = await response.json()
    console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(data));

    if (response.ok) {
        const subscriptionTypeId = data.subscriptionTypeId ?? 0;
        const subscriptionStatusId = data.subscriptionStatusId ?? 0;
        const trialEndDate = data.trialEndDateString ?? '01/01/2024';
        const displayTrialModal = data.displayTrialModal ?? false;

        localStorage.setItem('displayTrialModal', displayTrialModal);
        setAccountProfile({ ...accountProfile, subscriptionTypeId: subscriptionTypeId, subscriptionStatusId: subscriptionStatusId, trialEndDate: trialEndDate, displayTrialModal: displayTrialModal });

        console.log('got account membership info');
    } else {
        setAccountProfile({ ...accountProfile, subscriptionTypeId: 1, subscriptionStatusId: 1, trialEndDate: '01/01/2024', displayTrialModal: false });
        console.log('failed to get account membership info');
    }

    return { success: response.ok };
};
