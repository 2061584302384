import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Card, Modal, Alert } from 'react-bootstrap';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import { BlockBlobClient } from "@azure/storage-blob";
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

function CdexSenderCodeModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Downloads</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Downloading...</h6>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const Download = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const navigate = useNavigate();
    const location = useLocation();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');

    const [modalShow, setModalShow] = React.useState(false);

    const [alertShow, setAlertShow] = React.useState(false);
    const [alertVariant, setAlertVariant] = React.useState('danger');
    const [alertMessage, setAlertMessage] = React.useState('Document downloaded successfully!');
    const [progressStatus, setProgressStatus] = useState(0);

    //initialize page
    useEffect(() => {
        (async () => {
            console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            const docId = location.state;
            navigate(location.pathname, { replace: true });
            console.log('DocId: ' + docId);
            if (docId == null) {
                navigate('/');
            }
            else {
                //await getDownloadInfo(docId)
                //    .then(infoResponse => {
                //        if (infoResponse.success) {
                //            const fileName = infoResponse.data.fileName;
                //            console.log('Filename: ' + fileName);

                //            //download the document
                //            downloadDocument(docId, fileName)
                //                .then(response => {
                //                    setPageStatus('ready');
                //                    setAlertMessage('Document downloaded successfully!');
                //                    setAlertShow(true);
                //                    setAlertVariant('success');
                //                })
                //                .catch(ex => {
                //                    console.log(ex.message);
                //                    //setPageStatus('error');
                //                    //setPageErrors(ex.message);
                //                    //setDataStatus('error');
                //                    //setDataErrors(ex.message);
                //                    setPageStatus('ready');

                //                    setAlertShow(true);
                //                    setAlertVariant('danger');
                //                    setAlertMessage('Download failed.');
                //                })
                //                .finally(response => {
                //                    //do something
                //                })
                //        }
                //        else {
                //            console.log('Failed:', infoResponse.statusText + ' : ' + infoResponse);
                //            //setPageStatus('error');
                //            //setPageErrors('');
                //            //setDataStatus('error');
                //            //setDataErrors('');
                //            setPageStatus('ready');

                //            setAlertShow(true);
                //            setAlertVariant('danger');
                //            setAlertMessage('Download failed.');
                //        }
                //})
                //.catch(ex => {
                //    console.log(ex.message);
                //    //setPageStatus('error');
                //    //setPageErrors(ex.message);
                //    setPageStatus('ready');

                //    setAlertShow(true);
                //    setAlertVariant('danger');
                //    setAlertMessage('Download failed.');
                //})
                //.finally(response => {
                //    setTimeout(() => {
                //        navigate('/');
                //    }, 3000);
                //})

                await handleBlobDownload(docId);
            }
        })();
    }, []);

    //get document info
    const getDocumentInfo = async (docId) => {
        //console.log('Getting Document Info...');

        const apiUrl = 'a_export/documentinfo?docId=' + docId;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    const downloadDocument = async (docId, fileName) => {
        try {
            setDataStatus('loading');

            const apiUrl = 'a_export/download?docId=' + docId + '&fileName=' + fileName;
            //console.log(apiUrl);

            await getDocument(apiUrl).then(response => {
                response.data.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();

                    setDataStatus('ready');
                })
            });
        } catch (ex) {
            console.log(ex.message);
            //setDataStatus('error');
            //setDataErrors(ex.message);
            setPageStatus('ready');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Download failed.');
        }        

        return true;
    };

    //getDocument
    const getDocument = async (apiUrl) => {
        //console.log('Getting User Profile...');

        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Data: ' + response);

        return { success: response.ok, data: response };
    }

    //*********************************************Begin - Document Download*****************************************//

    const handleBlobDownload = async (docId) => {
        setDataStatus('loading')

        try {
            //reset progress indicator
            setProgressStatus(0);

            //create blob and get sas uri
            //let downloadInfo = null;
            let fileName = null;
            let downloadUri = null;
            let linkExpired = false;
            await getDownloadInfo(docId)
                .then(response => {
                    if (response.success) {
                        downloadUri = response.data.downloadUri;
                        fileName = response.data.fileName;
                        linkExpired = response.data.linkExpired;
                        //console.log(downloadUri);

                        //continue to downloadBlob below...
                    }
                    else {
                        // eslint-disable-next-line no-throw-literal
                        throw 'Failed:' + response.statusText + ' : ' + response;
                    }
                });

            if (linkExpired === true) {
                setPageStatus('ready');
                setAlertMessage('Download link expired!');
                setAlertShow(true);
                setAlertVariant('danger');
            } else {
                //download blob
                //console.log('Download Filename: ' + fileName);
                await downloadBlob(downloadUri, fileName)
                    .then(response => {
                        if (response.success) {
                            console.log('Success:', response.success);
                            setPageStatus('ready');

                            setAlertMessage('Document downloaded successfully!');
                            setAlertShow(true);
                            setAlertVariant('success');
                        }
                        else {
                            // eslint-disable-next-line no-throw-literal
                            throw 'Failed:' + response.statusText + ' : ' + response;
                        }
                    });
            }
        }
        catch (ex) {
            console.error('Error:', ex);
            setDataErrors(ex.message);
            setDataStatus('error');
            setPageStatus('ready');

            setAlertShow(true);
            setAlertVariant('danger');
            setAlertMessage('Download failed.');
        }
        finally {
            //set the alert to close after 10 seconds
            setTimeout(() => {
                setAlertShow(false);
            }, 10000);
        }

        return { success: true };
    };

    //get the download info
    const getDownloadInfo = async (docId) => {
        //console.log('Getting download uri...');

        let apiUrl = 'a_export/downloadinfo?accountId=' + currentAccount.id + '&docId=' + docId;
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const resultString = await response.text();
        const json = JSON.parse(resultString);
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok, ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    }

    //download blob using sas
    const downloadBlob = async (sasTokenUrl, fileName) => {
        console.log('Downloading blob...');

        const blockBlobClient = new BlockBlobClient(sasTokenUrl);

        //note: streaming only works for upload in browser; will need to change to stream from Web Api later to get progress
        //const downloadResponse = await blockBlobClient.download({
        //    blockSize: 4 * 1024 * 1024,
        //    concurrency: 20,
        //    onProgress: ev => console.log('Download Progress: ' + ev)
        //});
        const downloadResponse = await blockBlobClient.download();

        const response = await downloadResponse.blobBody
            .then(blobBody => {
                // Creating new object of file
                const fileURL = window.URL.createObjectURL(blobBody);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();

                return { success: true };
            })
            .catch((ex) => {
                return { success: false, statusText: ex.message };
            });

        setDataStatus('ready');
        return response;
    };

    //*********************************************End - Document Download*******************************************//

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render component
    return (
        <div>
            <Alert variant={alertVariant} show={alertShow} onClose={() => navigate('/')} dismissible>
                {alertMessage}
            </Alert>
            <h1>Download</h1>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return <p></p>
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <CdexSenderCodeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
}

export default Download;
