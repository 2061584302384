import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { apiGet, apiPost } from "../services/AuthService.js";
import { useAuth0 } from "@auth0/auth0-react";

const iconStyle = {
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

const Unregister = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('ready');
    const [dataStatus, setDataStatus] = useState('ready');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [dataCriteria, setDataCriteria] = useState({
        email: 'dave@totdata.com',
        confirmEmail: 'dave@totdata.com',
        deleteProfile: true,
        deleteLogin: true
    });

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);
        })();
    }, []);

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle checkbox clicks
    const handleCheckboxClick = (event) => {
        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + event.target.checked);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: event.target.checked
        });
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {

        switch (buttonId) {
            case "Edit":
                console.log('editing...');
                setDataStatus('editing');
                break;
            case "Cancel":
                console.log('cancelling...');
                setDataStatus('ready');
                break;
            case "Save":
                console.log('saving...');
                //save logic here...
                await unregisterUser();
                break;
        }
    }

    //Unregister User
    const unregisterUser = async () => {
        console.log('Unregistering User...');
        setDataStatus('loading');

        await saveUnregisterUser()
            .then(response => {
                if (response.success) {
                    //setAccountProfile({ ...currentAccount, currentUser: updatedProfile });
                    console.log('Successfully linked profile');
                    setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    console.log('Failed to link profile');
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //unregister user
    const saveUnregisterUser = async () => {
        //console.log('Getting User Account...');

        //const apiUrl = baseUrl + 'accounts/unregister';
        //console.log(apiUrl);

        ////const response = await apiGet(apiUrl, getAccessTokenSilently);
        ////const json = await response.json()
        //const result = await fetch(apiUrl, {
        //    method: 'POST',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify(dataCriteria)
        //})
        //.then(response => {
        //    //console.log('Response Status: ' + response.status);
        //    //console.log('Response Ok?: ' + response.ok);
        //    if (response.ok) {
        //        console.log('true response');
        //        return true;
        //    }
        //    else {
        //        return false;
        //    }
        //})
        //.catch(ex => {
        //    throw ex;                                   //use this to throw the raw error object
        //    //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        //})

        ////console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        //return { success: result }


        const apiUrl = 'accounts/unregister';
        const jsonBody = JSON.stringify(dataCriteria);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPost(apiUrl, jsonBody, true, getAccessTokenSilently);

        return { success: response.ok };
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render unregisterUser
    const showUnregisterUser = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4 shadow" style={{border: 'none', borderRadius: '10px'}} >
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.75rem', fontWeight: '600' }} >Unregister User</div>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{ marginTop: '0px'}} >
                                    <hr />
                                </div>                                
                            </Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="email">
                                    <Form.Label column sm={3}>Email</Form.Label>
                                    <Col sm={5}>
                                        <Form.Control name="email" size="sm" aria-label="Email" value={dataCriteria.email} onChange={handleChange} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="confirmEmail">
                                    <Form.Label column sm={3}>Confirm Email</Form.Label>
                                    <Col sm={5}>
                                        <Form.Control name="confirmEmail" size="sm" aria-label="Confirm Email" value={dataCriteria.confirmEmail} onChange={handleChange} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="deleteLogin" style={{ marginTop: '30px' }} >
                                    <Col sm={5}>
                                        <Form.Check
                                            type="checkbox"
                                            name="deleteLogin"
                                            label="Delete Login"
                                            checked={dataCriteria.deleteLogin}
                                            onChange={handleCheckboxClick}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="deleteProfile" style={{marginTop: '30px'}} >
                                    <Col sm={5}>
                                        <Form.Check
                                            type="checkbox"
                                            name="deleteProfile"
                                            label="Delete Profile"
                                            checked={dataCriteria.deleteProfile}
                                            onChange={handleCheckboxClick}
                                        />
                                    </Col>
                                </Form.Group>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <Form.Group className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                size='md'
                                                style={{width: '400px'}}
                                                //disabled={dataStatus !== 'editing'}
                                                onClick={dataStatus === 'editing' ? () => handleFormButtons('Save') : () => handleFormButtons('Save')}
                                            >
                                                Unregister User
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    return (
      <div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        //return showTableContainer()
                        return showUnregisterUser()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
      </div>
    );
}

export default Unregister;
