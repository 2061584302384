import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Modal, OverlayTrigger, Popover, Alert } from 'react-bootstrap';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
//import spinnerIcon from 'src/assets/img/loadingSpinner.gif'
import comingSoon from './/./../assets/img/coming-soon.png'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
//import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut, apiPost } from "../services/AuthService.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const popoverOperatorName = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Operator Name</Popover.Header>
        <Popover.Body>
            This represents the company that is sending your statements.
        </Popover.Body>
    </Popover>
);

function AddEntityModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Add Entity</h4>
                    <h6>Enter the criteria below to add an entity to your account.</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ height: '335px' }} >
                    <Form noValidate>
                        <Form.Group as={Row} className="mb-3" controlId="region" style={{ marginTop: '30px' }}>
                            <Form.Label column sm={3}>Operator's Region</Form.Label>
                            <Col sm={4}>
                                <Form.Select name="region" size="sm" value={props.checkCriteria.region} aria-label="Operator's Region" onChange={props.handleCheckFormChange} >
                                    {props.regions.map((region, i) => (
                                        <option key={i} value={region.id}>{region.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="docType">
                            <Form.Label column sm={3}>Document Type</Form.Label>
                            <Col sm={4}>
                                <Form.Select name="docType" size="sm" value={props.checkCriteria.docType} aria-label="Document Type" onChange={props.handleCheckFormChange} >
                                    {props.documentTypes.map((docType, i) => (
                                        <option key={i} value={docType.id}>{docType.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="operatorName">
                            <Form.Label column sm={3}>Operator Name
                                <OverlayTrigger placement="right" overlay={popoverOperatorName}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: 'pointer', marginLeft: '5px', width: '17px', height: '17px' }} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control name="operatorName" size="sm" aria-label="Operator Name" value={props.checkCriteria.operatorName} onChange={props.handleCheckFormChange} isInvalid={(props.isAddEntityClicked == true ? !props.isCheckDataValid.operatorName : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter operator's name (at least first 3 letters).
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="ownerNumber">
                            <Form.Label column sm={3}>Owner Number</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="ownerNumber" size="sm" aria-label="Owner Number" value={props.checkCriteria.ownerNumber} onChange={props.handleCheckFormChange} isInvalid={(props.isAddEntityClicked == true ? !props.isCheckDataValid.ownerNumber : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter owner number.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="checkMonth">
                            <Form.Label column sm={3}>Check/ACH Month</Form.Label>
                            <Col sm={2}>
                                <Form.Select name="checkYear" size="sm" value={props.checkCriteria.checkYear} aria-label="Check Year" onChange={props.handleCheckFormChange} >
                                    {props.years.map((year, i) => (
                                        <option key={i} value={year.year.num}>{year.year.num}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={2}>
                                <Form.Select name="checkMonth" size="sm" value={props.checkCriteria.checkMonth} aria-label="Check Month" onChange={props.handleCheckFormChange} >
                                    {props.months.map((month, i) => (
                                        <option key={i} value={month.index}>{month.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="checkAmount">
                            <Form.Label column sm={3}>Check Amount</Form.Label>
                            <Col sm={4}>
                                <Form.Control name="checkAmount" size="sm" aria-label="Check Amount" maxLength={15} value={props.checkCriteria.checkAmount} onChange={props.handleCheckFormChange} isInvalid={(props.isAddEntityClicked == true ? !props.isCheckDataValid.checkAmount : false)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter check amount.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    {(() => {
                        if (props.invalidText != "") {
                            return <Row>
                                <Col xs={3} />
                                <Col xs={9} style={{ display: 'flex', justifyContent: 'left' }} >
                                    <p style={{ color: 'red' }}><b>{props.invalidText}</b></p>
                                </Col>
                            </Row>
                        }
                    })()}
                    <Row>
                        <Col xs={3} >
                            <Button style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col xs={9} style={{ display: 'flex', justifyContent: 'left' }} >
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Button
                                    size='md'
                                    style={{ width: '400px' }}
                                    disabled={props.dataStatus == 'loading' || !props.isCheckDirty}
                                    onClick={() => props.handleCheckFormButtons('Save')}
                                >
                                    Add Entity
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function AddEntityResultModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animation={false}
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: (props.addEntityStatus == 'success' ? '#d1e7dd' : '#f8d7da') }} >
                <Container>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>{(props.addEntityStatus == 'success' ? 'Add Entity Success!' : 'Add Entity Failed')}</h4>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: (props.addEntityStatus == 'success' ? '#d1e7dd' : '#f8d7da'), marginTop: '0px', paddingTop: '0px' }} >
                <Alert variant={props.alertVariant} show={props.alertShow} onClose={props.setAlertShow} dismissible={false} style={{ border: 'none' }} >
                    <h5>{props.alertMessage}</h5>
                </Alert>
                <Container>
                    <Row style={{ marginTop: '20px' }} >
                        <Col style={{ display: 'flex', justifyContent: 'center' }} >
                            <Button
                                onClick={props.onHide}
                            >
                                {(props.addEntityStatus == 'success' ? 'Go to Home Page' : 'Try Again')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const Companies = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const params = useParams();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [companies, setCompanies] = useState([]);
    const [states, setStates] = useState(['Any', 'AZ', 'CO', 'ND', 'TX']);
    const [companyTypes, setCompanyTypes] = useState([{ id: 1, type: 'Sender' }, { id: 4, type: 'Receiver' }]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [dataCriteria, setDataCriteria] = useState({
        dataFileId: params.id,
        skipCount: 0,
        takeCount: 50,
        companyCode: '',
        companyName: '',
        address: '',
        city: '',
        state: 'Any',
        zip: '',
        //companyTypeId: accountProfile.accountType !== 3 ? 1 : 4
        companyTypeId: accountProfile.accountType !== "Receiver" ? 1 : 4
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const companyTopRef = useRef(null);
    const editRef = useRef(null);

    const [addEntityModalShow, setAddEntityModalShow] = React.useState(false);
    const [isAddEntityClicked, setIsAddEntityClicked] = useState(false);
    const [numTries, setNumTries] = useState(0);
    const [invalidText, setInvalidText] = useState("");
    const [isCheckDirty, setIsCheckDirty] = useState(false);
    const [regions, setRegions] = useState([{ id: 'US', name: 'United States' }, { id: 'CA', name: 'Canada' }, { id: 'OT', name: 'Other' }]);
    const [documentTypes, setDocumentTypes] = useState([{ id: 'REV', name: 'Revenue Statements' }, { id: 'JIB', name: 'JIB' }]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [currentYear, setCurrentYear] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(0);
    const [isCheckDataValid, setIsCheckDataValid] = useState({
        region: true,
        docType: true,
        operatorName: true,
        ownerNumber: true,
        checkMonth: true,
        checkYear: true,
        checkNumber: true,
        checkAmount: true
    });
    const [checkCriteria, setCheckCriteria] = useState({
        region: 'US',
        docType: 'REV',
        operatorName: '',
        ownerNumber: '',
        checkMonth: 0,
        checkYear: 0,
        checkNumber: '',
        checkAmount: ''
    });

    const [sureModalShow, setSureModalShow] = React.useState(false);
    const [addEntityStatus, setAddEntityStatus] = useState('fail');

    const [alertShow, setAlertShow] = React.useState(true);
    const [alertVariant, setAlertVariant] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('Add Entity successful!');

    ////initialize page
    //useEffect(() => {
    //    (async () => {
    //        //console.log('Initializing page...');

    //        if (setIsShowSidebarMd)
    //            setIsShowSidebarMd(true);

    //        await populateCompanies(1, dataCriteria.companyTypeId)
    //            .then(response => {
    //                setPageStatus('ready')
    //            })
    //            .catch(ex => {
    //                setPageStatus('error');
    //                setPageErrors(ex.message);

    //                setDataStatus('error');
    //                setDataErrors(ex.message);
    //            })
    //            .finally(response => {
    //                //do something
    //            })
    //    })();
    //}, []);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await initializeForm()
                .then(response => {

                    //set years
                    setYears(response.dates.years);

                    //set months
                    response.dates.years.map((year, index) => {
                        if (year.year.num == response.dates.currYear) {
                            setMonths(year.months);
                        }
                    });

                    //set current year/month
                    setCurrentMonth(response.dates.currMonth);
                    setCurrentYear(response.dates.currYear);

                    //initialize data criteria year/month
                    setCheckCriteria({
                        ...checkCriteria,
                        checkMonth: response.dates.currMonth,
                        checkYear: response.dates.currYear
                    });

                    populateCompanies(1, dataCriteria.companyTypeId);

                    setPageStatus('ready');
                    //setAddEntityModalShow(true);
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    //setDataStatus('error');
                    //setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //populate companies
    const populateCompanies = async (currentPage, companyTypeId, reset) => {
        //console.log('Current Page: ' + currentPage);
        
        //note: have to recalcaulate skipCount here since state updates aren't active until re-render
        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        //console.log('Skip Count: ' + skipCount);

        //setDataStatus('loading');

        const currentCriteria = {
            dataFileId: dataCriteria.dataFileId,
            companyCode: (reset ? '' : dataCriteria.companyCode),
            companyName: (reset ? '' : dataCriteria.companyName),
            address: (reset ? '' : dataCriteria.address),
            city: (reset ? '' : dataCriteria.city),
            state: (reset ? 'Any' : dataCriteria.state),
            zip: (reset ? '' : dataCriteria.zip),
            companyTypeId: (reset ? 1 : companyTypeId),
            skipCount: skipCount,
            takeCount: dataCriteria.takeCount
        }
        
        await getCompanies(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    //console.log(response.data);
                    setHoveredIndex(null);
                    setSelectedIndex(null);
                    setSelectedCompany(null);
                    setIsDirty(false);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Company Count: ' + response.data[0].rowCount);
                    setIsReadOnly(currentCriteria.companyTypeId != 4);
                    setHoveredIndex(null);
                    setSelectedIndex(0);
                    setSelectedCompany(response.data[0]);
                    setIsDirty(false);

                    setRowCount(response.data[0].rowCount);
                    setCompanies(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
                //console.log('Yo CompanyType: ' + companyTypeId);
                //console.log('Yo ReadOnly: ' + isReadOnly);
            })
    };

    //get companies
    const getCompanies = async (criteria) => {
        //console.log('Getting Companies...');

        const apiUrl = 'accounts/' + currentAccount.id + '/companies?skipcount=' + criteria.skipCount + '&takecount=' + criteria.takeCount
            + (criteria.companyCode === "" ? '' : '&companyCode=' + criteria.companyCode)
            + (criteria.companyName === "" ? '' : '&companyName=' + criteria.companyName)
            + (criteria.address === "" ? '' : '&address=' + criteria.address)
            + (criteria.city === "" ? '' : '&city=' + criteria.city)
            + (criteria.state === "Any" ? '' : '&state=' + criteria.state)
            + (criteria.zip === "" ? '' : '&zip=' + criteria.zip)
            + (criteria.companyTypeId === "" ? '' : '&companyTypeId=' + criteria.companyTypeId);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //handle form control changes
    const handleSearchChange = async (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle form type control change
    const handleSearchTypeChange = async (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value,
            skipCount: 0
        });

        setCurrentPage(1);

        await populateCompanies(1, value);
    }

    //handle edit control changes
    const handleEditChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);        

        //create modified company
        const modifiedCompany = {
            ...selectedCompany,
            [fieldName]: value
        };

        //set isDirty flag
        companies.map((company, index) => {
            if (index === selectedIndex) {
                if (checkIsDirty(company, modifiedCompany)) {
                    setIsDirty(true);
                }
                else {
                    setIsDirty(false);
                }
            }
        });        

        setSelectedCompany(modifiedCompany);
    }

    function checkIsDirty(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return true;
            }
        }

        return false;
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {
        
        switch (buttonId) {
            case "Edit":
                console.log('editing...');
                setDataStatus('editing');
                break;
            case "Cancel":
                console.log('cancelling...');                

                setSelectedCompany(companies[selectedIndex]);
                setIsDirty(false);

                setDataStatus('ready');

                //companyTopRef.current.scrollIntoView();
                //editRef.current.scrollIntoView();                
                break;
            case "SaveReceiver":
                console.log('saving receiver...');
                setIsDirty(false);
                await handleManagedReceiverSave();

                //companyTopRef.current.scrollIntoView();
                //editRef.current.scrollIntoView();
                break;
            case "SaveSender":
                console.log('saving sender...');
                setIsDirty(false);
                await handleManagedSenderSave();

                //companyTopRef.current.scrollIntoView();
                //editRef.current.scrollIntoView();
                break;
        }
    }

    //update company
    const handleManagedReceiverSave = async () => {
        console.log('Saving managed receiver...');
        //setDataStatus('loading');

        console.log('Id: ' + selectedCompany.id + ', Company Name: ' + selectedCompany.companyName);

        await updateManagedReceiver(selectedCompany)
            .then(response => {
                if (response.success) {
                    setCompanies(companies.map((company, index) => {
                        if (index === selectedIndex) {
                            return selectedCompany;
                        }
                        else {
                            return company;
                        }
                    }));

                    if (selectedCompany.isPrimary) {
                        setAccountProfile({ ...accountProfile, accountName: selectedCompany.companyName });
                    }

                    console.log('ready');
                    //setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save company
    const updateManagedReceiver = async (selectedCompany) => {
        //console.log('Saving Company...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updatemanagedreceiver/' + selectedCompany.id;
        const jsonBody = JSON.stringify(selectedCompany);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    //handle managed sender save
    const handleManagedSenderSave = async () => {
        console.log('Saving managed sender...');
        //setDataStatus('loading');

        console.log('Id: ' + selectedCompany.id + ', Company Name: ' + selectedCompany.companyName);

        await updateManagedSender(selectedCompany)
            .then(response => {
                if (response.success) {
                    setCompanies(companies.map((company, index) => {
                        if (index === selectedIndex) {
                            return selectedCompany;
                        }
                        else {
                            return company;
                        }
                    }));

                    if (selectedCompany.isPrimary) {
                        setAccountProfile({ ...accountProfile, accountName: selectedCompany.companyName });
                    }

                    console.log('ready');
                    //setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //update managed sender
    const updateManagedSender = async (selectedCompany) => {
        //console.log('Saving Company...');

        const apiUrl = 'accounts/' + currentAccount.id + '/updatemanagedsender/' + selectedCompany.id;
        const jsonBody = JSON.stringify(selectedCompany);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    //submit form
    const submitForm = async (e) => {
        //e.preventDefault();
        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        setCurrentPage(1);

        await populateCompanies(1, dataCriteria.companyTypeId);
    };

    //handle reset button
    const handleResetButton = async () => {

        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            companyCode: '',
            companyName: '',
            address: '',
            city: '',
            state: 'Any',
            zip: '',
            companyTypeId: 1,
            skipCount: 0
        });

        setCurrentPage(1);

        await populateCompanies(1, dataCriteria.companyTypeId, true);
    };

    //handle mouse over
    const handleMouseOver = async (index) => {
        console.log('Mouse Over: ' + index);

        if (index != selectedIndex) {
            setHoveredIndex(index);
        }
    };

    //handle mouse leave
    const handleMouseLeave = async (index) => {
        console.log('Mouse Leave: ' + index);

        if (index != selectedIndex) {
            setHoveredIndex(null);
        }
    };

    //handle mouse click
    const handleMouseClick = async (index) => {
        console.log('Mouse Click: ' + index);

        setHoveredIndex(null);
        setSelectedIndex(index);
        setSelectedCompany(companies[index]);
        setIsDirty(false);
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        //console.log('Current Page: ' + currentPage);

        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        //don't need to do here, since pagination component sets before calling this method
        //setCurrentPage(1);

        await populateCompanies(currentPage, dataCriteria.companyTypeId);
    };


    /*******************************Add Entity - BEGIN**********************************************/

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOwners() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        const dates = await getDates();

        return { success: true, dates: dates };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = () => {

        const date = new Date();
        const currMonthNum = date.getMonth() + 1;
        //const currMonthNum = 1;
        const currYearNum = date.getFullYear();
        //const currYearNum = 2023;
        const showMonths = 3;

        const allMonths = [{ index: 1, name: 'Jan' }, { index: 2, name: 'Feb' }, { index: 3, name: 'Mar' }, { index: 4, name: 'Apr' },
        { index: 5, name: 'May' }, { index: 6, name: 'Jun' }, { index: 7, name: 'Jul' }, { index: 8, name: 'Aug' },
        { index: 9, name: 'Sep' }, { index: 10, name: 'Oct' }, { index: 11, name: 'Nov' }, { index: 12, name: 'Dec' }];

        //previous year
        const years = [];
        if (currMonthNum < showMonths) {
            const prevYearMonths = allMonths.slice(12 - (showMonths - currMonthNum));
            const prevYear = { year: { index: 0, num: currYearNum - 1 }, months: prevYearMonths };
            years.push(prevYear);
        }

        //current year
        const currYearMonths = [];
        allMonths.map((month, i) => {
            if (i < currMonthNum && i >= (currMonthNum - showMonths))
                currYearMonths.push(month);
        });
        const currYear = { year: { index: 1, num: currYearNum }, months: currYearMonths };
        years.push(currYear);

        //years.map((year, i) => {
        //    console.log('Year: ' + year.year.num);

        //    year.months.map((month, i) => {
        //        console.log('Month: ' + month.index + ' : ' + month.name);
        //    }); 
        //}); 

        return { years: years, currMonth: currMonthNum, currYear: currYearNum };
    };

    //handle form button clicks
    const handleCheckFormButtons = async (buttonId) => {

        switch (buttonId) {
            case "Cancel":
                console.log('cancelling...');
                break;
            case "Save":
                console.log('saving...');
                await addEntity();
                setIsAddEntityClicked(true);
                //window.location.replace("https://totdata.com");                
                break;
            default:
                //do nothing
        }
    }

    //handle form control changes
    const handleCheckFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setInvalidText("");

        const newCheckCriteria = {
            ...checkCriteria,
            [fieldName]: value
        };

        //handling specific fields
        switch (fieldName) {
            case 'operatorName':
                //default operator help criteria operator name(s) to match registraton criteria
                //setHelpCriteria({
                //    ...helpCriteria,
                //    operatorNames: value
                //});
                break;
            case 'checkYear':
                //if setting year, set the selected year index so we can update the month dropdown
                years.map((year, index) => {
                    if (year.year.num == value) {
                        setMonths(year.months);
                        const monthNum = (year.year.num == currentYear ? currentMonth : year.months[0].index);
                        newCheckCriteria.checkMonth = monthNum;
                    }
                });
                break;
            case 'checkAmount':
                let positionIncrement = 0;

                //strip dollar sign and commas
                let newValue = value.replace("$", "");
                newValue = newValue.replaceAll(",", "");

                //add zero in front of lone decimal
                if (newValue == ".") {
                    newValue = "0.";
                    positionIncrement = 1;
                }

                //verify it matches a general number pattern
                var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);
                const matchFound = newValue.search(validNumber);
                //if (matchFound == -1) {
                //    return;
                //}
                if (matchFound == -1) {
                    //set to old value so we can figure out cursor position below
                    newValue = checkCriteria.checkAmount;

                    //decrement to nullify move back one position since an invalid character was input
                    positionIncrement = -1;
                } else {
                    //convert to numeric value and convert to locale string (ie. add thousand separator); also format string with dollar sign and suffix
                    if (newValue != "") {
                        const numericValue = parseFloat(newValue);

                        //note: parseFloat removes "." and ".0", so add back if in original string
                        const newValueSuffix = (value.endsWith(".") ? "." : (value.endsWith(".0") ? ".0" : ""));
                        newValue = "$" + numericValue.toLocaleString("en-US") + newValueSuffix;
                    }
                }

                //do some gymnastics to figure out cursor position (ie. adjust if comma was added or removed)
                const oldValueComma = checkCriteria.checkAmount.replaceAll(",", "#comma");
                const oldCommaCount = (oldValueComma.match(/#comma/g) || []).length;
                const newValueComma = newValue.replaceAll(",", "#comma");
                const newCommaCount = (newValueComma.match(/#comma/g) || []).length;
                positionIncrement = positionIncrement + (newCommaCount - oldCommaCount);

                if (checkCriteria.checkAmount == "") {
                    positionIncrement = positionIncrement + 1;
                }

                //set the cursor position within the input
                const inputBox = event.target;
                const startPos = inputBox.selectionStart + positionIncrement;
                const endPos = inputBox.selectionEnd + positionIncrement;

                setTimeout(() => {
                    inputBox.setSelectionRange(startPos, endPos);
                }, 10);

                //set the new value in state
                newCheckCriteria.checkAmount = newValue;
                break;

            default:
                break;
        }

        //set criteria and isDirty status
        setCheckCriteria(newCheckCriteria);
        checkIsCheckDataDirty(newCheckCriteria);
        checkIsCheckDataValid(newCheckCriteria);
    }

    //get user account
    const checkIsCheckDataDirty = async (newCheckCriteria) => {

        const isCheckDirty = newCheckCriteria.region.length > 0
            && newCheckCriteria.docType.length > 0
            && newCheckCriteria.operatorName.length > 2
            && newCheckCriteria.ownerNumber.length > 0
            && newCheckCriteria.checkMonth > 0
            && newCheckCriteria.checkYear > 0
            && newCheckCriteria.checkAmount.length > 1;

        setIsCheckDirty(isCheckDirty);
    }

    //get user account
    const checkIsCheckDataValid = async (newCheckCriteria) => {
        const isOperatorNameValid = newCheckCriteria.operatorName.length >= 3;
        const isOwnerNumberValid = newCheckCriteria.ownerNumber.length >= 1;

        //**********Begin - Check Amount**********//
        let newValue = newCheckCriteria.checkAmount.replace("$", "");
        newValue = newValue.replaceAll(",", "");

        var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);
        const matchFound = newValue.search(validNumber);
        let isCheckAmountValid = true;
        if (newValue == "" || matchFound == -1) {
            isCheckAmountValid = false;
        }
        //***********End - Check Amount***********//

        setIsCheckDataValid({
            ...isCheckDataValid,
            operatorName: isOperatorNameValid,
            ownerNumber: isOwnerNumberValid,
            checkAmount: isCheckAmountValid
        });
    }

    //add entity
    const addEntity = async () => {
        console.log('Adding entity...');
        setDataStatus('loading');

        setInvalidText("");

        const newCheckCriteria = {
            ...checkCriteria,
            checkAmount: checkCriteria.checkAmount.replace("$", "").replace(",", "")
        };

        setIsCheckDirty(false);

        await saveAddEntity(newCheckCriteria)
            .then(response => {
                if (response.success) {
                    setAddEntityStatus('success');
                    setAddEntityModalShow(false);
                    setSureModalShow(true);
                    setAlertVariant('success');
                    setAlertMessage('Add Entity succeeded.');
                    setAlertShow(true);

                    console.log('Successfully linked profile');
                    setDataStatus('ready');
                }
                else {
                    switch (response.resultText) {
                        case 'BadCheck':
                            setInvalidText("Invalid check information provided.  Please correct and try again.");
                            break;
                        case 'BadOperator':
                            setIsCheckDataValid({
                                ...isCheckDataValid,
                                operatorName: false
                            });
                            setInvalidText("Invalid Operator name provided.  Please correct and try again.");
                            break;
                        default:
                            setInvalidText("Oops, something went wrong.  Please try again.");
                            break;
                    }

                    const newNumTries = numTries + 1;
                    setNumTries(newNumTries);
                    //if (newNumTries == 3) {
                    if (newNumTries == 3) {
                        setAddEntityStatus('fail');
                        setAddEntityModalShow(false);
                        setSureModalShow(true);
                        setAlertVariant('danger');
                        setAlertMessage('Add Entity failed using the information provided.  Please try again, or feel free to contact us at support@totdata.com.');
                        setAlertShow(true);
                    }

                    console.log('Failed to link profile');
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do nothing
            })
    };

    //save add entity
    const saveAddEntity = async (newCriteria) => {
        //console.log('Getting User Account...');

        const apiUrl = 'accounts/' + currentAccount.id + '/addentity';
        console.log(apiUrl);
        console.log(JSON.stringify(newCriteria));

        //const result = await fetch(apiUrl, {
        //    method: 'POST',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify(newCriteria)
        //})
        //.then(response => {
        //    if (response.ok) {
        //        console.log('true response');
        //        return true;
        //    }
        //    else {
        //        return false;
        //    }
        //})
        //.catch(ex => {
        //    throw ex;                                   //use this to throw the raw error object
        //    //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        //})

        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));
        //return { success: result }

        //const response = await fetch(apiUrl, {
        //    method: 'POST',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify(newCriteria)
        //});

        const jsonBody = JSON.stringify(newCriteria);
        const response = await apiPost(apiUrl, jsonBody, true, getAccessTokenSilently);

        //const addEntityResult = await response.text()
        //    .then(function (text) {
        //        if (text == "")
        //            return "blah";
        //        else if (text == "")
        //            return "yo";
        //        else
        //            return text;
        //});
        const addEntityResult = await response.text();

        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));
        return { success: response.ok, resultText: addEntityResult }
    };

    //handle complete add entity
    const handleCompleteAddEntity = () => {
        //window.location.href = "https://totdata.com";
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page loading
    const showComingSoon = () => {
        return (
            <Row>
                <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}><img src={comingSoon} alt="Coming Soon!" style={{ width: '75%', height: 'auto' }} /></Col>
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{paddingLeft: '12px', paddingRight: '10px'}} >
                    {(() => {
                        switch (dataStatus) {
                            case 'nodata':
                                return <p>No Data Loaded.</p>
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <Row>No Data Loaded.</Row>
                        }
                    })()}
                </Card.Body>
                <Card.Footer>
                    <Container>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <PaginationComponent
                                    itemsCount={rowCount}
                                    itemsPerPage={dataCriteria.takeCount}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageClickHandler={handlePageClick}
                                    alwaysShown={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render companies
    const showData = () => {
        return (
            <Row>
                <Col xs={6} style={{ marginTop: '-16px', marginBottom: '-16px', overflow: 'auto', paddingRight: '0px', paddingLeft: '0px', borderRight: 'solid lightgray 1px' }} >
                {(() => {
                    switch (dataStatus) {
                        case 'ready':
                            return showDataMaster()
                        default:
                            return null
                    }
                    })()}
                </Col>
                <Col xs={6} style={{ height: '530px', overflow: 'auto', paddingLeft: '20px', marginTop: '-10px', marginBottom: '-16px' }}>
                    {(() => {
                        switch (dataStatus) {
                            case 'ready':
                                return showDataDetail()
                            default:
                                return null
                        }
                    })()}
                </Col>
            </Row>
        );
    }

    //render master pane (companies list)
    const showDataMaster = () => {
        return (
            <>                
                <ul style={{ listStyleType: "none", height: "515px", marginRight: '12px' }}>
                    {companies.map((company, index) => (
                        <li key={company.id} ref={(index == 0 ? companyTopRef : null)}
                            style={{ cursor: 'pointer', paddingLeft: '20px', marginLeft: '-42px', marginRight: '0px', fontSize: '.9rem' }}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            onClick={() => handleMouseClick(index)}>
                            <Row style={{
                                borderBottom: 'solid lightgray 1px',
                                backgroundColor: (hoveredIndex == index ? '#9AC5F4' : (selectedIndex == index ? '#4F709C' : 'white')),
                                color: (hoveredIndex == index ? 'white' : (selectedIndex == index ? 'white' : 'black'))
                            }} >       
                                <Col xs={2} >
                                    <div>{company.companyCode}</div>
                                </Col>
                                <Col xs={5} >
                                    <div>{company.companyName}</div>
                                    <div>{(company.additionalNameInfo ?? '' != '') && "(" + company.additionalNameInfo + ")"}</div>
                                </Col>
                                <Col xs={5} >
                                    <div>{company.address1}</div>
                                    <div>{company.city}</div>
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    //render detail pane (company display or edit)
    const showDataDetail = () => {
        return (
            <>
                {(() => {
                    if (isReadOnly) {
                        return showDataDisplay()
                    }
                    else {
                        return showDataEdit()
                    }
                })()}
            </>
        );
    }

    //render company detail
    const showDataDisplay = () => {
        return (
            <>
                <Row>
                    <Col xs={7}>
                        <div style={{ fontWeight: '600', fontSize: '1.25rem', marginTop: '10px' }} >Sender Detail</div>
                    </Col>
                    <Col xs={5} className="d-flex justify-content-end" style={{marginTop: '13px'}} >
                        Company Number:&nbsp;&nbsp;{selectedCompany.companyCode}&nbsp;&nbsp;&nbsp;&nbsp;
                    </Col>
                </Row>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }} />
                <Row style={{ paddingRight: '10px', paddingTop: '10px' }}>
                    <Col xs={2}>
                        Name:
                    </Col>
                    <Col xs={10}>
                        <div>
                            {selectedCompany.companyName}
                        </div>                        
                        <div>
                            {((selectedCompany.additionalNameInfo ?? '') == '' ? null : '(' + selectedCompany.additionalNameInfo + ')')}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Address:
                    </Col>
                    <Col xs={10}>
                        <div>
                            {selectedCompany.address1} {selectedCompany.address2} {selectedCompany.address3}
                        </div>
                        <div>
                            {selectedCompany.city}, {selectedCompany.state} {selectedCompany.zip}
                        </div>
                    </Col>
                </Row>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }}>
                    <Row style={{ marginTop: '10px' }}>
                    <Col xs="4">
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" size="sm" aria-label="Email" value={selectedCompany.email ?? ''} onChange={handleEditChange} />
                        </Form.Group>
                    </Col>
                    <Col xs="4">
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control name="phone" size="sm" aria-label="Phone" value={selectedCompany.phone ?? ''} onChange={handleEditChange} />
                        </Form.Group>
                    </Col>
                    <Col xs="4">
                        <Form.Group className="mb-3" controlId="fax">
                            <Form.Label>Fax</Form.Label>
                            <Form.Control name="fax" size="sm" aria-label="Fax" value={selectedCompany.fax ?? ''} onChange={handleEditChange} />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    {/*<Col xs="3">*/}
                    {/*    <Form.Group className="mb-3" controlId="taxRegNumber">*/}
                    {/*        <Form.Label>Tax Reg #</Form.Label>*/}
                    {/*        <Form.Control name="taxRegNumber" size="sm" aria-label="Tax Reg Number" value={selectedCompany.taxRegNumber ?? ''} onChange={handleEditChange} />*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}
                </Row>
                <Row style={{ marginTop: '-10px' }} >
                    <Col className="d-flex justify-content-end">
                        <Form.Group className="mb-3" controlId="buttonClear">
                            <Form.Label as={Row}><br /></Form.Label>
                            <Button id="editProfile" variant="primary"
                                size='sm'
                                style={{ width: '100px' }}
                                disabled={!isDirty}
                                onClick={isDirty ? () => handleFormButtons('Cancel') : null}
                            >
                                Cancel
                            </Button>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="buttonSubmit">
                            <Form.Label as={Row}><br /></Form.Label>
                            <Button
                                size='sm'
                                style={{ marginLeft: '20px', width: '100px' }}
                                disabled={!isDirty}
                                onClick={isDirty ? () => handleFormButtons('SaveSender') : null}
                            >
                                Save
                            </Button>
                        </Form.Group>
                    </Col>
                    </Row>
                    </Form>
            </>
        );
    }

    //render company edit form
    const showDataEdit = () => {
        return (
            <>                
                <Row ref={editRef} style={{ paddingRight: '10px' }} >
                    {(() => {
                        if (selectedCompany.companyTypeId === 1) {
                            return <Col>
                                <div style={{ fontWeight: '600', marginTop: '10px' }} >Company Number: {selectedCompany.companyCode}</div>
                            </Col>
                        }
                        else {
                            return <>
                                <Col xs={6}>
                                    <div style={{ fontWeight: '600', fontSize: '1.25rem', marginTop: '10px' }} >Receiver Detail</div>
                                </Col>
                                <Col xs={3}>
                                    <div style={{ marginTop: '13px' }} >Company Number:</div>
                                </Col>
                                <Col xs={3}>
                                    <div style={{ marginTop: '10px' }}>
                                        <Form.Control name="companyCode" size="sm" aria-label="Receiver CDEX Code" value={selectedCompany.companyCode ?? ''} onChange={handleEditChange} />
                                    </div>
                                </Col>
                            </>
                        }
                    })()}
                </Row>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }} >
                    <Row style={{ marginTop: '10px' }} >
                        <Col xs="6">
                            <Form.Group className="mb-3" controlId="companyName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control name="companyName" size="sm" aria-label="Company Name" value={selectedCompany.companyName ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="6">
                            <Form.Group className="mb-3" controlId="additionalNameInfo">
                                <Form.Label>Additional Name</Form.Label>
                                <Form.Control name="additionalNameInfo" size="sm" aria-label="Additional Name" value={selectedCompany.additionalNameInfo ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Group className="mb-3" controlId="address1">
                                <Form.Label>Address 1</Form.Label>
                                <Form.Control name="address1" size="sm" aria-label="Address 1" value={selectedCompany.address1 ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <Form.Group className="mb-3" controlId="address2">
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control name="address2" size="sm" aria-label="Address 2" value={selectedCompany.address2 ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="6">
                            <Form.Group className="mb-3" controlId="address3">
                                <Form.Label>Address 3</Form.Label>
                                <Form.Control name="address3" size="sm" aria-label="Address 3" value={selectedCompany.address3 ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control name="city" size="sm" aria-label="City" value={selectedCompany.city ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Form.Group className="mb-3" controlId="state">
                                <Form.Label>State</Form.Label>
                                <Form.Control name="state" size="sm" aria-label="State" value={selectedCompany.state ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label>Country</Form.Label>
                                <Form.Control name="country" size="sm" aria-label="Country" value={selectedCompany.country ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Form.Group className="mb-3" controlId="zip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control name="zip" size="sm" aria-label="Zip" value={selectedCompany.zip ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" size="sm" aria-label="Email" value={selectedCompany.email ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control name="phone" size="sm" aria-label="Phone" value={selectedCompany.phone ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Form.Group className="mb-3" controlId="fax">
                                <Form.Label>Fax</Form.Label>
                                <Form.Control name="fax" size="sm" aria-label="Fax" value={selectedCompany.fax ?? ''} onChange={handleEditChange} />
                            </Form.Group>
                        </Col>
                        {/*<Col xs="2">*/}
                        {/*    <Form.Group className="mb-3" controlId="taxRegNumber">*/}
                        {/*        <Form.Label>Tax Reg #</Form.Label>*/}
                        {/*        <Form.Control name="taxRegNumber" size="sm" aria-label="Tax Reg Number" value={selectedCompany.taxRegNumber ?? ''} onChange={handleEditChange} />*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row style={{ marginTop: '-10px' }} >
                        <Col className="d-flex justify-content-end">
                            <Form.Group className="mb-3" controlId="buttonClear">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button id="editProfile" variant="primary"
                                    size='sm'
                                    style={{ width: '100px' }}
                                    disabled={!isDirty}
                                    onClick={isDirty ? () => handleFormButtons('Cancel') : null}
                                >
                                    Cancel
                                </Button>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    size='sm'
                                    style={{ marginLeft: '20px', width: '100px' }}
                                    disabled={!isDirty}
                                    onClick={isDirty ? () => handleFormButtons('SaveReceiver') : null}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }

    //pagination example
    //https://github.com/lukaaspl/ellipsis-pagination/blob/master/src/components/Pagination.js

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container>
                <Form>
                    <Row>
                        {(() => {
                            if (currentAccount.accountTypeId !== 3) {
                                return <Col xs="auto">
                                    <Form.Group className="mb-3" controlId="companyTypeId">
                                        <Form.Label>Company Type</Form.Label>
                                        <Form.Select name="companyTypeId" size="sm" aria-label="Type" value={dataCriteria.companyTypeId} style={{ width: '120px' }} onChange={handleSearchTypeChange} >
                                            {companyTypes.map((companyType) => (
                                                <option key={companyType.id} value={companyType.id}>{companyType.type}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            }
                        })()}
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="companyCode">
                                <Form.Label>Company Number</Form.Label>
                                <Form.Control name="companyCode" size="sm" aria-label="Company Code" value={dataCriteria.companyCode} style={{ width: '100' }} onChange={handleSearchChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="companyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control name="companyName" size="sm" aria-label="Company Name" value={dataCriteria.companyName} style={{ width: '240' }} onChange={handleSearchChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="buttonReset">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='button'
                                    size='sm'
                                    style={{ width: '70px' }}
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? handleResetButton : null}
                                >
                                    Reset
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    size='sm'
                                    style={{ width: '70px' }}
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>                        
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    hidden={dataCriteria.companyTypeId !== '40' && dataCriteria.companyTypeId !== 40}
                                    size='sm'
                                    style={{ width: '100px', marginLeft: '40px' }}
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? () => setAddEntityModalShow(true) : null}
                                >
                                    Add Entity
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col xs={4} style={{marginLeft: '-13px'}} >
                        <h1>Company Profiles</h1>
                    </Col>
                </Row>
            </Container>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}

            <AddEntityModal
                show={addEntityModalShow}
                onHide={() => setAddEntityModalShow(false)}
                checkCriteria={checkCriteria}
                handleCheckFormButtons={handleCheckFormButtons}
                isAddEntityClicked={isAddEntityClicked}
                isCheckDataValid={isCheckDataValid}
                months={months}
                years={years}
                handleCheckFormChange={handleCheckFormChange}
                documentTypes={documentTypes}
                regions={regions}
                isCheckDirty={isCheckDirty}
                invalidText={invalidText}
                dataStatus={dataStatus}
                numTries={numTries}
            />

            <AddEntityResultModal
                show={sureModalShow}
                //onHide={handleCompleteAddEntity}
                onHide={() => setSureModalShow(false)}
                invalidText={invalidText}
                alertShow={alertShow}
                addEntityStatus={addEntityStatus}
                setAlertShow={setAlertShow}
                alertVariant={alertVariant}
                alertMessage={alertMessage}
            />
        </div>
    );
}

export default Companies;
