import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate, useOutletContext } from "react-router-dom";
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'

const LoginOLD = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    
    //stateful properties (hooks)
    const navigate = useNavigate();
    const [currentAccount, setAccountProfile] = useOutletContext();

    const [pageStatus, setPageStatus] = useState('ready');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [dataCriteria, setDataCriteria] = useState({
        email: "",
        password: ""
    });

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            //get user account from storage
            const currentAccount = localStorage.getItem("currentAccount");

            if (!currentAccount) {
                // Initialize page views count
                setPageStatus('ready')
                setDataStatus('nodata')
            } else {
                //console.log('Current Account: ' + currentAccount.currentUser.email);
                console.log('Going home');
                navigate('/');
            }
        })();
    }, []);

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //submit form
    const submitForm = async (e) => {
        e.preventDefault();

        setPageStatus('loading');
        await populateCurrentAccount();        
    };

    //populate current account
    const populateCurrentAccount = async () => {
        //console.log('Populating Current Account...);

        await getCurrentAccount()
            .then(response => {
                if (response.success) {
                    //get user account
                    const currentAccount = response.data;
                    console.log('Email: ' + currentAccount.currentUser.email);

                    //get current user
                    const currentUser = currentAccount.currentUser;
                    console.log('Current User: ' + currentUser.email + (currentUser.firstName != null ? ' - ' + currentUser.firstName : ''));

                    //update state variable
                    setAccountProfile(currentAccount);

                    //redirect to home page
                    //setPageStatus('ready')
                    //navigate('/');
                }
                else {
                    console.log('Login Failed.');
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                setPageStatus('ready')
            })
    };

    //get user account
    const getCurrentAccount = async () => {
        //console.log('Getting User Account...');

        let apiUrl = baseUrl + 'accounts/login' + '?email=' + dataCriteria.email + '&password=' + dataCriteria.password;
        console.log(apiUrl);

        const response = await fetch(apiUrl);
        const json = await response.json();

        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + json);
        return { success: response.ok, data: json }
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Container>
                <Row>
                    <Col xs={7}>
                        <Card className="mb-4 shadow" style={{ border: 'none', borderRadius: '10px' }}>
                            <Card.Header style={{ backgroundColor: 'lightgray', border: 'none', borderRadius: '10px 10px 0px 0px' }}>
                                <h3>Sign in to Your Account</h3>
                            </Card.Header>
                            <Card.Body>
                                {showInputCriteria()}
                                <div><br /></div>
                                {(() => {
                                    switch (dataStatus) {
                                        //case 'nodata':
                                            //return <p>No Data Loaded.</p>
                                        case 'loading':
                                            return showDataLoading()
                                        case 'ready':
                                            return showData()
                                        case 'error':
                                            return showDataError()
                                        default:
                                            //return <Row>No Data Loaded.</Row>
                                    }
                                })()}
                            </Card.Body>                
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    //render check statements
    const showData = () => {
        return (
            <Container>
                <Row>
                    <div><p>{(currentAccount ? 'Welcome, ' + (currentAccount.currentUser.firstName != null ? currentAccount.currentUser.firstName : currentAccount.currentUser.email) : 'Please log in.')}</p></div>
                </Row>
            </Container>
        );
    }

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="email">
                    <Form.Label column sm={2}>Email</Form.Label>
                    <Col sm={5}>
                        <Form.Control name="email" size="sm" aria-label="Email" value={dataCriteria.email} onChange={handleChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="password">
                    <Form.Label column sm={2}>Password</Form.Label>
                    <Col sm={5}>
                        <Form.Control type="password" name="password" size="sm" aria-label="Password" value={dataCriteria.password} onChange={handleChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="buttonSubmit">
                    <Form.Label column sm={2}></Form.Label>
                    <Col sm={4}>
                        <Button
                            type='submit'
                            size='sm'
                            style={{ width: '280px' }}
                            disabled={pageStatus !== 'ready'}
                            onClick={pageStatus === 'ready' ? submitForm : null}
                        >
                            Sign In
                        </Button>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="buttonSubmit">
                    <Form.Label column sm={2}></Form.Label>
                    <Col sm={4}>
                        <Button
                            size='sm'
                            style={{width: '280px'}}
                            disabled={pageStatus !== 'ready'}
                            onClick={pageStatus === 'ready' ? () => navigate('/registeraccount') : null}
                        >
                            Create an Account
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        );
    }

    return (
        <div className="body flex-grow-1 px-3">
            <Container fluid="lg">
                <Row style={{ height: "100px" }} ></Row>
                {(() => {
                    switch (pageStatus) {
                        case 'loading':
                            return showPageLoading()
                        case 'ready':
                            return showTableContainer()
                        case 'error':
                            return showPageError()
                        default:
                            return <p>No Page Loaded.</p>
                    }
                })()}
            </Container>
      </div>
    );
}

export default LoginOLD;
