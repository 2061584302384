import React, { useEffect } from "react";
import spinnerIcon from 'src/assets/img/loadingSpinner.gif'

const SpinnerComponent = () => {
    //const pagesCount = Math.ceil(itemsCount / itemsPerPage);
    //useEffect(setLastPageAsCurrent, [pagesCount]);

    return (
        <img src={process.env.REACT_APP_API_BASEURL.includes("localhost") ? spinnerIcon : `/${spinnerIcon}`} alt="loading spinner..." />
    );
};

export default SpinnerComponent;