import { useOutletContext, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faGear, faContactBook, faXmark, faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from "@auth0/auth0-react";

import Logo from './../assets/brand/pumpjack2.png';
import Cables from './../assets/brand/cables.png';
const imgStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '20px'
};

const imgPublicStyle = {
    width: '100%',
    height: 'auto',
    padding: '0px',
    margin: '0px',
    marginBottom: '0px',
    objectFit: 'fill'
};

const Welcome = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();

    const [pageStatus, setPageStatus] = useState('ready');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');
            if (setIsShowSidebarMd) {
                setIsShowSidebarMd(false);
            }

        })();
    }, []);

    //render profile overview info
    const cardNavigate = (path) => {

        if (path == '/') {
            toggleIsShowSidebarMd();
        }
        else {
            navigate(path);
        }
    }

    //render cards
    const showCards = () => {
        return <Container>
            {(() => {
                if (currentAccount.accountTypeId === 1 || currentAccount.accountTypeId === 2 || currentAccount.accountTypeId === 4) {
                    return <>
                        <Row>
                            <p className="display-6" style={{ fontSize: '1.65rem', fontWeight: '700' }} >Operated</p>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col xs={4}>
                                {showImport()}
                            </Col>
                            <Col xs={4}>
                                {showDataFiles()}
                            </Col>
                            <Col xs={4}>
                                {showOwnerMapping()}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return null
                }
            })()}

            {(() => {
                if (currentAccount.accountTypeId === 1 || currentAccount.accountTypeId === 2 || currentAccount.accountTypeId === 4) {
                    return <>
                        <Row>
                            <p className="display-6" style={{fontSize: '1.65rem', fontWeight: '700'}} >Non-Operated</p>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                {showCheckStatements()}
                            </Col>
                            <Col xs={4}>
                                {showMyOperators()}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return <>
                        <Row>
                            <Col xs={1}></Col>
                            <Col xs={5}>
                                {showCheckStatements()}
                            </Col>
                            <Col xs={5}>
                                {showMyOperators()}
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </>
                }
            })()}            

            {(() => {
                if (currentAccount.accountTypeId === 1 || currentAccount.accountTypeId === 2 || currentAccount.accountTypeId === 4) {
                    return <>
                        <Row style={{ marginTop: '50px' }} >
                            <Col xs={3}></Col>
                            <Col xs={6}>
                                {showTakeHome()}
                            </Col>
                            <Col xs={3}></Col>
                        </Row>
                    </>
                }
                else {
                    return <>
                        <Row style={{ marginTop: '50px' }} >
                            <Col xs={3}></Col>
                            <Col xs={6}>
                                {showTakeHome()}
                            </Col>
                            <Col xs={3}></Col>
                        </Row>
                    </>
                }
            })()}
        </Container>
    }

    //render profile overview info
    const showImport = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/import')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Import Revenue Files</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{backgroundColor: '#465359'}} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render primary company info
    const showDataFiles = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/datafiles')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage Check Statements</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render settings info
    const showOwnerMapping = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/mappedowners')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage my 'Owners'</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#969FA7' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showCheckStatements = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/checkfiles')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Access my Check Statements</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#465359' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showMyOperators = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/sendingoperators')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage my Operators</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showTakeHome = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Just take me to the Main Menu</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#969FA7' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    return (
        <>
            {(() => {
                if (isLoading) {
                    return showPageLoading();
                }
                else {
                    if (!currentAccount) {
                        return <>
                            <div className="body flex-grow-1">
                                <Row style={{ height: '14px', marginTop: '40px', width: '100%' }} >
                                    <Col style={{ backgroundColor: '#465359', marginLeft: '50px' }} ></Col>
                                    <Col style={{ backgroundColor: '#1CADE4', marginLeft: '15px', marginRight: '15px' }}></Col>
                                    <Col style={{ backgroundColor: '#969FA7', marginRight: '50px' }}></Col>
                                </Row>
                                <div style={{ marginTop: '80px', marginBottom: '26px', paddingLeft: '50px' }} >
                                    <h1 className="display-4" style={{ fontWeight: '700' }}>THE OPERATOR'S PLATFORM</h1>
                                </div>
                                <div>
                                    <img
                                        src={Cables}
                                        alt="totdata.com"
                                        style={imgPublicStyle}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    else {
                        return <>
                            <br /><br />
                            <p className="display-6" style={{ fontWeight: '500' }} >Welcome, {(currentAccount == null ? accountProfile.nickname : (currentAccount.currentUser.firstName != null ? currentAccount.currentUser.firstName : currentAccount.currentUser.email))}!</p>
                            <div className="body flex-grow-1 px-3">
                                <Container fluid="lg">
                                    <Row style={{ height: "25px" }} ></Row>
                                    <Row style={{ marginBottom: '30px' }} >
                                        <p className="display-6">What would you like to do today?</p>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }} >
                                        {showCards()}
                                    </Row>
                                </Container>
                            </div>
                        </>
                    }
                }
            })()}
            <div>
                {!isAuthenticated && (
                    <p>Not Authenticated</p>
                )}
                {isAuthenticated && (
                    <p>Is Authenticated</p>
                )}
            </div>
        </>
    );
}

export default Welcome;
