import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

const iconStyle = {
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

const Profile = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [profile, setProfile] = useState([]);
    const [dataCriteria, setDataCriteria] = useState({
        dataFileId: 1,
        skipCount: 0,
        takeCount: 10,
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        email: '',
        phone: '',
        fax: '',
        auth0Id: ''
    });

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            //console.log('Current Email: ' + accountProfile.email);
            //if (accountProfile.email != "") {
                await populateProfile()
                    .then(response => {
                        //console.log('User Profile: ' + response.data.email + ' - ' +  response.data.firstName);
                        if (response.data === undefined) {
                            console.log('no profile');
                            setDataStatus('nodata')
                            setDataErrors('No profile loaded.');
                            setPageStatus('error')
                            setPageErrors('No profile loaded.');
                        }
                        else {
                            console.log('User Profile: ' + response.data.email + ' - ' + response.data.firstName);
                            const userProfile = response.data;
                            setProfile(userProfile);
                            setDataCriteria({
                                ...dataCriteria,
                                firstName: userProfile.firstName ?? '',
                                lastName: userProfile.lastName ?? '',
                                address1: userProfile.address1 ?? '',
                                address2: userProfile.address2 ?? '',
                                address3: userProfile.address3 ?? '',
                                city: userProfile.city ?? '',
                                state: userProfile.state ?? '',
                                country: userProfile.country ?? '',
                                zip: userProfile.zip ?? '',
                                email: userProfile.email ?? '',
                                phone: userProfile.phone ?? '',
                                fax: userProfile.fax ?? '',
                                auth0Id: userProfile.auth0Id ?? ''
                            });
                            setDataStatus('ready')
                            setPageStatus('ready')
                        }
                    })
                    .catch(ex => {
                        setPageStatus('error');
                        setPageErrors(ex.message);

                        setDataStatus('error');
                        setDataErrors(ex.message);
                    })
                    .finally(response => {
                        //do something
                    });
            //}
        })();
    }, []);

    //populate user profile
    const populateProfile = async () => {
        //console.log('Getting User Profile...');

        //let json = await fetch(apiUrl)
        //    .then(response => response.json())
        //    .catch(ex => {
        //        throw ex;                                   //use this to throw the raw error object
        //        //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        //    })

        const apiUrl = 'userprofiles/current';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {

        switch (buttonId) {
            case "Edit":
                console.log('editing...');
                setDataStatus('editing');
                break;
            case "Cancel":
                console.log('cancelling...');
                setDataStatus('ready');
                break;
            case "Save":
                console.log('saving...');
                //save logic here...
                await updateProfile();
                break;
        }
    }

    //update profile
    const updateProfile = async () => {
        console.log('Saving profile...');
        setDataStatus('loading');

        const updatedProfile = { id: profile.id, ...dataCriteria };
        console.log('Id: ' + updatedProfile.id + ', First Name: ' + updatedProfile.firstName + ', Last Name: ' + updatedProfile.lastName);

        await saveProfile(updatedProfile)
            .then(response => {
                if (response.success) {
                    setAccountProfile({ ...accountProfile, firstName: updatedProfile.firstName, lastName: updatedProfile.lastName });
                    setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save profile
    const saveProfile = async (updatedProfile) => {
        //console.log('Saving Profile...');

        const apiUrl = 'userprofiles';
        const jsonBody = JSON.stringify(updatedProfile);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render profile info
    const showProfile = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Profile Info</div>
                                </Col>
                                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                                    <FontAwesomeIcon icon={faPencil} style={{ cursor: 'pointer', padding: '5px', width: '20px', height: '20px' }} onClick={() => handleFormButtons('Edit')} />
                                </Col>
                            </Row>
                            <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                <div style={{ fontSize: '.75rem' }} >Name</div>
                                <div style={{ color: ((dataCriteria.firstName ?? '') == '' ? 'red' : 'black') }} >{((dataCriteria.firstName ?? '') == '' ? 'Update first name' : dataCriteria.firstName)}</div>
                                <div style={{ color: ((dataCriteria.lastName ?? '') == '' ? 'red' : 'black') }} >{((dataCriteria.lastName ?? '') == '' ? 'Update last name' : dataCriteria.lastName)}</div>
                            </div>
                            <hr />
                            <div style={{marginTop: '20px', marginBottom: '20px'}} >
                                <div style={{ fontSize: '.75rem' }} >Address</div>
                                <div>{dataCriteria.address1}</div>
                                <div>{dataCriteria.address2}</div>
                                <div>{dataCriteria.address3}</div>
                                <div>{dataCriteria.city}, {dataCriteria.state} {dataCriteria.zip}</div>
                            </div>
                            <hr />
                            <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                <div style={{ fontSize: '.75rem' }} >Email</div>
                                <div style={{ color: ((dataCriteria.email ?? '') == '' ? 'red' : 'black') }} >{((dataCriteria.email ?? '') == '' ? 'Add email address' : dataCriteria.email)}</div>
                            </div>
                            <hr />
                            <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                <div style={{ fontSize: '.75rem' }} >Phone</div>
                                <div style={{ color: ((dataCriteria.phone ?? '') == '' ? 'red' : 'black') }} >{((dataCriteria.phone ?? '') == '' ? 'Update phone number' : dataCriteria.phone)}</div>
                            </div>
                            <hr />
                            <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                <Button id="editProfile" variant="primary" size="sm" style={{ width: "70px" }}
                                    disabled={dataStatus !== 'ready'}
                                    onClick={dataStatus === 'ready' ? () => handleFormButtons('Edit') : null}
                                >
                                    Edit
                                </Button>
                            </div>                            
                        </Card.Body>
                    </Card>
                </Col>
                {/*<Col xs={5}>*/}
                {/*    {showDefaultCompany()}*/}
                {/*</Col>*/}
            </Row>
        </Container>
    }

    //render edit profile
    const editProfile = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4 shadow" style={{border: 'none', borderRadius: '10px'}} >
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.75rem', fontWeight: '600' }} >Edit Profile</div>
                                </Col>
                                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                                    <FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer', padding: '5px', width: '20px', height: '20px' }} onClick={() => handleFormButtons('Cancel')} />
                                </Col>
                            </Row>
                            <Row>
                                <div style={{ marginTop: '0px'}} >
                                    <hr />
                                    <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Name</div>
                                </div>                                
                            </Row>
                            <Form>
                                <Row style={{marginTop: '10px'}}>
                                    <Col xs="6">
                                        <Form.Group className="mb-3" controlId="firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control name="firstName" size="sm" aria-label="Profile Name" value={dataCriteria.firstName} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="6">
                                        <Form.Group className="mb-3" controlId="lastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control name="lastName" size="sm" aria-label="Additional Name" value={dataCriteria.lastName} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <div>
                                        <hr />
                                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Address</div>
                                    </div>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col xs="12">
                                        <Form.Group className="mb-3" controlId="address1">
                                            <Form.Label>Address 1</Form.Label>
                                            <Form.Control name="address1" size="sm" aria-label="Address 1" value={dataCriteria.address1} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <Form.Group className="mb-3" controlId="address2">
                                            <Form.Label>Address 2</Form.Label>
                                            <Form.Control name="address2" size="sm" aria-label="Address 2" value={dataCriteria.address2} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="6">
                                        <Form.Group className="mb-3" controlId="address3">
                                            <Form.Label>Address 3</Form.Label>
                                            <Form.Control name="address3" size="sm" aria-label="Address 3" value={dataCriteria.address3} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <Form.Group className="mb-3" controlId="city">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control name="city" size="sm" aria-label="City" value={dataCriteria.city} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="2">
                                        <Form.Group className="mb-3" controlId="state">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control name="state" size="sm" aria-label="State" value={dataCriteria.state} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="2">
                                        <Form.Group className="mb-3" controlId="country">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control name="country" size="sm" aria-label="Country" value={dataCriteria.country} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="2">
                                        <Form.Group className="mb-3" controlId="zip">
                                            <Form.Label>Zip</Form.Label>
                                            <Form.Control name="zip" size="sm" aria-label="Zip" value={dataCriteria.zip} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{ marginTop: '0px' }} >
                                        <hr />
                                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Email</div>
                                    </div>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col xs="12">
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control name="email" size="sm" aria-label="Email" value={dataCriteria.email} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{ marginTop: '0px' }} >
                                        <hr />
                                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Phone/Fax</div>
                                    </div>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col xs="6">
                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control name="phone" size="sm" aria-label="Phone" value={dataCriteria.phone} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="6">
                                        <Form.Group className="mb-3" controlId="fax">
                                            <Form.Label>Fax</Form.Label>
                                            <Form.Control name="fax" size="sm" aria-label="Fax" value={dataCriteria.fax} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <Form.Group className="mb-3" controlId="buttonClear">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button id="editProfile" variant="primary" size="sm" style={{ width: "70px" }}
                                                disabled={dataStatus !== 'editing'}
                                                onClick={dataStatus === 'editing' ? () => handleFormButtons('Cancel') : null}
                                            >
                                                Cancel
                                            </Button>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                size='sm'
                                                style={{marginLeft: '20px'}}
                                                disabled={dataStatus !== 'editing'}
                                                onClick={dataStatus === 'editing' ? () => handleFormButtons('Save') : null}
                                            >
                                                Save
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    return (
      <div>
            <h1>{((accountProfile.firstName ?? '') == '' || (accountProfile.lastName ?? '') == '') ? accountProfile.email : accountProfile.firstName + ' ' + accountProfile.lastName}</h1>
            <br />

            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return (dataStatus === 'ready' ? showProfile() : (dataStatus === 'editing' ? editProfile() : null))
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
      </div>
    );
}

export default Profile;
