import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useLocation } from "react-router-dom";

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();

    const handleLogin = async () => {
        console.log(location.pathname);
        await loginWithRedirect({
            appState: {
                returnTo: location.pathname,
            },
        });
        //await loginWithRedirect();
    };

    return (
        <button className="button__login" onClick={handleLogin}>
            Log In
        </button>
    );
};

export default LoginButton;