import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Tab, Tabs } from 'react-bootstrap';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import comingSoon from './/./../assets/img/coming-soon.png'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet, apiPut } from "../services/AuthService.js";

const MappedOwners = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();

    //stateful properties (hooks)
    const params = useParams();
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [operators, setOperators] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [states, setStates] = useState(['', 'AZ', 'CO', 'ND', 'TX']);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [dataCriteria, setDataCriteria] = useState({
        dataFileId: params.id,
        skipCount: 0,
        takeCount: 50,
        operatorId: 0,
        ownerCode: '',
        companyName: '',
        address: '',
        city: '',
        state: '',
        zip: ''
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const companyTopRef = useRef(null);
    const editRef = useRef(null);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await initializeForm()
                .then(operatorResponse => {
                    //const operators = [
                    //    { id: 0, companyName: '' },
                    //    ...operatorResponse.data                        
                    //]
                    //setOperators(operators);

                    populateCompanies(1, dataCriteria.operatorId)
                        .then(response => {
                            setPageStatus('ready')
                        })
                        .catch(ex => {
                            setPageStatus('error');
                            setPageErrors(ex.message);

                            setDataStatus('error');
                            setDataErrors(ex.message);
                        })
                        .finally(response => {
                            //do something
                        })
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOperators() from useEffect
        //console.log('Initializing form...');

        //get operators for dropdown
        //let operators = await getOperators();

        return { success: true, data: operators.data };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    //get operators
    const getOperators = async () => {
        //console.log('Getting Operators...');

        console.log('AccountId: ' + currentAccount.id);

        const apiUrl = 'accounts/' + currentAccount.id + '/managedsenders';
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //populate companies
    const populateCompanies = async (currentPage, operatorId, reset) => {
        //console.log('Current Page: ' + currentPage);
        
        //note: have to recalcaulate skipCount here since state updates aren't active until re-render
        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        //console.log('Skip Count: ' + skipCount);

        //setDataStatus('loading');

        const currentCriteria = {
            dataFileId: dataCriteria.dataFileId,
            operatorId: (reset ? 0 : operatorId),
            ownerCode: (reset ? '' : dataCriteria.ownerCode),
            companyName: (reset ? '' : dataCriteria.companyName),
            address: (reset ? '' : dataCriteria.address),
            city: (reset ? '' : dataCriteria.city),
            state: (reset ? '' : dataCriteria.state),
            zip: (reset ? '' : dataCriteria.zip),
            skipCount: skipCount,
            takeCount: dataCriteria.takeCount
        }
        
        await getCompanies(currentCriteria)
            .then(response => {
                if (response.data.length === 0) {
                    //console.log(response.data);
                    setHoveredIndex(null);
                    setSelectedIndex(null);
                    setSelectedCompany(null);
                    setIsDirty(false);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Company Count: ' + response.data[0].rowCount);
                    setIsReadOnly(true);
                    setHoveredIndex(null);
                    setSelectedIndex(0);
                    setSelectedCompany(response.data[0]);
                    setIsDirty(false);

                    setRowCount(response.data[0].rowCount);
                    setCompanies(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //get companies
    const getCompanies = async (criteria) => {
        //console.log('Getting Companies...');

        const apiUrl = 'accounts/' + currentAccount.id + '/externalreceivers?skipcount=' + criteria.skipCount + '&takecount=' + criteria.takeCount
            + (criteria.operatorId === 0 ? '' : '&senderId=' + criteria.operatorId)
            + (criteria.ownerCode === "" ? '' : '&receiverCode=' + criteria.ownerCode)
            + (criteria.companyName === "" ? '' : '&companyName=' + criteria.companyName)
            + (criteria.address === "" ? '' : '&address=' + criteria.address)
            + (criteria.city === "" ? '' : '&city=' + criteria.city)
            + (criteria.state === "" ? '' : '&state=' + criteria.state)
            + (criteria.zip === "" ? '' : '&zip=' + criteria.zip);
        //console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json };
    };

    //handle form control changes
    const handleSearchChange = async (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //handle form operator control change
    const handleSearchOperatorChange = async (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value,
            skipCount: 0
        });

        setCurrentPage(1);

        await populateCompanies(1, value);
    }

    //handle edit control changes
    const handleEditChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);        

        //create modified company
        const modifiedCompany = {
            ...selectedCompany,
            [fieldName]: value
        };

        //set isDirty flag
        companies.map((company, index) => {
            if (index === selectedIndex) {
                if (checkIsDirty(company, modifiedCompany)) {
                    setIsDirty(true);
                }
                else {
                    setIsDirty(false);
                }
            }
        });        

        setSelectedCompany(modifiedCompany);
    }

    function checkIsDirty(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return true;
            }
        }

        return false;
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {
        
        switch (buttonId) {
            case "UnmapOwner":
                console.log('unmapping...');
                setIsDirty(false);
                await unmapOwner();

                //companyTopRef.current.scrollIntoView();
                //editRef.current.scrollIntoView();
                break;
        }
    }

    //unmap owner
    const unmapOwner = async () => {
        console.log('Unmapping Owner...');
        //setDataStatus('loading');

        console.log('Id: ' + selectedCompany.id + ', Company Name: ' + selectedCompany.companyName);

        await saveOwner(selectedCompany)
            .then(response => {
                if (response.success) {
                    //repoplulate list
                    submitForm(null);
                    console.log('ready');
                    //note: data status is set to 'ready' in submitForm()
                    //setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    console.log('nodata');
                    //setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //save owner
    const saveOwner = async (selectedCompany) => {
        //console.log('Saving Company...');

        const apiUrl = 'accounts/' + currentAccount.id + '/unmapexternalreceiver/' + selectedCompany.id;
        const jsonBody = JSON.stringify(selectedCompany);
        //console.log(apiUrl);
        //console.log(jsonBody);

        const response = await apiPut(apiUrl, jsonBody, getAccessTokenSilently);

        return { success: response.ok };
    };

    //submit form
    const submitForm = async (e) => {
        //e.preventDefault();
        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        setCurrentPage(1);

        await populateCompanies(1, dataCriteria.operatorId);
    };

    //handle reset button
    const handleResetButton = async () => {

        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            operatorId: 0,
            ownerCode: '',
            companyName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            skipCount: 0
        });

        setCurrentPage(1);

        await populateCompanies(1, dataCriteria.operatorId, true);
    };

    //handle mouse over
    const handleMouseOver = async (index) => {
        console.log('Mouse Over: ' + index);

        if (index != selectedIndex) {
            setHoveredIndex(index);
        }
    };

    //handle mouse leave
    const handleMouseLeave = async (index) => {
        console.log('Mouse Leave: ' + index);

        if (index != selectedIndex) {
            setHoveredIndex(null);
        }
    };

    //handle mouse click
    const handleMouseClick = async (index) => {
        console.log('Mouse Click: ' + index);

        setHoveredIndex(null);
        setSelectedIndex(index);
        setSelectedCompany(companies[index]);
        setIsDirty(false);
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        //console.log('Current Page: ' + currentPage);

        //setDataStatus('loading');

        setDataCriteria({
            ...dataCriteria,
            skipCount: 0
        });

        //don't need to do here, since pagination component sets before calling this method
        //setCurrentPage(1);

        await populateCompanies(currentPage, dataCriteria.operatorId);
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page loading
    const showComingSoon = () => {
        return (
            <Row>
                <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}><img src={comingSoon} alt="Coming Soon!" style={{ width: '75%', height: 'auto' }} /></Col>
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body style={{paddingLeft: '12px', paddingRight: '10px'}} >
                    {(() => {
                        switch (dataStatus) {
                            case 'nodata':
                                return <p>No Data Loaded.</p>
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <Row>No Data Loaded.</Row>
                        }
                    })()}
                </Card.Body>
                <Card.Footer>
                    <Container>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <PaginationComponent
                                    itemsCount={rowCount}
                                    itemsPerPage={dataCriteria.takeCount}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageClickHandler={handlePageClick}
                                    alwaysShown={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Footer>
            </Card>
        );
    }

    //render companies
    const showData = () => {
        return (
            <Row>
                <Col xs={6} style={{ marginTop: '-16px', marginBottom: '-16px', overflow: 'auto', paddingRight: '0px', paddingLeft: '0px', borderRight: 'solid lightgray 1px' }} >
                {(() => {
                    switch (dataStatus) {
                        case 'ready':
                            return showDataMaster()
                        default:
                            return null
                    }
                    })()}
                </Col>
                <Col xs={6} style={{ height: '530px', overflow: 'auto', paddingLeft: '20px', marginTop: '-10px', marginBottom: '-16px' }}>
                    {(() => {
                        switch (dataStatus) {
                            case 'ready':
                                return showDataDetail()
                            default:
                                return null
                        }
                    })()}
                </Col>
            </Row>
        );
    }

    //render master pane (companies list)
    const showDataMaster = () => {
        return (
            <>                
                <ul style={{ listStyleType: "none", height: "515px", marginRight: '12px' }}>
                    {companies.map((company, index) => (
                        <li key={company.id} ref={(index == 0 ? companyTopRef : null)}
                            style={{ cursor: 'pointer', paddingLeft: '20px', marginLeft: '-42px', marginRight: '0px', fontSize: '.9rem' }}
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            onClick={() => handleMouseClick(index)}>
                            <Row style={{
                                borderBottom: 'solid lightgray 1px',
                                backgroundColor: (hoveredIndex == index ? '#9AC5F4' : (selectedIndex == index ? '#4F709C' : 'white')),
                                color: (hoveredIndex == index ? 'white' : (selectedIndex == index ? 'white' : 'black'))
                            }} >       
                                <Col xs={2} >
                                    <div>{company.receiverCode}</div>
                                </Col>
                                <Col xs={5} >
                                    <div>{company.companyName}</div>
                                    <div>{(company.additionalNameInfo ?? '' != '') && "(" + company.additionalNameInfo + ")"}</div>
                                </Col>
                                <Col xs={5} >
                                    <div>{company.address1}</div>
                                    <div>{company.city}</div>
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    //render detail pane (company display or edit)
    const showDataDetail = () => {
        return (
            <>
                {(() => {
                    if (isReadOnly) {
                        //return showDataDisplay()
                        return showDataEdit()
                    }
                    else {
                        //return showDataEdit()
                        return showDataDisplay()
                    }
                })()}
            </>
        );
    }

    //render company detail
    const showDataDisplay = () => {
        return (
            <>
                <Row>
                    <Col xs={8}>
                        <div style={{ fontWeight: '600', fontSize: '1.25rem', marginTop: '10px' }} >Sender Detail</div>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end" style={{marginTop: '13px'}} >
                        Company Code:&nbsp;&nbsp;{selectedCompany.companyCode}&nbsp;&nbsp;&nbsp;&nbsp;
                    </Col>
                </Row>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }} />
                <Row style={{ paddingRight: '10px', paddingTop: '10px' }}>
                    <Col xs={2}>
                        Name:
                    </Col>
                    <Col xs={10}>
                        <div>
                            {selectedCompany.companyName}
                        </div>                        
                        <div>
                            {((selectedCompany.additionalNameInfo ?? '') == '' ? null : '(' + selectedCompany.additionalNameInfo + ')')}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Address:
                    </Col>
                    <Col xs={10}>
                        <div>
                            {selectedCompany.address1} {selectedCompany.address2} {selectedCompany.address3}
                        </div>
                        <div>
                            {selectedCompany.city}, {selectedCompany.state} {selectedCompany.zip}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Email:
                    </Col>
                    <Col xs={10}>
                        <div style={{ color: ((selectedCompany.email ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.email ?? '') == '' ? 'Not available' : selectedCompany.email}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Phone:
                    </Col>
                    <Col xs={10}>
                        <div style={{ color: ((selectedCompany.phone ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.phone ?? '') == '' ? 'Not available' : selectedCompany.phone}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        Fax:
                    </Col>
                    <Col xs={10}>
                        <div style={{ color: ((selectedCompany.fax ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.fax ?? '') == '' ? 'Not available' : selectedCompany.fax}</div>
                    </Col>                    
                </Row>
                //<Row>
                //    <Col xs={2}>
                //        Tax Reg #:
                //    </Col>
                //    <Col xs={10}>
                //        <div style={{ color: ((selectedCompany.taxRegNumber ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.taxRegNumber ?? '') == '' ? 'Not available' : selectedCompany.taxRegNumber}</div>
                //    </Col>
                //</Row>
                <Row>
                    <Col xs={3}>
                        CDEX Sender Code:
                    </Col>
                    <Col xs={9}>
                        <div style={{ color: ((selectedCompany.cdexSenderCode ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.cdexSenderCode ?? '') == '' ? 'Not available' : selectedCompany.cdexSenderCode}</div>
                    </Col>
                </Row>
            </>
        );
    }

    //render company edit form
    const showDataEdit = () => {
        return (
            <>
                <Row ref={editRef} style={{ paddingRight: '10px' }} >
                    <Col xs={8}>
                        <div style={{ fontWeight: '600', fontSize: '1.25rem', marginTop: '10px' }} >Owner Detail/Mapping</div>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end" style={{ marginTop: '13px' }} >
                        Owner Code:&nbsp;&nbsp;{selectedCompany.receiverCode}&nbsp;&nbsp;&nbsp;&nbsp;
                    </Col>
                </Row>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }} >
                    <Row style={{ paddingRight: '10px', paddingTop: '10px' }}>
                        <Col xs={3}>
                            <div style={{fontWeight: '600'}} >Owner:</div>
                        </Col>
                        <Col xs={2}>
                            Name:
                        </Col>
                        <Col xs={7}>
                            <div>
                                {selectedCompany.companyName}
                            </div>
                            <div>
                                {((selectedCompany.additionalNameInfo ?? '') == '' ? null : '(' + selectedCompany.additionalNameInfo + ')')}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Address:
                        </Col>
                        <Col xs={7}>
                            <div>
                                {selectedCompany.address1} {selectedCompany.address2} {selectedCompany.address3}
                            </div>
                            <div>
                                {selectedCompany.city}, {selectedCompany.state} {selectedCompany.zip}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Email:
                        </Col>
                        <Col xs={7}>
                            <div style={{ color: ((selectedCompany.email ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.email ?? '') == '' ? 'Not available' : selectedCompany.email}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Phone:
                        </Col>
                        <Col xs={7}>
                            <div style={{ color: ((selectedCompany.phone ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.phone ?? '') == '' ? 'Not available' : selectedCompany.phone}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Fax:
                        </Col>
                        <Col xs={7}>
                            <div style={{ color: ((selectedCompany.fax ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.fax ?? '') == '' ? 'Not available' : selectedCompany.fax}</div>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col xs={3}>*/}
                    {/*        &nbsp;*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={2}>*/}
                    {/*        Tax Reg #:*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={7}>*/}
                    {/*        <div style={{ color: ((selectedCompany.taxRegNumber ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.taxRegNumber ?? '') == '' ? 'Not available' : selectedCompany.taxRegNumber}</div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Form>
                <Form style={{ borderTop: 'solid lightgray 1px', marginTop: '10px', paddingRight: '10px' }} >
                    <Row style={{ paddingRight: '10px', paddingTop: '10px' }}>
                        <Col xs={3}>
                            <div style={{ fontWeight: '600' }} >Mapped To:</div>
                        </Col>
                        <Col xs={2}>
                            Name:
                        </Col>
                        <Col xs={7}>
                            <div>
                                {selectedCompany.receiver.companyName}
                            </div>
                            <div>
                                {((selectedCompany.receiver.additionalNameInfo ?? '') == '' ? null : '(' + selectedCompany.receiver.additionalNameInfo + ')')}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Address:
                        </Col>
                        <Col xs={7}>
                            <div>
                                {selectedCompany.receiver.address1} {selectedCompany.receiver.address2} {selectedCompany.receiver.address3}
                            </div>
                            <div>
                                {selectedCompany.receiver.city}, {selectedCompany.receiver.state} {selectedCompany.receiver.zip}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Email:
                        </Col>
                        <Col xs={7}>
                            <div style={{ color: ((selectedCompany.receiver.email ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.receiver.email ?? '') == '' ? 'Not available' : selectedCompany.receiver.email}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Phone:
                        </Col>
                        <Col xs={7}>
                            <div style={{ color: ((selectedCompany.receiver.phone ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.receiver.phone ?? '') == '' ? 'Not available' : selectedCompany.receiver.phone}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            &nbsp;
                        </Col>
                        <Col xs={2}>
                            Fax:
                        </Col>
                        <Col xs={7}>
                            <div style={{ color: ((selectedCompany.receiver.fax ?? '') == '' ? 'red' : 'black') }}>{(selectedCompany.receiver.fax ?? '') == '' ? 'Not available' : selectedCompany.receiver.fax}</div>
                        </Col>
                    </Row>
                    {(() => {
                        if (accountProfile.hasImpersonate) {
                            return <>
                                <Row style={{ marginTop: '-10px' }} >
                                    <Col className="d-flex justify-content-end">
                                        <Form.Group className="mb-3" controlId="buttonUnmap">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                size='sm'
                                                style={{ marginLeft: '20px', width: '100px' }}
                                                disabled={isDirty}
                                                onClick={!isDirty ? () => handleFormButtons('UnmapOwner') : null}
                                            >
                                                Unmap
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        }
                    })()}                    
                </Form>
            </>
        );
    }

    //pagination example
    //https://github.com/lukaaspl/ellipsis-pagination/blob/master/src/components/Pagination.js

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerCode">
                                <Form.Label>Owner Code</Form.Label>
                                <Form.Control name="ownerCode" size="sm" aria-label="Owner Code" value={dataCriteria.ownerCode} style={{ width: '240' }} onChange={handleSearchChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="companyName">
                                <Form.Label>Owner Name</Form.Label>
                                <Form.Control name="companyName" size="sm" aria-label="Owner Name" value={dataCriteria.companyName} style={{ width: '240' }} onChange={handleSearchChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="buttonReset">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='button'
                                    size='sm'
                                    style={{ width: '70px' }}
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? handleResetButton : null}
                                >
                                    Reset
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    size='sm'
                                    style={{ width: '70px' }}
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col xs={4} style={{marginLeft: '-13px'}} >
                        <h1>Owner Mapping</h1>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'right', marginTop: '15px' }} >
                        <div>
                            <Button id="uploadEmails" variant="link"
                                size='md'
                                onClick={() => navigate('/uploademails')}
                            >
                                Load Email Addresses
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div><br /></div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showTableContainer()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
        </div>
    );
}

export default MappedOwners;
