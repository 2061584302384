import { Nav, Button } from 'react-bootstrap'
import LoginButton from "../../../components/buttons/login-button.js";
import LogoutButton from "../../../components/buttons/logout-button.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";

const LoginRegistrationNav = () => {

    const baseUrl = process.env.REACT_APP_API_BASEURL
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogin = async () => {
        console.log(location.pathname);
        await loginWithRedirect({
            appState: {
                //returnTo: window.location.origin,
                //returnTo: location.pathname,
                returnTo: '/'
            },
        });
    };

    const handleLocalSignIn = async () => {
        navigate('/login');
    };

    return (
        <Nav>
            {/*<LoginButton />*/}
            {/*<LogoutButton />*/}

            <Nav.Item>
                <Nav.Link className="px-3 py-2 d-flex align-items-center">
                    <span className="nav-icon ms-n3" />
                    {/*<Button variant="primary" size="lg" style={{ border: 'solid #0c6ff9 2px', borderRadius: '15px' }} onClick={() => handleLogin()} >Sign In</Button>*/}
                    {/*<Button disabled variant="primary" size="md" style={{ border: 'solid white 1px', borderRadius: '6px', backgroundColor: '#141416', height: '45px', font: 'Open Sans', fontSize: '14px', paddingLeft: '12px', paddingRight: '12px' }} onClick={() => handleLogin()} >Sign In</Button>*/}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className="px-3 py-2 d-flex align-items-center">
                    <span className="nav-icon ms-n3" />
                    {/*<Button variant="primary" size="lg" style={{ border: 'solid #0c6ff9 2px', borderRadius: '15px' }} onClick={() => navigate('/register')} >Register</Button>*/}
                    {/*<Button disabled variant="primary" size="md" style={{ border: 'solid white 1px', borderRadius: '6px', backgroundColor: '#141416', height: '45px', font: 'Open Sans', fontSize: '14px', paddingLeft: '12px', paddingRight: '12px' }} onClick={() => navigate('/register')} >Register</Button>*/}
                </Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default LoginRegistrationNav;
