import { useOutletContext, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import SpinnerComponent from '../components/progressbar/SpinnerComponent.js'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faGear, faContactBook, faXmark, faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from "@auth0/auth0-react";
//import RequestInfoDialog from '../components/request-info/RequestInfo'
import styles from '../styles/Home.module.css'

//import Cables from './../assets/brand/cables.png';
import Cables from './../assets/brand/pumpjack.png';
import Account from "./Account";
const imgStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '20px'
};
const imgPublicStyle = {
    width: '100%',
    height: 'auto',
    padding: '0px',
    margin: '0px',
    marginBottom: '0px',
    marginTop: '-24px',
    objectFit: 'fill',
    opacity: '0.75'
};

const Home = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();

    const [pageStatus, setPageStatus] = useState('ready');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');

    const { loginWithRedirect } = useAuth0();
    const location = useLocation();
    const [queryParameters] = useSearchParams();

    const handleLogin = async () => {
        console.log(location.pathname);
        await loginWithRedirect({
            appState: {
                //returnTo: window.location.origin,
                //returnTo: location.pathname,
                returnTo: '/'
            },
        });
    };

    //initialize page
    useEffect(() => {
        (async () => {

            if (isAuthenticated) {
                let sessionNonce = null;
                let docId = null;

                //get sessionData from local storage
                const sessionData = localStorage.getItem('sessionData');
                console.log('Session Data: ' + sessionData);
                if (sessionData) {
                    const sessionDataObject = JSON.parse(sessionData);

                    //check sessionData for docId
                    sessionNonce = sessionDataObject.nonce;
                    docId = sessionDataObject.docId;
                    console.log('Session Data Objects - Nonce: ' + sessionNonce + ', DocId: ' + docId);

                    //reset sessionData items in local storage (note: may use for items that we don't want to discard after one use later)
                    const resetSessionData = { nonce: null, docId: null };
                    const resetSessionDataString = JSON.stringify(resetSessionData);
                    localStorage.setItem('sessionData', resetSessionDataString);
                }

                //validate nonce if logging in (ie. exists in local storage)
                var authNonce = location.state;
                if (sessionNonce !== null) {
                    console.log('Post-Auth Nonce: ' + authNonce);

                    if (authNonce != sessionNonce) {
                        //there's a problem; possibly a CSFR, so handle
                        console.log('Warning: Nonce Invalid');
                    }
                    else {
                        console.log('Nonce Validated');
                    }
                }

                if (docId == null) {
                    //check queryParams for docId
                    docId = queryParameters.get("docid");
                    console.log('DocId from Query Param: ' + docId);
                }

                if (docId != null) {
                    //redirect to download page
                    navigate('/download', { state: docId });
                }

                const checkoutSessionId = queryParameters.get("session_id");
                if (checkoutSessionId != null) {
                    navigate('/membership', { state: { checkoutSessionId: checkoutSessionId } });
                    //alert('CheckoutSessionId: ' + checkoutSessionId);
                }
            }
            else {
                //if registering, short-circuit and navigate to register page without authenticating
                const isRegister = queryParameters.get("register");
                if (isRegister !== null) {
                    const numMonthsParam = queryParameters.get("months");

                    let numMonths = 3;
                    if (parseInt(numMonthsParam)) {
                        numMonths = parseInt(numMonthsParam);
                    }

                    //navigate('/register');
                    navigate('/register', { state: numMonths});
                }
                else {
                    //create a one-time state identifier (nonce)
                    const nonce = uuidv4();

                    //if downloading document, save the docId in local storage for retrieval on authentication callback
                    let docId = queryParameters.get("docid");
                    //docId = 'my docId'

                    //save sessionData in local storage
                    const sessionData = { nonce: nonce, docId: docId };
                    const sessionDataString = JSON.stringify(sessionData);
                    localStorage.setItem('sessionData', sessionDataString);

                    //navigate to signin page (pass nonce in state)
                    navigate('/signin', { state: nonce });
                }
            }

            //console.log('Initializing page...');
            if (setIsShowSidebarMd) {
                setIsShowSidebarMd(false);
            }

        })();
    }, []);

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }

    //render profile overview info
    const cardNavigate = (path) => {

        if (path == '/') {
            toggleIsShowSidebarMd();
        }
        else {
            navigate(path);
        }
    }

    //render cards
    const showCards = () => {
        return <Container>
            {(() => {
                if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                    return <>
                        <Row>
                            <p className="display-6" style={{ fontSize: '1.65rem', fontWeight: '700' }} >Operated</p>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col xs={4}>
                                {showImport()}
                            </Col>
                            <Col xs={4}>
                                {showDataFiles()}
                            </Col>
                            <Col xs={4}>
                                {showOwnerMapping()}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return null
                }
            })()}

            {(() => {
                if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                    return <>
                        <Row>
                            <p className="display-6" style={{fontSize: '1.65rem', fontWeight: '700'}} >Non-Operated</p>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                {showCheckStatements()}
                            </Col>
                            <Col xs={4}>
                                {showMyOperators()}
                            </Col>
                            <Col xs={4}>
                                {showDocumentConverter()}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return <>
                        <Row>
                            <Col xs={4}>
                                {showCheckStatements()}
                            </Col>
                            <Col xs={4}>
                                {showMyOperators()}
                            </Col>
                            <Col xs={4}>
                                {showDocumentConverter()}
                            </Col>
                        </Row>
                    </>
                }
            })()}            

            {(() => {
                if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                    return <>
                        <Row style={{ marginTop: '50px' }} >
                            <Col xs={3}></Col>
                            <Col xs={6}>
                                {showTakeHome()}
                            </Col>
                            <Col xs={3}></Col>
                        </Row>
                    </>
                }
                else {
                    return <>
                        <Row style={{ marginTop: '50px' }} >
                            <Col xs={3}></Col>
                            <Col xs={6}>
                                {showTakeHome()}
                            </Col>
                            <Col xs={3}></Col>
                        </Row>
                    </>
                }
            })()}
        </Container>
    }

    //render profile overview info
    const showImport = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/import')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Import Revenue Files</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{backgroundColor: 'black'}} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render primary company info
    const showDataFiles = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/datafiles')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage Check Statements</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render settings info
    const showOwnerMapping = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/mappedowners')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage my 'Owners'</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: 'black' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showCheckStatements = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/checkfiles')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Access my Check Statements</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: 'black' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showMyOperators = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/sendingoperators')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage my Operators</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render settings info
    const showDocumentConverter = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/documentconversion')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Convert my Documents</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: 'black' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showTakeHome = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Just take me to the Main Menu</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#465359' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <SpinnerComponent />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <SpinnerComponent />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    return (
        <>
            {(() => {
                if (isLoading) {
                    return showPageLoading();
                }
                else {
                    if (!isAuthenticated) {
                        return <>
                            <div className={styles.contentSection} />
                            <div className="body flex-grow-1" style={{ marginTop: '-24px'}} >
                            </div>
                            <div style={{
                                position: 'absolute',
                                top: '35%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '20'
                            }} >
                                <p style={{ fontSize: "2.0em", fontWeight: "bold", margin: "0px 0px 15px 0px" }} >THE OPERATORS DATA PLATFORM</p>
                                <p style={{ fontSize: "1.5em", fontWeight: "bold", margin: "0px" }} >Changing the way owner data is delivered.</p>
                                <div style={{ textAlign: "center", marginTop: "30px" }} >
                                    {/*<RequestInfoDialog />*/}
                                </div>
                            </div>
                        </>
                    }
                    else {
                        return <>
                            <br /><br />
                            {(() => {
                                if (false) {
                                    return <Container>
                                        <Row>
                                            <Col xs={8} style={{marginLeft: '-12px'}} >
                                                <p className="display-6" style={{ fontWeight: '500' }} >Welcome, {(accountProfile.firstName ?? '') == '' ? accountProfile.nickname : accountProfile.firstName}!</p>
                                            </Col>
                                            <Col xs={4}>
                                                <Container>
                                                    <Card className="mb-4 shadow" style={{ margin: '0px', padding: '0px' }} >
                                                        <Card.Body style={{ margin: '0px', padding: '8px' }}>
                                                            <Row>
                                                                <Col className="d-flex justify-content-center">
                                                                    <div style={{ color: 'red', fontSize: '.8rem', fontWeight: '700' }} >Excel/CDEX formats free for 2 months!!</div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="d-flex justify-content-center">
                                                                    <div style={{ color: 'red', fontSize: '.8rem', fontWeight: '700' }} >Only $12/month after for all Operator data.</div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="d-flex justify-content-center">
                                                                    <div style={{ color: 'red', fontSize: '.8rem', fontWeight: '700' }} >Cancel anytime.</div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="d-flex justify-content-center">
                                                                    <Button size="sm"
                                                                        onClick={() => navigate('/membership')}
                                                                        style={{
                                                                            width: '200px',
                                                                            border: '1px solid #C8B568',
                                                                            borderRadius: '20px',
                                                                            backgroundColor: '#C8B568',
                                                                            color: '#fff',
                                                                            fontSize: '.75rem',
                                                                            fontWeight: '600',
                                                                            padding: '0px',
                                                                            marginTop: '5px'
                                                                        }} >
                                                                        Yes - Upgrade my Membership Now!
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Container>
                                            </Col>
                                        </Row>
                                    </Container>
                                } else {
                                    return <p className="display-6" style={{ fontWeight: '500' }} >Welcome, {(accountProfile.firstName ?? '') == '' ? accountProfile.nickname : accountProfile.firstName}!</p>
                                }
                            })()}
                           
                            <div className="body flex-grow-1 px-3" style={{ marginTop: (false ? '-60px' : '0px')}} >
                                <Container fluid="lg">
                                    <Row style={{ height: "25px" }} ></Row>
                                    <Row style={{ marginBottom: '30px' }} >
                                        <p className="display-6">What would you like to do today?</p>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }} >
                                        {showCards()}
                                    </Row>
                                </Container>
                            </div>
                        </>
                    }
                }
            })()}
        </>
    );
}

export default Home;
