import { useOutletContext } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'

import Logo from './../assets/brand/pumpjack2.png';
const imgStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '20px'
};

const Owners = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    //stateful properties (hooks)
    const [currentAccount, accountProfile] = useOutletContext();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [checkStatements, setCheckStatements] = useState([]);
    const [downLoading, setDownLoading] = useState(false);
    const [dataCriteria, setDataCriteria] = useState({
        dataFileId: 1,
        skipCount: 0,
        takeCount: 10,
        ownerNumber: "",
        ownerName: "",
        checkNumber: ""
    });
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    ////initialize page
    //useEffect(() => {
    //    (async () => {
    //        //console.log('Initializing page...');
    //    })();
    //}, []);

    //populate check statements
    const populateCheckStatements = async (skipCount) => {
        //console.log('Getting Check Statements...');

        let apiUrl = baseUrl + 'datafiles/' + dataCriteria.dataFileId + '/checkstatements?skipcount=' + skipCount + '&takecount=' + dataCriteria.takeCount + (dataCriteria.ownerNumber === "" ? '' : '&ownerNumber=' + dataCriteria.ownerNumber) + (dataCriteria.ownerName === "" ? '' : '&ownerName=' + dataCriteria.ownerName) + (dataCriteria.checkNumber === "" ? '' : '&checkNumber=' + dataCriteria.checkNumber);
        console.log(apiUrl);
        let json = await fetch(apiUrl)
            .then(response => response.json())
            .catch(ex => {
                throw ex;                                   //use this to throw the raw error object
                //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
            })

        return { success: true, data: json };
    };

    const downloadPdf = async (exportType, checkId) => {
        try {
            setDownLoading(true);

            let apiUrl = baseUrl + 'a_export/';
            let fileName = 'RevenueStatement.';
            switch (exportType) {
                case 'Pdf':
                    apiUrl = apiUrl + 'pdf';
                    fileName = fileName + 'pdf';
                    break;
                case 'Excel':
                    apiUrl = apiUrl + 'excel';
                    fileName = fileName + 'xlsx';
                    break;
                case 'Cdex':
                    apiUrl = apiUrl + 'cdex';
                    fileName = fileName + 'dat';
                    break;
                default:
                    break;
            }

            apiUrl = apiUrl + '?checkid=' + checkId;
            console.log(apiUrl);

            fetch(apiUrl).then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();

                    setDownLoading(false);
                })
            });
        } catch (error) {
            console.log(error);
            setDownLoading(false);
        }
    };

    const downloadStatus = () => {
        return (
            <p>
                <em>
                    Downloading...
                </em>
            </p>
        );
    }

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        //console.log('Field: ' + fieldName + ', Value: ' + value);

        setDataCriteria({
            ...dataCriteria,
            [fieldName]: value
        });
    }

    //submit form
    const submitForm = async (e) => {
        e.preventDefault();
        setDataStatus('loading');

        setCurrentPage(1);

        await populateCheckStatements(0)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setRowCount(response.data[0].rowCount);
                    setCheckStatements(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //handle clear button
    const handleClearButton = async () => {

        setDataCriteria({
            ...dataCriteria,
            ownerNumber: '',
            ownerName: '',
            checkNumber: ''
        });
    };

    //handle page click
    const handlePageClick = async (currentPage) => {
        console.log('Current Page: ' + currentPage);

        let skipCount = (currentPage === 1 ? 0 : (currentPage - 1) * dataCriteria.takeCount);
        console.log('Skip Count: ' + skipCount);
        setDataCriteria({
            ...dataCriteria,
            skipCount: skipCount
        });

        //setDataStatus('loading');

        await populateCheckStatements(skipCount)
            .then(response => {
                if (response.data.length === 0) {
                    console.log(response.data);
                    setDataStatus('nodata')
                }
                else {
                    //console.log('Check Count: ' + response.data[0].rowCount);
                    setCheckStatements(response.data);
                    setDataStatus('ready')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render table container
    const showTableContainer = () => {
        return (
            <Card className="mb-4">
                <Card.Header style={{ backgroundColor: 'lightgray' }}>
                    {showInputCriteria()}
                </Card.Header>
                <Card.Body>
                    {(() => {
                        switch (dataStatus) {
                            case 'nodata':
                                return <p>No Data Loaded.</p>
                            case 'loading':
                                return showDataLoading()
                            case 'ready':
                                return showData()
                            case 'error':
                                return showDataError()
                            default:
                                return <Row>No Data Loaded.</Row>
                        }
                    })()}
                </Card.Body>
            </Card>
        );
    }

    //render check statements
    const showData = () => {
        return (
            <Container>
                <Row>
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Co #</th>
                                <th>Owner Number</th>
                                <th>Owner Name</th>
                                <th>Owner Status</th>
                                <th>Check Number</th>
                                <th>Check Amount</th>
                                <th>Check Date</th>
                                <th colSpan={3} style={{ textAlign: "center" }}>Non-Operated Statements</th>
                            </tr>
                        </thead>
                        <tbody>
                            {checkStatements.map((checkStatement) => (
                                <tr key={checkStatement.checkId}>
                                    <td>{checkStatement.companyNumber}</td>
                                    <td>{checkStatement.ownerNumber}</td>
                                    <td>{checkStatement.ownerName}</td>
                                    <td>{(checkStatement.ownerStatus === true ? "Generated" : "Released")}</td>
                                    <td>{checkStatement.checkNumber}</td>
                                    <td>{checkStatement.checkAmount}</td>
                                    <td>{checkStatement.checkDateString}</td>
                                    <td>
                                        <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "70px", marginLeft: "20px" }} value={checkStatement.checkId}
                                            onClick={(e) => downloadPdf('Pdf', e.currentTarget.value)}>
                                            PDF
                                        </Button>
                                    </td>
                                    <td>
                                        <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "70px" }} value={checkStatement.checkId}
                                            disabled={!checkStatement.dataFileReleased}
                                            onClick={(e) => downloadPdf('Excel', e.currentTarget.value)}>
                                            Excel
                                        </Button>
                                    </td>
                                    <td>
                                        <Button id={checkStatement.checkId} variant="primary" size="sm" style={{ width: "70px" }} value={checkStatement.checkId}
                                            disabled={!checkStatement.dataFileReleased}
                                            onClick={(e) => downloadPdf('Cdex', e.currentTarget.value)}>
                                            CDEX
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <PaginationComponent
                            itemsCount={rowCount}
                            itemsPerPage={dataCriteria.takeCount}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageClickHandler={handlePageClick}
                            alwaysShown={false}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    //pagination example
    //https://github.com/lukaaspl/ellipsis-pagination/blob/master/src/components/Pagination.js

    //render input criteria
    const showInputCriteria = () => {
        return (
            <Container>
                <Form>
                    <Row>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerNumber">
                                <Form.Label>Owner Number</Form.Label>
                                <Form.Control name="ownerNumber" size="sm" aria-label="Owner Number" value={dataCriteria.ownerNumber} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="ownerName">
                                <Form.Label>Owner Name</Form.Label>
                                <Form.Control name="ownerName" size="sm" aria-label="Owner Name" value={dataCriteria.ownerName} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3" controlId="checkNumber">
                                <Form.Label>Check Number</Form.Label>
                                <Form.Control name="checkNumber" size="sm" aria-label="Check Number" value={dataCriteria.checkNumber} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonClear">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='button'
                                    size='sm'
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? handleClearButton : null}
                                >
                                    Clear
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group as={Col} className="mb-3" controlId="buttonSubmit">
                                <Form.Label as={Row}><br /></Form.Label>
                                <Button
                                    type='submit'
                                    size='sm'
                                    disabled={pageStatus !== 'ready'}
                                    onClick={pageStatus === 'ready' ? submitForm : null}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }

    return (
        <>
            <div className="body flex-grow-1 px-3">
                <Container fluid="lg">
                    <Row style={{ height: "100px" }} ></Row>
                    <Row>
                        <Col xs={5}>
                            <br /><br />
                            <h1 className="display-1">Owners</h1>
                            <br />
                            <p className="display-6">Getting your statements</p>
                            <p className="display-6">is easier than ever!</p>
                        </Col>
                        <Col>
                            <img
                                src={Logo}
                                alt="totdata.com"
                                style={imgStyle}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid>
                <Row style={{ marginTop: '50px', backgroundColor: 'SteelBlue', height: '500px' }} />
            </Container>
        </>
    );
}

export default Owners;
