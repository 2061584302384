import { useOutletContext, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faGear, faContactBook, faXmark, faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons'

import Logo from './../assets/brand/pumpjack2.png';
import Cables from './../assets/brand/cables.png';
const imgStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '20px'
};

const imgPublicStyle = {
    width: '100%',
    height: 'auto',
    padding: '0px',
    margin: '0px',
    marginBottom: '0px',
    objectFit: 'fill'
};

const Settings = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd) {
                setIsShowSidebarMd(true);
            }
            
        })();
    }, []);

    //render profile overview info
    const cardNavigate = (path) => {

        if (path == '/') {
            toggleIsShowSidebarMd();
        }
        else {
            navigate(path);
        }
    }

    //render cards
    const showCards = () => {
        return <Container>
            {(() => {
                if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                    return <>
                        <Row>
                            <p className="display-6" style={{ fontSize: '1.65rem', fontWeight: '700' }} >Operated</p>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col xs={4}>
                                {showOwnerMappings()}
                            </Col>
                            <Col xs={4}>
                                {accountProfile.hasImpersonate == true ? showCdexMappings() : null}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return null
                }
            })()}

            {(() => {
                if (accountProfile.accountType === "Admin" || accountProfile.accountType === "Sender" || accountProfile.accountType === "SenderReceiver") {
                    return <>
                        <Row>
                            <p className="display-6" style={{fontSize: '1.65rem', fontWeight: '700'}} >Non-Operated</p>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                {cdexUDFs()}
                            </Col>
                            <Col xs={4}>
                                {cdexAccountOverride()}
                            </Col>
                        </Row>
                    </>
                }
                else {
                    return <>
                        <Row>
                            <Col xs={4}>
                                {cdexUDFs()}
                            </Col>
                            <Col xs={4}>
                                {cdexAccountOverride()}
                            </Col>
                        </Row>
                    </>
                }
            })()}
        </Container>
    }

    //render profile overview info
    const showOwnerMappings = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/mappedowners')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Owner Mapping</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{backgroundColor: 'black'}} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render primary company info
    const showCdexMappings = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/cdexcodes')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Cdex Acct Mapping</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render settings info
    const showOwnerMapping = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/mappedowners')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage my 'Owners'</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#969FA7' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const cdexUDFs = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/cdexudfs')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Cdex User-Defined Fields</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: 'black' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const cdexAccountOverride = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Cdex Acct Override</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showMyOperators = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/sendingoperators')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Manage my Operators</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#C8B568' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    //render contact info
    const showTakeHome = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => cardNavigate('/')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faLink} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Just take me to the Main Menu</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#969FA7' }} >
                {/*<div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Heading...</div>*/}
                {/*<div>Description...</div>*/}
            </Card.Body>
        </Card>
    }

    return (
        <>
            <br /><br />
            <p className="display-6" style={{fontWeight: '500'}} >Settings</p>
            <div className="body flex-grow-1 px-3">
                <Container fluid="lg">
                    <Row style={{ height: "25px" }} ></Row>
                    <Row style={{marginTop: '20px'}} >
                        {showCards()}
                    </Row>
                </Container>                            
            </div>
        </>
    );
}

export default Settings;
