import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";

//import { Auth0Provider } from "@auth0/auth0-react";
//import { getConfig } from "./config";

//const navigate = useNavigate();

//const onRedirectCallback = (appState) => {
//    //history.push(
//    //    appState && appState.returnTo ? appState.returnTo : window.location.pathname
//    //);
//    navigate(appState?.returnTo || window.location.pathname);
//};

//const config = getConfig();

//const providerConfig = {
//    domain: config.domain,
//    clientId: config.clientId,
//    onRedirectCallback,
//    authorizationParams: {
//        redirect_uri: window.location.origin,
//        ...(config.audience ? { audience: config.audience } : null),
//        scope: "openid profile email read:messages admin",
//    },
//};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    /*<React.StrictMode>*/    
    <BrowserRouter>
        <Auth0ProviderWithNavigate>
            <App />
        </Auth0ProviderWithNavigate>
    </BrowserRouter>    
    /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
