import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card, Modal } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif';
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth0 } from "@auth0/auth0-react";

const iconStyle = {
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

const SignIn = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const location = useLocation();

    const [registerModalShow, setRegisterModalShow] = useState(true);
    const [pageStatus, setPageStatus] = useState('ready');
    const [dataStatus, setDataStatus] = useState('ready');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [regions, setRegions] = useState([{ id: 'US', name: 'United States' }, { id: 'CA', name: 'Canada' }, { id: 'OT', name: 'Other' }]);
    const [documentTypes, setDocumentTypes] = useState([{ id: 'REV', name: 'Revenue Statements' }, { id: 'JIB', name: 'JIB' }]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [currentYear, setCurrentYear] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(0);
    const [isDirty, setIsDirty] = useState(false);
    const captchaRef = useRef(null);
    const [isRegisterClicked, setIsRegisterClicked] = useState(false);
    const [isDataValid, setIsDataValid] = useState({
        region: true,
        docType: true,
        operatorName: true,
        ownerNumber: true,
        checkMonth: true,
        checkYear: true,
        checkNumber: true,
        checkAmount: true,
        confirmTOC: true,
        email: true,
        confirmEmail: true,
        reCaptchaToken: false
    });
    const [dataCriteria, setDataCriteria] = useState({
        region: 'US',
        docType: 'REV',
        operatorName: '',
        ownerNumber: '',
        checkMonth: 0,
        checkYear: 0,
        checkNumber: '',
        checkAmount: '',
        confirmTOC: false,
        email: '',
        confirmEmail: '',
        reCaptchaToken: ''
    });

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');
            
            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            if (!isAuthenticated) {
                await handleLogin();
            }

        })();
    }, []);

    const handleLogin = async () => {
        console.log(location.pathname);

        var nonce = location.state;
        await loginWithRedirect({
            appState: {
                //returnTo: window.location.origin,
                //returnTo: location.pathname,
                returnTo: '/',
                nonce: nonce
            },
        });
    };

    //initialize form
    const initializeForm = async () => {
        //TODO: might just call populateOwners() from useEffect
        //console.log('Initializing form...');

        //get dates for to/from dropdowns
        const dates = await getDates();

        return { success: true, dates: dates };

        //TODO: might want to clean this up with .then /.catch, but how to do with multiple api calls?
        //.then(get other data)
        //.catch(ex => throw...)
    };

    const getDates = () => {

        const date = new Date();
        //const day = date.getDate();
        const currMonthNum = date.getMonth() + 1;
        //const currMonthNum = 3;
        const currYearNum = date.getFullYear();

        const allMonths = [{ index: 1, name: 'Jan' }, { index: 2, name: 'Feb' }, { index: 3, name: 'Mar' }, { index: 4, name: 'Apr' },
            { index: 5, name: 'May' }, { index: 6, name: 'Jun' },{ index: 7, name: 'Jul' }, { index: 8, name: 'Aug' },
            { index: 9, name: 'Sep' }, { index: 10, name: 'Oct' }, { index: 11, name: 'Nov' }, { index: 12, name: 'Dec' }];

        //previous year
        const years = [];
        if (currMonthNum < 6) {
            const prevYearMonths = allMonths.slice(12 - (6 - currMonthNum));
            const prevYear = { year: { index: 0, num: currYearNum - 1 }, months: prevYearMonths };
            years.push(prevYear);
        }

        //current year
        const currYearMonths = [];
        allMonths.map((month, i) => {
            if (i < currMonthNum && i >= (currMonthNum - 6))
                currYearMonths.push(month);
        });
        const currYear = { year: { index: 1, num: currYearNum }, months: currYearMonths };
        years.push(currYear);

        //years.map((year, i) => {
        //    console.log('Year: ' + year.year.num);

        //    year.months.map((month, i) => {
        //        console.log('Month: ' + month.index + ' : ' + month.name);
        //    }); 
        //}); 

        return { years: years, currMonth: currMonthNum, currYear: currYearNum };
    };

    //handle form control changes
    const handleChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        const newDataCriteria = {
            ...dataCriteria,
            [fieldName]: value
        };      

        //handling specific fields
        switch (fieldName) {
            case 'checkYear':
                //if setting year, set the selected year index so we can update the month dropdown
                years.map((year, index) => {
                    if (year.year.num == value) {
                        setMonths(year.months);
                        const monthNum = (year.year.num == currentYear ? currentMonth : year.months[0].index);
                        newDataCriteria.checkMonth = monthNum;
                    }
                });
                break;
            case 'checkAmount':
                let newValue = value.replace("$", "");
                newValue = newValue.replace(",", "");
                console.log('New Value1: ' + newValue);
                console.log('New Length: ' + newValue.length);

                //var validNumber = new RegExp(/^\d*\.?\d*$/);
                //var validNumber = new RegExp(/^\d*\.?\d\d$/);
                //var valNum = new RegExp(/^[0-9]+\.[0-9][0-9]$/);
                var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);

                const matchFound = newValue.search(validNumber);
                console.log('Match Found: ' + matchFound);
                if (matchFound == -1) {
                    return;
                }

                //this works, but bumps cursor to end of input
                const numericValue = parseFloat(newValue);
                newValue = numericValue.toLocaleString("en-US");
                console.log('Formatted Number: ' + newValue);

                ////this works, but bumps cursor to end of input
                //let currLength = newValue.length;
                //if (currLength > 3)
                //    newValue = newValue.substr(0, 1) + "," + newValue.substring(1);
                //console.log('New Value2: ' + newValue);


                newValue = "$" + newValue;
                console.log('New Value3: ' + newValue);

                newDataCriteria.checkAmount = newValue;
                //newDataCriteria.checkAmount = "$" + (value == "$" ? "" : value.replace("$", ""));
                break;





                //1. remove $ and ,
                //2. verify regex
                //3. 


                //let newValue = value.replace("$", "");
                //newValue = newValue.replace(",", "");
                //console.log('New Value1: ' + newValue);
                //console.log('New Length: ' + newValue.length);

                ////var validNumber = new RegExp(/^\d*\.?\d*$/);
                ////var validNumber = new RegExp(/^\d*\.?\d\d$/);
                ////var valNum = new RegExp(/^[0-9]+\.[0-9][0-9]$/);
                //var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);

                //const matchFound = newValue.search(validNumber);
                //console.log('Match Found: ' + matchFound);
                //if (matchFound == -1) {
                //    return;
                //}

                ////this works, but bumps cursor to end of input
                //const numericValue = parseFloat(newValue);
                //newValue = numericValue.toLocaleString("en-US");
                //console.log('Formatted Number: ' + newValue);

                //////this works, but bumps cursor to end of input
                ////let currLength = newValue.length;
                ////if (currLength > 3)
                ////    newValue = newValue.substr(0, 1) + "," + newValue.substring(1);
                ////console.log('New Value2: ' + newValue);


                //newValue = "$" + newValue;
                //console.log('New Value3: ' + newValue);

                //newDataCriteria.checkAmount = newValue;                
                //newDataCriteria.checkAmount = "$" + (value == "$" ? "" : value.replace("$", ""));
                //break;
            default:
                break;
        }

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }

    //handle form control changes
    const handleTempChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.name;
        const value = event.target.value;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        const newDataCriteria = {
            ...dataCriteria,
            [fieldName]: value
        };

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }

    //handle modal display pdf hide button
    const handleHideRegisterModal = () => {
        //navigate("https://totdata.com");
        //window.location.href = "https://totdata.com";
        window.location.replace("https://totdata.com");
        //return null;
        //setRegisterModalShow(false);
    }

    //handle checkbox clicks
    const handleCheckboxClick = (event) => {
        const fieldName = event.target.name;
        const value = event.target.checked;
        console.log('Field: ' + fieldName + ', Value: ' + value);

        const newDataCriteria = {
            ...dataCriteria,
            [fieldName]: value
        };

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }

    //handle recaptcha change
    const handleReCaptchaChange = () => {
        //event.preventDefault();

        const token = captchaRef.current.getValue();
        //console.log('Setting ReCaptcha Token: ' + token)
        
        const newDataCriteria = {
            ...dataCriteria,
            reCaptchaToken: token
        };

        //set criteria and isDirty status
        setDataCriteria(newDataCriteria);
        checkIsDataDirty(newDataCriteria);
        checkIsDataValid(newDataCriteria);
    }

    //get user account
    const checkIsDataDirty = async (newDataCriteria) => {

        const isDirty = newDataCriteria.region.length > 0
            && newDataCriteria.docType.length > 0
            && newDataCriteria.operatorName.length > 2
            && newDataCriteria.ownerNumber.length > 0
            && newDataCriteria.checkMonth > 0
            && newDataCriteria.checkYear > 0
            && newDataCriteria.checkAmount.length > 1
            && newDataCriteria.confirmTOC == true
            && newDataCriteria.email.length > 0
            && newDataCriteria.confirmEmail.length > 0
            && newDataCriteria.reCaptchaToken.length > 0;
        
        setIsDirty(isDirty);
    }

    //get user account
    const checkIsDataValid = async (newDataCriteria) => {
        const isOperatorNameValid = newDataCriteria.operatorName.length >= 3;
        const isOwnerNumberValid = newDataCriteria.ownerNumber.length >= 1;

        //**********Begin - Check Amount**********//
        let newValue = newDataCriteria.checkAmount.replace("$", "");
        newValue = newValue.replace(",", "");
        //console.log('New Value1: ' + newValue);
        //console.log('New Length: ' + newValue.length);

        //var validNumber = new RegExp(/^\d*\.?\d*$/);
        //var validNumber = new RegExp(/^\d*\.?\d\d$/);
        //var valNum = new RegExp(/^[0-9]+\.[0-9][0-9]$/);
        var validNumber = new RegExp(/^\d*\.?\d{0,2}$/);

        const matchFound = newValue.search(validNumber);
        //console.log('Match Found: ' + matchFound);
        let isCheckAmountValid = true;
        if (matchFound == -1) {
            isCheckAmountValid = false;
        }
        //***********End - Check Amount***********//

        //***************Begin - Email************//
        var validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        const emailMatchFound = newDataCriteria.email.search(validEmail);
        const isEmailValid = emailMatchFound > -1;
        //***************End - Email**************//

        const isConfirmEmailValid = newDataCriteria.email == newDataCriteria.confirmEmail;
        const isConfirmTOCValid = newDataCriteria.confirmTOC;
        const isReCaptchaValid = newDataCriteria.reCaptchaToken.length > 0;

        setIsDataValid({
            ...isDataValid,
            operatorName: isOperatorNameValid,
            ownerNumber: isOwnerNumberValid,
            checkAmount: isCheckAmountValid,
            email: isEmailValid,
            confirmEmail: isConfirmEmailValid,
            confirmTOC: isConfirmTOCValid,
            reCaptchaToken: isReCaptchaValid
        });
    }

    //handle form button clicks
    const handleFormButtons = async (buttonId) => {

        switch (buttonId) {
            case "Cancel":
                console.log('cancelling...');
                break;
            case "Save":
                console.log('saving...');
                await registerUser();
                setIsRegisterClicked(true);
                window.location.replace("https://totdata.com");                
                break;
        }
    }

    //register user
    const registerUser = async () => {
        console.log('Saving profile...');
        setDataStatus('loading');

        captchaRef.current.reset();
        //console.log('Reading ReCAPTCHA Token: ' + dataCriteria.reCaptchaToken);

        const newDataCriteria = {
            ...dataCriteria,
            checkAmount: dataCriteria.checkAmount.replace("$", "").replace(",", "")
        };

        await saveRegistration(newDataCriteria)
            .then(response => {
                if (response.success) {
                    console.log('Successfully linked profile');
                    setDataStatus('ready');
                }
                else {
                    //TODO: figure out what to do here
                    console.log('Failed to link profile');
                    setDataStatus('nodata')
                }
            })
            .catch(ex => {
                setDataStatus('error');
                setDataErrors(ex.message);
            })
            .finally(response => {
                //do something
            })
    };
    
    //save user registration
    const saveRegistration = async (newCriteria) => {
        //console.log('Getting User Account...');

        const apiUrl = baseUrl + 'accounts/register';
        console.log(apiUrl);
        console.log(JSON.stringify(newCriteria));

        const result = await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newCriteria)
        })
        .then(response => {
            if (response.ok) {
                console.log('true response');
                return true;
            }
            else {
                return false;
            }
        })
        .catch(ex => {
            throw ex;                                   //use this to throw the raw error object
            //throw new Error('Bad Url: ' + ex);        //use this to throw custom error message
        })

        //console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: result }
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render page error
    const showBlankPage = () => {
        return (
            <div>
                {/*THIS IS BLANK*/}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render register form
    const showRegisterForm = () => {
        return <Container>
            <Row>
                <Col xs={7}>
                    <Card className="mb-4 shadow" style={{border: 'none', borderRadius: '10px'}} >
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '1.75rem', fontWeight: '600' }} >Register</div>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{ marginTop: '0px'}} >
                                    <hr />
                                    <div style={{ fontSize: '1.2rem', fontWeight: '600' }} >Enter the criteria below to register and link to your documents.</div>
                                </div>                                
                            </Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="region" style={{ marginTop: '30px' }}>
                                    <Form.Label column sm={3}>Operator's Region</Form.Label>
                                    <Col sm={4}>
                                        <Form.Select name="region" size="sm" value={dataCriteria.region} aria-label="Operator's Region" onChange={handleChange} >
                                            {regions.map((region, i) => (
                                                <option key={i} value={region.id}>{region.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="docType">
                                    <Form.Label column sm={3}>Document Type</Form.Label>
                                    <Col sm={4}>
                                        <Form.Select name="docType" size="sm" value={dataCriteria.docType} aria-label="Document Type" onChange={handleChange} >
                                            {documentTypes.map((docType, i) => (
                                                <option key={i} value={docType.id}>{docType.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="operatorName">
                                    <Form.Label column sm={3}>Operator Name</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="operatorName" size="sm" aria-label="Operator Name" value={dataCriteria.operatorName} onChange={handleChange} isInvalid={(isRegisterClicked == true ? !isDataValid.operatorName : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter first 3 letters of operator's name.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="ownerNumber">
                                    <Form.Label column sm={3}>Owner Number</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="ownerNumber" size="sm" aria-label="Owner Number" value={dataCriteria.ownerNumber} onChange={handleChange} isInvalid={(isRegisterClicked == true ? !isDataValid.ownerNumber : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter owner number.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="checkMonth">
                                    <Form.Label column sm={3}>Check/ACH Month</Form.Label>
                                    <Col sm={2}>
                                        <Form.Select name="checkMonth" size="sm" value={dataCriteria.checkMonth} aria-label="Check Month" onChange={handleChange} >
                                            {months.map((month, i) => (
                                                <option key={i} value={month.index}>{month.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Select name="checkYear" size="sm" value={dataCriteria.checkYear} aria-label="Check Year" onChange={handleChange} >
                                            {years.map((year, i) => (
                                                <option key={i} value={year.year.num}>{year.year.num}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    {/*<Col sm={4}>*/}
                                    {/*    <div style={{color: 'red'}} >Reminder: remove temp code</div>*/}
                                    {/*</Col>*/}
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="checkAmount">
                                    <Form.Label column sm={3}>Check Amount</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="checkAmount" size="sm" aria-label="Check Amount" value={dataCriteria.checkAmount} onChange={handleChange} isInvalid={(isRegisterClicked == true ? !isDataValid.checkAmount : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter check amount.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                {/*<Form.Group as={Row} className="mb-3" controlId="checkAmount2">*/}
                                {/*    <Form.Label column sm={3}>Check Amount 2</Form.Label>*/}
                                {/*    <Col sm={4}>*/}
                                {/*        <CurrencyFormat value={2456981} thousandSeparator={true} prefix={'$'} />*/}
                                {/*        <Form.Control.Feedback type="invalid">*/}
                                {/*            Please enter check amount2.*/}
                                {/*        </Form.Control.Feedback>*/}
                                {/*    </Col>*/}
                                {/*    <Col sm={4}>*/}
                                {/*        <div style={{ color: 'red' }} >Reminder: remove temp code</div>*/}
                                {/*    </Col>*/}
                                {/*</Form.Group>*/}
                                <Form.Group as={Row} className="mb-3" controlId="email">
                                    <Form.Label column sm={3}>Email</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="email" size="sm" aria-label="Email" value={dataCriteria.email} onChange={handleChange} placeholder="john.doe@gmail.com" isInvalid={(isRegisterClicked == true ? !isDataValid.email : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="confirmEmail">
                                    <Form.Label column sm={3}>Confirm Email</Form.Label>
                                    <Col sm={4}>
                                        <Form.Control name="confirmEmail" size="sm" aria-label="Confirm Email" value={dataCriteria.confirmEmail} onChange={handleChange} placeholder="john.doe@gmail.com" isInvalid={(isRegisterClicked == true ? !isDataValid.confirmEmail : false)} />
                                        <Form.Control.Feedback type="invalid">
                                            Emails must match.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="confirmTOC" style={{marginTop: '30px'}} >
                                    <Col sm={5}>
                                        <Form.Check
                                            type="checkbox"
                                            name="confirmTOC"
                                            label="Confirm Terms and Conditions"
                                            checked={dataCriteria.confirmTOC}
                                            onChange={handleCheckboxClick}
                                            feedback="You must confirm before submitting."
                                            feedbackType="invalid"
                                            isInvalid={(isRegisterClicked == true ? !isDataValid.confirmTOC : false)}

                                        />
                                    </Col>
                                </Form.Group>
                                <Row style={{ marginTop: '-10px' }} >
                                    <div>I authorize TOTData.com and its designated agents and representatives to use my information to protect against or prevent actual or potential fraud, unauthorized transactions or access, or other liability.</div>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            ref={captchaRef}
                                            onChange={handleReCaptchaChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <Form.Group className="mb-3" controlId="buttonSubmit">
                                            <Form.Label as={Row}><br /></Form.Label>
                                            <Button
                                                size='md'
                                                style={{width: '400px'}}
                                                disabled={!isDirty}
                                                onClick={() => handleFormButtons('Save')}
                                            >
                                                Register
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '15px' }} >
                                    <Col className="d-flex justify-content-center">
                                        <div style={{ fontSize: '1rem', fontWeight: '600' }} ><i>Note: This is a one-time process to securely link your login profile to your documents.</i></div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    return (
      <div>
            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showBlankPage() 
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
      </div>
    );
}

export default SignIn;
