import React from 'react'

export default function Footer() {
  return (
      <footer className="footer flex-column flex-md-row border-top d-flex align-items-center justify-content-between px-4 py-2" >
          <div style={{ color: 'white' }}>
              <a className="text-decoration-none" style={{color: 'black'}} href="/">
                  TOTData, Inc. &nbsp; © 2023
        </a>
      </div>
          <div className="ms-md-auto" >
              <a className="text-decoration-none" style={{ color: 'black' }} href="/">
              TOTData, Inc.
            </a>
          </div>
    </footer>
  )
}
