import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import spinnerIcon from './/./../assets/img/loadingSpinner.gif'
import PaginationComponent from '../components/pagination/PaginationComponent.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faGear, faContactBook, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { apiGet } from "../services/AuthService.js";
import { useAuth0 } from "@auth0/auth0-react";

const iconStyle = {
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

const Account = props => {

    //properties
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const { getAccessTokenSilently } = useAuth0();
    
    //stateful properties (hooks)
    const [currentAccount, accountProfile, setAccountProfile, toggleIsShowSidebarMd, setIsShowSidebarMd] = useOutletContext();
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState('loading');
    const [dataStatus, setDataStatus] = useState('loading');
    const [pageErrors, setPageErrors] = useState('');
    const [dataErrors, setDataErrors] = useState('');
    const [accountDetails, setAccountDetails] = useState([]);

    //initialize page
    useEffect(() => {
        (async () => {
            //console.log('Initializing page...');

            if (setIsShowSidebarMd)
                setIsShowSidebarMd(true);

            await populateAccountDetails()
                .then(response => {
                    if (response.data.length === 0) {
                        console.log(response.data);
                        setDataStatus('nodata')
                    }
                    else {
                        setAccountDetails(response.data);
                        setDataStatus('ready')
                    }

                    setPageStatus('ready')
                })
                .catch(ex => {
                    setPageStatus('error');
                    setPageErrors(ex.message);

                    setDataStatus('error');
                    setDataErrors(ex.message);
                })
                .finally(response => {
                    //do something
                })
        })();
    }, []);

    //get user profile
    const populateAccountDetails = async () => {
        //console.log('Getting User Profile...');

        //TODO: don't need email anymore since auth0Id is used (we're not logging in; just getting profile for the login)
        let apiUrl = 'accounts/userprofile';
        console.log(apiUrl);

        const response = await apiGet(apiUrl, getAccessTokenSilently);
        const json = await response.json()
        console.log('Response - Status: ' + response.status + ', Success: ' + response.ok + ', Json: ' + JSON.stringify(json));

        return { success: response.ok, data: json }
    };

    //render page loading
    //note: spinner overlay will eventually be at the page level (I think)
    const showPageLoading = () => {
        return (
            <div>
                <img src={spinnerIcon} alt="loading..." />
            </div>
        );
    }

    //render page loading
    const showDataLoading = () => {
        return (
            <Row>
                <img src={spinnerIcon} alt="loading..." />
            </Row>
        );
    }

    //render page error
    const showPageError = () => {
        return (
            <div>
                Oops, there was a page load error: {pageErrors}
            </div>
        );
    }

    //render data error
    const showDataError = () => {
        return (
            <Row>
                Oops, there was a data load error: {dataErrors}
            </Row>
        );
    }

    //render profile info
    const showProfile = () => {
        return <Container>
            <Row>
                <Col xs={5}>
                    {showProfileOverview()}
                </Col>
                <Col xs={5}>
                    {showPrimaryCompany()}
                </Col>
                <Col xs={5}>
                    {showSettings()}
                </Col>
                <Col xs={5}>
                    {showContact()}
                </Col>
            </Row>
        </Container>
    }

    //render profile overview info
    const showProfileOverview = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/profile')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faUser} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >My Profile</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    {(() => {
                        if ((accountDetails.currentUser ?? '') != '') {
                            return <>
                                <div style={{ fontSize: '1.25rem', fontWeight: '600' }} >{accountDetails.currentUser.firstName} {accountDetails.currentUser.lastName}</div>
                                <div>{accountDetails.currentUser.address1}</div>
                                <div>{accountDetails.currentUser.address2}</div>
                                <div>{accountDetails.currentUser.address3}</div>
                                <div>{accountDetails.currentUser.city}, {accountDetails.currentUser.state} {accountDetails.currentUser.zip}</div>
                                <div>{accountDetails.currentUser.email}</div>
                                <div>{accountDetails.currentUser.phone}</div>
                                <div>{((accountDetails.primaryCompany.additionalNameInfo ?? '') == '' ? null : <br/>)}</div>
                            </>
                        }
                        else {
                            return <div style={{ fontSize: '1rem', fontWeight: '600' }} >No primary company found.</div>
                        }
                    })()}
                </Row>
            </Card.Body>
        </Card>
    }

    //render primary company info
    const showPrimaryCompany = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }}>
            <Card.Header>
                <Row>
                    <Col xs="1" onClick={() => navigate('/companies')}>
                        <FontAwesomeIcon icon={faBuilding} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col onClick={() => navigate('/companies')}>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Company Profiles</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body onClick={() => navigate('/companies')}>                
                <Row>                    
                    {(() => {
                        if ((accountDetails.primaryCompany ?? '') != '') {
                            return <>
                                <div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Primary Company</div>
                                <div>{accountDetails.primaryCompany.companyName}</div>
                                <div>{((accountDetails.primaryCompany.additionalNameInfo ?? '') == '' ? null : '(' + accountDetails.primaryCompany.additionalNameInfo + ')')}</div>
                                <div>{accountDetails.primaryCompany.address1}{((accountDetails.primaryCompany.address2 ?? '') == '' ? null : ', ' + accountDetails.primaryCompany.address2)}</div>
                                <div>{accountDetails.primaryCompany.city}, {accountDetails.primaryCompany.state} {accountDetails.primaryCompany.zip}</div>
                                <div>{accountDetails.primaryCompany.email}</div>
                                <div>{accountDetails.primaryCompany.phone}</div>
                            </>
                        }
                        else {
                            return <div style={{ fontSize: '1rem', fontWeight: '600' }} >No primary company found.</div>
                        }
                    })()}
                </Row>
            </Card.Body>
        </Card>
    }

    //render settings info
    const showSettings = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/settings')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faGear} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Settings</div>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    {(() => {
                        if ((accountDetails.currentUser ?? '') != '') {
                            return <>
                                <div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Coming soon...</div>
                                <div>&nbsp;</div>
                            </>
                        }
                        else {
                            return <div style={{ fontSize: '1rem', fontWeight: '600' }} >No primary company found.</div>
                        }
                    })()}
                </Row>
            </Card.Body>
        </Card>
    }

    //render contact info
    const showContact = () => {
        return <Card className="mb-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/contact')}>
            <Card.Header>
                <Row>
                    <Col xs="1">
                        <FontAwesomeIcon icon={faContactBook} style={{ paddingTop: '10px', width: '20px', height: '20px' }} />
                    </Col>
                    <Col>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600' }} >Contact</div>
                    </Col>                    
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    {(() => {
                        if ((accountDetails.currentUser ?? '') != '') {
                            return <>
                                <div style={{ fontSize: '1.25rem', fontWeight: '600' }} >Email</div>
                                <div>support@totdata.com</div>
                                
                            </>
                        }
                        else {
                            return <div style={{ fontSize: '1rem', fontWeight: '600' }} >No primary company found.</div>
                        }
                    })()}
                </Row>
            </Card.Body>
        </Card>
    }

    return (
      <div>     
            {(() => {
                if ((accountDetails.primaryCompany ?? '') != '') {
                    return <>
                        <h1>{((accountDetails.primaryCompany.companyName ?? '') == '' ? 'Account' : accountDetails.primaryCompany.companyName)}</h1>
                        <h1>{((accountDetails.primaryCompany.additionalNameInfo ?? '') == '' ? null : '(' + accountDetails.primaryCompany.additionalNameInfo + ')')}</h1>

                    </>
                }
            })()}
            <br />

            {(() => {
                switch (pageStatus) {
                    case 'loading':
                        return showPageLoading()
                    case 'ready':
                        return showProfile()
                    case 'error':
                        return showPageError()
                    default:
                        return <p>No Page Loaded.</p>
                }
            })()}
      </div>
    );
}

export default Account;
