import { Nav } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

//import TOTLogo from './/./../../../assets/brand/Logo_Another2.png';
import TOTLogo from './/./../../../assets/brand/ColorNoBackground.png';
//const logoStyle = {
//    height: '67px',
//    width: '318px',
//    color: 'transparent',
//    border: '1px solid black',
//    cursor: 'pointer'
//};
const logoStyle = {
    height: '67px',
    width: '100px',
    color: 'transparent',
    border: '1px solid black',
    cursor: 'pointer'
};

export default function HeaderFeaturedNav() {
    //stateful properties (hooks)
    const navigate = useNavigate();

  return (
      <Nav>
          <Nav.Item>
              <img
                  object-fit="fill"
                  src={TOTLogo}
                  alt="totdata.com"
                  style={logoStyle}
                onClick={() => navigate('/')}
                />
          </Nav.Item>
          {/*<Nav.Item>*/}
          {/*    <div style={{ color: "white", textAlign: "right", marginLeft: "5px", marginTop: "0px" }} ><p style={{ font: "Open Sans", fontSize: "19px", fontWeight: "400" }} >"Tripping Over The Data"<span style={{ font: "Open Sans", fontSize: "12px", verticalAlign: "top", marginLeft: "3px" }} >&nbsp;TM</span></p></div>*/}
          {/*</Nav.Item>*/}
    </Nav>
  )
}
